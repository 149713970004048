import { Decimal } from "decimal.js";
import { decimalConfig } from "../utils/utils";
import { updateUserApi } from "../api/ProfileApi";
import user_plan, { userPlanType } from "../utils/user_utils";
Decimal.set(decimalConfig);

export const userSlice = (set, get) => ({
  currency_formatter: undefined,
  currency_symbol: undefined,
  user: {},
  flowerbuddyFee: () => {
    let user_plan = get().userPlan();
    if (user_plan) {
      if (user_plan.status === "trialing") {
        return new Decimal(0.0);
      } else {
        return new Decimal(user_plan.items[0].price.metadata.per_event_fee);
      }
    }
  },
  formatCurrency: (val, currency) => {
    if (val || val === 0) {
      var formatter;
      if (!currency || currency === get().user.currency) {
        formatter = get().getCurrencyFormatter();
      } else {
        formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          signDisplay: "negative",
        });
      }
      if (Object.is(val, -0) || Math.abs(val) < 0.01) {
        return formatter.format(0);
      } else {
        return formatter.format(val);
      }
    } else {
      return undefined;
    }
  },
  getCurrencyFormatter: () => {
    let currency_formatter = get().currency_formatter;
    const user = get().user;
    if (!currency_formatter && user.currency) {
      currency_formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      set({ currency_formatter: currency_formatter });
    }
    return currency_formatter;
  },
  getCurrencySymbol: () => {
    let currency_symbol = get().currency_symbol;
    if (!currency_symbol) {
      let currency_formatter = get().getCurrencyFormatter();
      if (currency_formatter) {
        currency_symbol = currency_formatter
          .format(0)
          .replaceAll("0", "")
          .replaceAll(".", "")
          .replaceAll(",", "");
        set({ currency_symbol: currency_symbol });
      }
    }
    return currency_symbol;
  },
  getFeatureFlagValue: (key) => {
    return get().user.feature_flags[key];
  },
  initializeUser: (data) => {
    set({ user: data, _INIT: true });
  },
  stripeFee: () => {
    let user_plan = get().userPlanType();
    if (user_plan === "FRE") {
      return new Decimal(0.0365);
    } else {
      return new Decimal(0.0315);
    }
  },
  stripeFeePercent: () => {
    let plan_type = get().userPlanType();
    if (plan_type === "FRE") {
      return new Decimal(0.034);
    } else {
      return new Decimal(0.029);
    }
  },
  updateUser: (data) => {
    get().updateUserState(data);
    updateUserApi(data).then((resp) => {
      console.log(resp);
    });
  },
  updateUserState: (data) => {
    set({ user: data });
  },
  updateUserStripeCustomer: (data) => {
    const user = get().user;
    set({ user: { ...user, stripe_customer: data } });
  },
  userCanPublish: () => {
    const user_plan_type = get().userPlanType();
    const user = get().user;
    if (
      user_plan_type === "FRE" &&
      !user.stripe_customer.default_payment_method
    ) {
      return false;
    } else {
      return true;
    }
  },
  userPlan: () => {
    let user = get().user;
    return user_plan(user);
  },
  userPlanType: () => {
    let user = get().user;
    return userPlanType(user);
  },
});
