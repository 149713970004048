import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  Grid,
  Paper,
  Divider,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Chip,
  styled,
  Link,
  InputAdornment,
  FormControlLabel,
  Switch,
  CircularProgress,
  Menu,
  Checkbox,
  Alert,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { useAccountState } from "../state/store";
import { getFormApi } from "../api/FormsApi";
import { useFormik } from "formik";
import { form_field_types } from "../utils/form_utils";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { ColorPickerDialog } from "./Proposal";
import CroppedImg from "./CroppedImg";
import { getEventItemsApi } from "../api/EventsApi";
import { update } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Input } from "react-dropzone-uploader";
import { sort_by_order } from "../utils/utils";
import { sort } from "fast-sort";
import _ from "lodash";
import { FontSelector } from "./FontSelector";
import { FileDropZoneDialog } from "./FileUploader";

const FormEditor = (props) => {
  const [initializeForm, initializeUser] = useAccountState((state) => [
    state.initializeForm,
    state.initializeUser,
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = React.useState(true);

  const [event_templates, setEventTemplates] = React.useState([]);

  React.useEffect(() => {
    initializeUser(props.user);
    let form_uuid = props.match.params.form_uuid;
    getFormApi(form_uuid).then((resp) => {
      console.log(resp.data);
      initializeForm(resp.data.form);
      setEventTemplates(resp.data.event_templates);
      setLoading(false);
    });
  }, []);

  return loading ? (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <CircularProgress sx={{ m: "auto", mt: "25%" }} />
    </Box>
  ) : (
    <Box sx={{ height: "calc(100vh - 50px)", overflow: "auto" }}>
      <Box sx={{ mb: "calc(100vh/2)" }}>
        <Box
          sx={{
            maxWidth: "800px",
            ml: "auto",
            mr: "auto",
            mt: "1rem",
            overflow: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper sx={{ p: "1rem" }}>
                <Grid container spacing={1}>
                  <FormDetails event_templates={event_templates} />
                  <FormDesign />
                  <FormFields />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const FormEditorSection = (props) => {
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ mb: "1rem" }}
    >
      <Grid item xs="auto">
        <Typography variant="h5">{props.title}</Typography>
      </Grid>
      {props.action && (
        <Grid item xs="auto">
          {props.action}
        </Grid>
      )}
      <Divider sx={{ width: "100%", mb: "0.5rem", mt: "0.5rem" }} />
      <Grid item xs={12} container spacing={1}>
        {props.children}
      </Grid>
    </Grid>
  );
};

const FormDetails = (props) => {
  const [form, updateForm] = useAccountState((state) => [
    state.form,
    state.updateForm,
  ]);
  const [copied, setCopied] = React.useState(false);
  const handleCreateEventChange = (e) => {
    let data = { create_event_on_submission: e.target.checked };
    if (!e.target.checked) {
      data.linked_event_template = null;
    }
    updateForm(data);
  };
  return (
    <>
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={"auto"}>
          <FormName />
        </Grid>
        <Grid item xs="auto" container spacing={4} alignItems={"center"}>
          <Grid item xs="auto">
            <FormControlLabel
              control={
                <Switch
                  checked={form.create_event_on_submission}
                  onChange={handleCreateEventChange}
                />
              }
              label={
                <Typography>
                  Create Event{" "}
                  <Tooltip title="If enabled, a new event will be created upon each form submission.">
                    <HelpOutlineOutlinedIcon fontSize="inherit" />
                  </Tooltip>
                </Typography>
              }
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs="auto">
            <CopyToClipboard
              text={process.env.REACT_APP_BASE_URL + "/form/" + form.uuid}
              onCopy={() => {
                setCopied(true);
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="small"
                id="copy-proposal-link-button"
              >
                Copy Sharable Link
              </Button>
            </CopyToClipboard>
            <Snackbar
              open={copied}
              autoHideDuration={3000}
              onClose={() => {
                setCopied(false);
              }}
              message="Link copied!"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormDetailsTextField
          id="description"
          label="Description"
          help="Description of the form for organization purposes only. This description will not be displayed on the form."
        />
      </Grid>
      {form.create_event_on_submission && (
        <Grid item xs={12}>
          <EventTemplateSelect event_templates={props.event_templates} />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormDetailsTextField
          id="return_url"
          label="Return URL"
          help="If supplied, form will contain a button allowing users to return to your site via this url."
        />
      </Grid>
    </>
  );
};

const FormDesign = (props) => {
  const [form, updateForm] = useAccountState((state) => [
    state.form,
    state.updateForm,
  ]);
  const [uploadingFile, setUploadingFile] = React.useState(false);
  const uploadLogo = (cropped_images) => {
    updateForm({ logo: cropped_images[0] });
    setUploadingFile(false);
  };
  return (
    <FormEditorSection title="Form Design">
      <Grid item xs={12} container justifyContent={"center"}>
        {form.logo ? (
          <Grid item xs={6} container justifyContent={"center"}>
            <Grid item xs="auto">
              <CroppedImg
                img={form.logo}
                marginBottom="2rem"
                marginTop="2rem"
                maxHeight="200px"
                maxWidth="100%"
              />
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              p: "1rem",
              border: "2px dashed lightgrey",
              borderRadius: "5px",
              m: "1rem",
            }}
          >
            <Typography variant="body2" color="info.main">
              No Logo Uploaded
            </Typography>
          </Box>
        )}
        <Grid item xs={12} container justifyContent={"center"} spacing={1}>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="info"
              onClick={() => setUploadingFile(true)}
              size="small"
            >
              Upload Logo
            </Button>
          </Grid>
          {form.logo ? (
            <Grid item xs="auto">
              <Button
                variant="outlined"
                color="info"
                onClick={() => updateForm({ logo: null })}
                size="small"
              >
                Remove Logo
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <FileDropZoneDialog
          open={uploadingFile}
          handleCancel={() => setUploadingFile(false)}
          handleSubmit={uploadLogo}
          maxSelections={1}
          forceRecrop
        />
      </Grid>
      <Grid item xs={4}>
        <FormColorSetting id="background_color" label="Background Color" />
      </Grid>
      <Grid item xs={4}>
        <FormColorSetting id="primary_text_color" label="Primary Text Color" />
      </Grid>
      <Grid item xs={4}>
        <FormColorSetting
          id="secondary_text_color"
          label="Secondary Text Color"
        />
      </Grid>
      <Grid item xs={6}>
        <FormDetailsSelectField
          id="primary_text_font"
          label="Primary Font"
          help="Font used for form field headings and the intro text"
        />
      </Grid>

      <Grid item xs={6}>
        <FormDetailsSelectField
          id="secondary_text_font"
          label="Secondary Font"
          help="Font used for form field helper text"
        />
      </Grid>

      <Grid item xs={12}>
        <FormDetailsTextField
          id="intro_text"
          label="Intro Text"
          multiline
          help="Text displayed at the beginning of the form, after the logo but before any form fields"
        />
      </Grid>
      <Grid item xs={12}>
        <FormDetailsTextField
          id="confirmation_text"
          label="Confirmation Text"
          multiline
          help="Text displayed after the form is submitted"
        />
      </Grid>
      <Grid item xs={12}>
        <FormDetailsTextField
          id="email_confirmation_text"
          label="Email Confirmation Text"
          multiline
          help="Text displayed in the confirmation email sent to the user after the form is submitted"
        />
      </Grid>
    </FormEditorSection>
  );
};

const FormColorSetting = (props) => {
  const [form, updateForm] = useAccountState((state) => [
    state.form,
    state.updateForm,
  ]);

  const [updatingColor, setUpdatingColor] = React.useState(false);

  const updateColor = (color) => {
    let data = { [props.id]: color };
    updateForm(data);
    setUpdatingColor(false);
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12}>
        <Typography textAlign="center" variant="body2">
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <Button
          onClick={() => setUpdatingColor(true)}
          variant="outlined"
          sx={{ m: "auto" }}
          id="background-color-button"
          color="info"
        >
          <Box
            sx={{
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              mr: ".25rem",
              backgroundColor: form[props.id],
              border: "1px solid",
            }}
          ></Box>
          {form[props.id]}
        </Button>
      </Grid>
      <ColorPickerDialog
        open={updatingColor}
        handleCancel={() => setUpdatingColor(false)}
        handleSubmit={updateColor}
      />
    </Grid>
  );
};

const FormName = (props) => {
  const [form] = useAccountState((state) => [state.form]);
  const [editing, setEditing] = React.useState(false);
  return editing ? (
    <FormDetailsTextField
      id="name"
      label="Name"
      onBlur={() => setEditing(false)}
    />
  ) : (
    <Typography variant="h5">
      {form.name}
      <IconButton size="small" onClick={() => setEditing(true)}>
        <EditOutlinedIcon fontSize="inherit" />
      </IconButton>
    </Typography>
  );
};

const FormDetailsTextField = (props) => {
  const [form, updateForm] = useAccountState((state) => [
    state.form,
    state.updateForm,
  ]);

  const [value, setValue] = React.useState(form[props.id] || "");

  React.useEffect(() => {
    setValue(form[props.id]);
  }, [props.id, form[props.id]]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    let data = { [props.id]: value };
    updateForm(data);
    if (props.onBlur) {
      props.onBlur();
    }
  };

  return (
    <TextField
      fullWidth
      id={props.id}
      name={props.id}
      label={props.label}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      size="small"
      variant="filled"
      multiline={props.multiline}
      minRows={props.minRows}
      helperText={props.helperText}
      InputProps={
        props.onBlur
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleBlur}>
                    <CheckOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : props.help
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={props.help}>
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }
          : null
      }
    />
  );
};
const FormDetailsSelectField = (props) => {
  const [form, updateForm] = useAccountState((state) => [
    state.form,
    state.updateForm,
  ]);

  const [value, setValue] = React.useState(form[props.id] || "");

  React.useEffect(() => {
    setValue(form[props.id]);
  }, [props.id, form[props.id]]);

  const handleChange = (e) => {
    setValue(e.target.value);
    let data = { [props.id]: e.target.value };
    updateForm(data);
  };

  return (
    <FontSelector
      id={props.id}
      value={value}
      label={props.label}
      updateFont={handleChange}
      size="small"
      variant="filled"
      endAdornment={
        props.help ? (
          <InputAdornment position="end">
            <Tooltip title={props.help}>
              <HelpOutlineOutlinedIcon sx={{ mr: "1rem" }} />
            </Tooltip>
          </InputAdornment>
        ) : undefined
      }
      formControlSx={{ mt: "0.5rem" }}
    />
  );
};

const EventTemplateSelect = (props) => {
  const [form, updateForm] = useAccountState((state) => [
    state.form,
    state.updateForm,
  ]);
  const [event_templates, setEventTemplates] = React.useState(
    props.event_templates
  );
  React.useEffect(() => {
    setEventTemplates(props.event_templates);
  }, [props.event_templates]);
  const label = "Link Form To Template (optional)";

  const handleChange = (e) => {
    updateForm({
      linked_event_template: e.target.value
        ? props.event_templates.find((t) => t.uuid === e.target.value)
        : null,
    });
  };
  return (
    <FormControl fullWidth sx={{ mt: "0.5rem" }} variant="filled">
      <InputLabel size="small" id={`event-template-select-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`event-template-select-label`}
        id={props.name}
        value={form.linked_event_template?.uuid}
        label={label}
        onChange={handleChange}
        size="small"
        variant="filled"
        endAdornment={
          <InputAdornment position="end">
            <Tooltip
              title={
                "The event template that will be used when creating a new event upon form submissions. If none is supplied, an event will be created from scratch."
              }
            >
              <HelpOutlineOutlinedIcon sx={{ mr: "1rem" }} />
            </Tooltip>
          </InputAdornment>
        }
      >
        {event_templates.map((option) => (
          <MenuItem value={option.uuid}>{option.name}</MenuItem>
        ))}
        <MenuItem value={null}>None</MenuItem>
      </Select>
    </FormControl>
  );
};

const FormFields = (props) => {
  const [form] = useAccountState((state) => [state.form]);
  const [addingField, setAddingField] = React.useState(false);

  const formErrors = () => {
    let errors = [];
    if (
      form.fields.length === 0 ||
      !form.fields.find((field) => field.type === "CD")
    ) {
      errors.push({
        message:
          "No contact details field added. Add a contact details field to allow users to submit their contact information.",
        severity: "error",
      });
    } else if (
      form.fields.length === 0 ||
      !form.fields.find((field) => field.type === "CD" && field.required)
    ) {
      errors.push({
        message:
          "Contact details field is present but not required. Mark at least one contact details field as required to ensure you collect contact information.",
        severity: "warning",
      });
    }
    if (
      form.fields.length === 0 ||
      !form.fields.find((field) => field.type === "ED")
    ) {
      errors.push({
        message:
          "No event date field added. Add an event date field to allow users to select a date for their event. If no event date field is added, the date of any events created from form submissions will default to 30 days after the form submission date.",
        severity: "error",
      });
    } else if (
      form.fields.length === 0 ||
      !form.fields.find((field) => field.type === "ED" && field.required)
    ) {
      errors.push({
        message:
          "Event date field is present but not required. Mark the event date field as required to ensure you collect a date for the event.",
        severity: "warning",
      });
    }
    return errors;
  };

  return (
    <FormEditorSection
      title="Form Fields"
      action={
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => setAddingField(true)}
        >
          Add Field
        </Button>
      }
    >
      <>
        {formErrors().map((error) => (
          <Grid item xs={12}>
            <Alert severity={error.severity}>{error.message}</Alert>
          </Grid>
        ))}
        {form.fields && form.fields.length > 0 ? (
          form.fields.map((field, idx) => {
            return (
              <FormField
                id={field.uuid}
                key={field.uuid}
                field={field}
                index={idx}
                last={idx === form.fields.length - 1}
              />
            );
          })
        ) : (
          <Grid item xs={12}>
            <Box
              sx={{
                border: "dashed 2px",
                borderColor: "info.main",
                borderRadius: "5px",
                p: "1rem",
                m: "1rem",
              }}
            >
              <Typography textAlign={"center"}>No fields added.</Typography>
            </Box>
          </Grid>
        )}
      </>
      <AddFieldDialog
        open={addingField}
        handleCancel={() => setAddingField(false)}
      />
    </FormEditorSection>
  );
};

const AddFieldDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [addFormField] = useAccountState((state) => [state.addFormField]);
  const formik = useFormik({
    initialValues: {
      type: "GT",
    },
    onSubmit: (values) => {
      addFormField(
        values.type,
        props.index === undefined ? undefined : props.index
      );
      props.handleCancel();
    },
  });
  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add Field</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: "0.5rem" }}>
          <InputLabel id="field-type-label">Field Type</InputLabel>
          <Select
            labelId="field-type-label"
            id="type"
            value={formik.values.type}
            label="Field Type"
            onChange={(e) => {
              formik.setFieldValue("type", e.target.value);
            }}
          >
            {Object.entries(form_field_types).map(([key, value]) => (
              <MenuItem value={key}>{value.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="body2" sx={{ mt: ".5rem" }}>
          {form_field_types[formik.values.type].description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={formik.handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const FormField = (props) => {
  const [
    decrementFormFieldOrder,
    deleteFormField,
    incrementFormFieldOrder,
    updateFormField,
  ] = useAccountState((state) => [
    state.decrementFormFieldOrder,
    state.deleteFormField,
    state.incrementFormFieldOrder,
    state.updateFormField,
  ]);
  const [field, setField] = React.useState(props.field);
  React.useEffect(() => {
    setField(props.field);
  }, [props.field]);

  const updateFieldFunc = (field_name) => (value) => {
    let data = { [field_name]: value };
    updateFormField(data, props.index);
  };

  const handleBlurFunc = (field_name) => (e) => {
    let data = { [field_name]: e.target.value };
    updateFormField(data, props.index);
  };

  const [addingField, setAddingField] = React.useState(false);
  const [addingFieldEnd, setAddingFieldEnd] = React.useState(false);

  const orderDown = () => decrementFormFieldOrder(props.index);
  const orderUp = () => incrementFormFieldOrder(props.index);

  const [hovering, setHovering] = React.useState(false);
  const setIsHovering = () => setHovering(true);
  const setIsNotHovering = () => setHovering(false);

  const formFieldErrors = () => {
    let errors = [];
    if (!field.name) {
      errors.push({
        message:
          "No question text added. Question fields will appear unlabeled and may confuse clients.",
        severity: "warning",
      });
    }
    if (field.type === "CD" && !field.email && !field.phone) {
      errors.push({
        message:
          "No contact details fields selected. Enable at least one of email or phone sub-fields to collect contact information.",
        severity: "error",
      });
    }
    if (
      field.type === "CD" &&
      field.required &&
      !field["email_required"] &&
      !field["phone_required"]
    ) {
      errors.push({
        message:
          "Contact details field is required, but no required sub-fields selected. Mark at least one of email or phone sub-fields as required to ensure you collect contact information.",
        severity: "error",
      });
    }
    return errors;
  };

  return (
    <>
      <Root>
        <Divider onMouseEnter={setIsHovering} onMouseLeave={setIsNotHovering}>
          <IconButton onClick={() => setAddingField(true)} size="small">
            <AddCircleOutlineOutlinedIcon fontSize="inherit" />
          </IconButton>
          <AddFieldDialog
            open={addingField}
            handleCancel={() => {
              setAddingField(false);
            }}
            index={props.index}
          />
        </Divider>
      </Root>
      <Paper
        sx={{
          width: "95%",
          m: "auto",
          mt: ".5rem",
          p: ".5rem",
          border: "1px lightgrey solid",
        }}
      >
        <Grid item xs={12} container spacing={1} alignItems={"center"}>
          <Grid
            item
            xs={12}
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item xs="auto">
              <Typography variant="h6" fontWeight={"light"}>
                {form_field_types[field.type]
                  ? form_field_types[field.type].name
                  : ""}
              </Typography>
            </Grid>

            <Grid
              item
              xs="auto"
              container
              justifyContent={"flex-end"}
              alignItems={"center"}
              spacing={1}
            >
              <Grid item xs="auto">
                <IconButton
                  size="small"
                  onClick={orderUp}
                  disabled={props.index === 0}
                >
                  <ArrowUpwardOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Grid>
              <Grid item xs="auto">
                <IconButton
                  size="small"
                  onClick={orderDown}
                  disabled={props.last}
                >
                  <ArrowDownwardOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Grid>
              <ControlsDivider />
              <Grid item xs="auto">
                <IconButton
                  onClick={() => deleteFormField(props.index)}
                  size="small"
                >
                  <DeleteOutlineOutlinedIcon
                    sx={{ color: "info.main" }}
                    fontSize="inherit"
                  />
                </IconButton>
              </Grid>
              <ControlsDivider />
              <Grid item xs="auto">
                <FormFieldSwitch
                  name="required"
                  label="Required"
                  field={field}
                  updateFieldFunc={updateFieldFunc}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              fontWeight={"light"}
              sx={{ lineHeight: ".5rem" }}
            >
              {form_field_types[field.type]?.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormFieldTextField
              name={"name"}
              label="Question Text (appears as bold)"
              handleBlurFunc={handleBlurFunc}
              field={field}
            />
          </Grid>
          <Grid item xs={12}>
            <FormFieldTextField
              name={"description"}
              label="Description text to help your client understand the question"
              handleBlurFunc={handleBlurFunc}
              field={field}
            />
          </Grid>
          {field.type === "GT" && (
            <Grid item xs={4}>
              <FormFieldSelect
                name={"text_type"}
                label="Text Type"
                handleChangeFunc={handleBlurFunc}
                field={field}
                options={["Single Line", "Paragraph"]}
              />
            </Grid>
          )}
          {field.type === "GN" && (
            <Grid item xs={4}>
              <FormFieldSelect
                name={"number_type"}
                label="Number Type"
                handleChangeFunc={handleBlurFunc}
                field={field}
                options={["Integer", "Decimal"]}
              />
            </Grid>
          )}
          {(field.type === "MC" || field.type === "MS") && (
            <Grid item xs={8}>
              <FormFieldOptions
                name={"options"}
                label="Options"
                field={field}
                updateFieldFunc={updateFieldFunc}
              />
            </Grid>
          )}
          {field.type === "RQ" && (
            <RecipeOptions field={field} updateFieldFunc={updateFieldFunc} />
          )}
          {(field.type === "CD" || field.type == "CC") && (
            <>
              <Grid item xs={12}>
                <ContactDetailsFieldSwitch
                  id="name"
                  label="Name"
                  field={field}
                  updateFieldFunc={updateFieldFunc}
                  forceEnabled
                  overrideRequried={field.required}
                />
              </Grid>
              <Grid item xs={12}>
                <ContactDetailsFieldSwitch
                  id="email"
                  label="Email"
                  field={field}
                  updateFieldFunc={updateFieldFunc}
                />
              </Grid>
              <Grid item xs={12}>
                <ContactDetailsFieldSwitch
                  id="phone"
                  label="Phone"
                  field={field}
                  updateFieldFunc={updateFieldFunc}
                />
              </Grid>
              {field.type == "CC" && (
                <>
                  <Grid item xs={12}>
                    <ContactDetailsFieldSwitch
                      id="company"
                      label="Company"
                      field={field}
                      updateFieldFunc={updateFieldFunc}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ContactDetailsFieldSwitch
                      id="website"
                      label="Website"
                      field={field}
                      updateFieldFunc={updateFieldFunc}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <ContactDetailsFieldSwitch
                  id="role"
                  label="Role"
                  field={field}
                  updateFieldFunc={updateFieldFunc}
                />
              </Grid>
            </>
          )}
          {formFieldErrors().map((error) => (
            <Grid item xs={12}>
              <Alert size="small" severity={error.severity}>
                {error.message}
              </Alert>
            </Grid>
          ))}
        </Grid>
      </Paper>
      {props.last && (
        <Root>
          <Divider onMouseEnter={setIsHovering} onMouseLeave={setIsNotHovering}>
            <IconButton onClick={() => setAddingFieldEnd(true)} size="small">
              <AddCircleOutlineOutlinedIcon fontSize="inherit" />
            </IconButton>
            <AddFieldDialog
              open={addingFieldEnd}
              handleCancel={() => {
                setAddingFieldEnd(false);
              }}
            />
          </Divider>
        </Root>
      )}
    </>
  );
};

const ContactDetailsFieldSwitch = (props) => {
  return (
    <Grid container>
      <Grid item xs="auto">
        <FormFieldSwitch
          name={props.id}
          label={props.label}
          field={props.field}
          updateFieldFunc={props.updateFieldFunc}
          width="90px"
          forceEnabled={props.forceEnabled}
        />
      </Grid>
      <Grid item xs="auto">
        <FormFieldSwitch
          name={`${props.id}_required`}
          label={`Required`}
          field={props.field}
          updateFieldFunc={props.updateFieldFunc}
          override={
            props.field.required
              ? props.overrideRequried ||
                (!props.field[props.id] ? false : undefined)
              : false
          }
        />
      </Grid>
    </Grid>
  );
};

const ControlsDivider = (props) => {
  return (
    <Grid item xs="auto">
      <Typography sx={{ color: "info.light" }}>|</Typography>
    </Grid>
  );
};

const FormFieldTextField = (props) => {
  const [value, setValue] = React.useState(props.field[props.name] || "");
  React.useEffect(() => {
    setValue(props.field[props.name]);
  }, [props.field[props.name]]);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <TextField
      fullWidth
      id={props.name}
      label={props.label}
      value={value}
      size="small"
      onChange={handleChange}
      onBlur={props.handleBlurFunc(props.name)}
      variant="filled"
    />
  );
};

const FormFieldSelect = (props) => {
  const [value, setValue] = React.useState(
    props.field[props.name] ? props.field[props.name] : props.default
  );
  React.useEffect(() => {
    setValue(props.field[props.name]);
  }, [props.field[props.name]]);

  return (
    <FormControl fullWidth sx={{ mt: "0.5rem" }} variant="filled">
      <InputLabel size="small" id={`${props.name}-label`}>
        {props.label}
      </InputLabel>
      <Select
        labelId={`${props.name}-label`}
        id={props.name}
        value={value}
        label={props.label}
        onChange={props.handleChangeFunc(props.name)}
        size="small"
        defaultValue={props.default}
        variant="filled"
      >
        {props.options.map((option) => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const FormFieldOptions = (props) => {
  const [value, setValue] = React.useState(props.field[props.name] || []);
  React.useEffect(() => {
    setValue(props.field[props.name]);
  }, [props.field[props.name]]);

  const updateField = props.updateFieldFunc(props.name);

  const addOption = () => {
    let val = value ? value : [];
    let new_value = [...val, "New Option"];
    updateField(new_value);
  };

  const deleteOption = (index) => {
    let val = value ? value : [];
    let new_value = val.filter((option, idx) => idx !== index);
    updateField(new_value);
  };

  const updateOption = (index, new_option) => {
    let val = value ? value : [];
    let new_value = val.map((option, idx) => {
      if (idx === index) {
        return new_option;
      } else {
        return option;
      }
    });
    updateField(new_value);
  };

  return (
    <Grid container alignItems={"center"} spacing={1}>
      {value && value.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="body2">Options: </Typography>
        </Grid>
      )}
      {value &&
        value.map((option, idx) => (
          <Grid item xs={12}>
            <FormFieldOption
              option={option}
              index={idx}
              deleteOption={deleteOption}
              updateOption={updateOption}
            />
          </Grid>
        ))}
      <Grid item xs="auto">
        <Typography variant="body2">
          <IconButton onClick={addOption} size="small">
            <AddCircleOutlineOutlinedIcon fontSize="inherit" />
          </IconButton>
          Add Option
        </Typography>
      </Grid>
      {!value && (
        <Grid item xs={12}>
          <Alert severity="warning">
            No options have been added to question. Click the button above to
            add options.
          </Alert>{" "}
        </Grid>
      )}
    </Grid>
  );
};

const FormFieldOption = (props) => {
  const [value, setValue] = React.useState(props.option);
  React.useEffect(() => {
    setValue(props.option);
  }, [props.option]);
  const [editing, setEditing] = React.useState(false);
  const deleteOption = () => {
    props.deleteOption(props.index);
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const save = () => {
    props.updateOption(props.index, value);
    setEditing(false);
  };
  return editing ? (
    <TextField
      value={value}
      size="small"
      onChange={handleChange}
      onBlur={save}
      sx={{ minWidth: "350px" }}
      multiline
      inputProps={{ sx: { fontSize: "0.75rem", pl: 0.5, pt: 0.5, pb: 0.5 } }}
      InputProps={{
        sx: { pr: 0 },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={save}>
              <CheckOutlinedIcon sx={{ fontSize: ".75rem" }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <Chip
      label={value}
      size="small"
      onDelete={deleteOption}
      onClick={() => setEditing(true)}
      variant="outlined"
    />
  );
};

const FormFieldSwitch = (props) => {
  const overrideActive = props.override !== undefined;

  const getValue = () => {
    console.log(props.name);
    if (props.forceEnabled) {
      console.log("FORCE ENABLED");
      return true;
    } else if (overrideActive) {
      console.log("OVERRIDE ACTIVE", props.override);
      return props.override;
    } else if (props.field[props.name]) {
      console.log("FIELD", props.field[props.name]);
      return props.field[props.name];
    } else {
      console.log("DEFAULT", props.default);
      return props.default;
    }
  };

  const [value, setValue] = React.useState(getValue());
  React.useEffect(() => {
    setValue(getValue());
  }, [props.field[props.name], props.override, overrideActive]);
  const updateField = props.updateFieldFunc(props.name);

  const handleChange = (e) => {
    setValue(e.target.checked);
    updateField(e.target.checked);
  };
  return (
    <Box sx={{ pl: ".5rem" }}>
      <FormControlLabel
        sx={{ width: props.width }}
        control={
          <Switch
            checked={value}
            onChange={handleChange}
            size="small"
            disabled={props.forceEnabled || overrideActive}
          />
        }
        label={
          <Typography sx={{ fontSize: ".75rem", color: "info.main" }}>
            {props.label}
          </Typography>
        }
        size="small"
      />
    </Box>
  );
};

const RecipeOptions = (props) => {
  const updateField = props.updateFieldFunc("recipes");
  const [form] = useAccountState((state) => [state.form]);
  const [recipes, setRecipes] = React.useState([]);
  React.useEffect(() => {
    if (form.linked_event_template) {
      getEventItemsApi(form.linked_event_template.uuid).then((resp) => {
        setRecipes(resp.data);
        let new_recipes = props.field.recipes?.map((r) => {
          let new_name = resp.data.find((o) => o?.uuid === r?.uuid)?.name;
          return { ...r, name: new_name ? new_name : r?.name };
        });
        if (!_.isEqual(new_recipes, props.field.recipes)) {
          updateField(new_recipes);
        }
      });
    } else {
      setRecipes([]);
    }
  }, [form.linked_event_template]);
  const [addingRecipes, setAddingRecipes] = React.useState(false);
  const addOption = () => {
    setAddingRecipes(true);
  };

  const deleteRecipe = (uuid) => {
    let new_recipes = props.field.recipes.filter((r, idx) => r.uuid !== uuid);
    updateField(new_recipes);
  };
  return (
    <Grid container alignItems={"center"}>
      {props.field.recipes && (
        <>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ ml: ".5rem" }}>
              Selected Recipes:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {props.field.recipes &&
              props.field.recipes
                .filter((r) => r)
                .sort(sort_by_order)
                .map((recipe, idx) => {
                  return (
                    <RecipeOption
                      recipe={recipe}
                      index={idx}
                      deleteRecipe={deleteRecipe}
                      recipe_options={recipes}
                    />
                  );
                })}
          </Grid>
        </>
      )}

      <Grid item xs="auto">
        {form.linked_event_template ? (
          <>
            <Typography variant="body2">
              <IconButton onClick={addOption} size="small">
                <AddCircleOutlineOutlinedIcon fontSize="inherit" />
              </IconButton>
              Add Recipe from {form.linked_event_template.name}
            </Typography>
            <RecipeOptionsDialog
              recipe_options={recipes}
              recipes={props.field.recipes}
              open={addingRecipes}
              handleCancel={() => setAddingRecipes(false)}
              handleSubmit={(recipes) => {
                updateField(recipes);
              }}
            />
          </>
        ) : (
          <Alert severity="warning" sx={{ ml: ".5rem" }}>
            No event template is linked to form.{" "}
            <Tooltip
              title="Link an event template in the
            form settings in order to add recipe quantity fields."
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" />
            </Tooltip>
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};

const RecipeOption = (props) => {
  const [recipeOptionsUuids, setRecipeOptionsUuids] = React.useState(
    props.recipe_options.map((r) => r.uuid) || []
  );
  React.useEffect(() => {
    setRecipeOptionsUuids(props.recipe_options.map((r) => r.uuid) || []);
  }, [props.recipe_options]);
  return (
    <Typography variant="body2" sx={{ ml: ".5rem" }}>
      {props.recipe.name}
      <IconButton
        size="small"
        onClick={() => props.deleteRecipe(props.recipe.uuid)}
      >
        <CancelOutlinedIcon fontSize="inherit" />
      </IconButton>
      {!recipeOptionsUuids.includes(props.recipe.uuid) && (
        <Tooltip title="This recipe is not in the event template linked to this form and will not be included as an option.">
          <IconButton size="small" color="error">
            <WarningAmberOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
    </Typography>
  );
};

const RecipeOptionsDialog = (props) => {
  const [recipes, setRecipes] = React.useState(
    props.recipes?.filter((r) => r).map((r) => r.uuid) || []
  );

  React.useEffect(() => {
    setRecipes(props.recipes?.filter((r) => r).map((r) => r.uuid) || []);
  }, [props.recipes]);

  const handleChange = (e) => {
    if (e.target.checked) {
      setRecipes([...recipes, e.target.name]);
    } else {
      setRecipes(recipes.filter((r) => r !== e.target.name));
    }
  };

  const handleSubmit = () => {
    let slected_recipes = recipes.map((r) =>
      props.recipe_options.find((o) => o.uuid === r)
    );
    props.handleSubmit(slected_recipes);
    props.handleCancel();
  };

  const selectAll = () => {
    setRecipes(props.recipe_options.map((r) => r.uuid));
  };

  const clearSelection = () => {
    setRecipes([]);
  };

  const getEventItemByUuid = (uuid) => {
    return props.recipe_options.find((r) => r.uuid === uuid);
  };

  const getEventItemGroupOrder = (recipe) => {
    let event_item_group = getEventItemByUuid(recipe.event_item_group);
    return event_item_group ? event_item_group.order : recipe.order;
  };

  const getEventItemGroupName = (recipe) => {
    let event_item_group = getEventItemByUuid(recipe.event_item_group);
    return event_item_group ? event_item_group.name : "";
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Add Recipe Options</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={"auto"}>
              <Button
                size="small"
                color="info"
                variant="outlined"
                onClick={selectAll}
              >
                Select All
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                size="small"
                color="info"
                variant="outlined"
                onClick={clearSelection}
              >
                Clear Selection
              </Button>
            </Grid>
          </Grid>
          {sort(props.recipe_options)
            .by([
              { asc: (r) => getEventItemGroupOrder(r) },
              { asc: (r) => (r.event_item_group ? r.order : -1) },
            ])
            .map((recipe, idx) => {
              return (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={recipes.includes(recipe.uuid)}
                        onChange={handleChange}
                        name={recipe.uuid}
                      />
                    }
                    label={
                      recipe.event_item_group
                        ? getEventItemGroupName(recipe) + " > " + recipe.name
                        : recipe.name
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(FormEditor);
