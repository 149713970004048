import { Box, Link, Typography } from "@mui/material";
import React from "react";

export const EmptyStateBox = ({ children }) => {
  const c = React.Children.toArray(children);
  return (
    <Box
      sx={{
        border: "1px dashed",
        borderColor: "lightgray",
        borderRadius: "5px",
        p: "1rem",
      }}
    >
      {c}
    </Box>
  );
};

export const EmptyStateText = ({ children, action }) => {
  const c = React.Children.toArray(children);
  return (
    <EmptyStateBox>
      <Typography sx={{ color: "lightgray" }}>
        {c[0]}
        {c.length > 1 && (
          <>
            {" "}
            <Link
              sx={{ color: "lightgray", cursor: "pointer" }}
              onClick={action}
            >
              {c[1]}
            </Link>
          </>
        )}
      </Typography>
    </EmptyStateBox>
  );
};
