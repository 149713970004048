import {
  AddPaletteItemApi,
  RemovePaletteItemApi,
  UpdatePaletteApi,
} from "../api/EventVersionApi";

export const paletteItemSlice = (set, get) => ({
  addPaletteItem: (item_uuid, cb) => {
    let event_version = get().event_version;
    AddPaletteItemApi(item_uuid, event_version.uuid).then((response) => {
      let palette_item = response.data;
      let palette_items = event_version.palette_items;
      var new_palette_items;
      if (Array.isArray(palette_item)) {
        new_palette_items = [...palette_items, ...palette_item];
      } else {
        new_palette_items = [...palette_items, palette_item];
      }
      set({
        event_version: { ...event_version, palette_items: new_palette_items },
      });
      get().addSelectableFlowers(new_palette_items.map((pi) => pi.item));
      if (cb) {
        cb(new_palette_items);
      }
    });
  },
  deletePaletteItem: (palette_item_uuid, cb) => {
    let event_version = get().event_version;
    let palette_items = event_version.palette_items;
    let new_palette_items = palette_items.filter(
      (item) => item.uuid !== palette_item_uuid
    );
    set({
      event_version: { ...event_version, palette_items: new_palette_items },
    });
    RemovePaletteItemApi(palette_item_uuid);
    if (cb) {
      cb();
    }
  },
  updatePalette: (item_uuids, cb) => {
    let event_version = get().event_version;
    UpdatePaletteApi(item_uuids, event_version.uuid).then((response) => {
      let palette_items = response.data;
      set({
        event_version: { ...event_version, palette_items: palette_items },
      });
      get().addSelectableFlowers(palette_items.map((pi) => pi.item));
      if (cb) {
        cb();
      }
    });
  },
});
