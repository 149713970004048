import { Decimal } from "decimal.js";

import { recipes } from "./event_items_utils";
import { decimalConfig, quantityField } from "./utils";
import { sort } from "fast-sort";

Decimal.set(decimalConfig);

export function itemDisplayName(item) {
  if (item.item_type === "FL") {
    return flowerDisplayName(item);
  } else if (item.item_type === "MA") {
    return materialDisplayName(item);
  }
}

export const sortByItemDisplayName = (a, b) => {
  let a_name = itemDisplayName(a);
  let b_name = itemDisplayName(b);
  return a_name.localeCompare(b_name);
};

export function flowerDisplayName(item) {
  return item.name + " " + item.color_name;
}

export function materialDisplaySize(material) {
  return [material.length, material.width, material.height]
    .filter((x) => x)
    .join(" X ");
}

function materialDisplayName(material) {
  let name = materialTypeName(material.type) + " " + material.name;
  if (material.width | material.height | material.length) {
    name = name + " " + materialDisplaySize(material);
  }
  return name;
}

export default function itemCost(item_price) {
  if (item_price) {
    if (item_price.individual_per_group > 0) {
      return new Decimal(item_price.price_per_group).dividedBy(
        item_price.individual_per_group
      );
    } else {
      return new Decimal(0);
    }
  } else {
    return new Decimal(0);
  }
}

export function itemCostMultiplier(recipe_item, back_office) {
  let multiplier = new Decimal(1);
  if (recipe_item.item.item_type === "FL") {
    multiplier = new Decimal(back_office.flower_cost_multiplier);
  } else {
    if (recipe_item.item_sale_type == "SE") {
      multiplier = new Decimal(back_office.material_sale_cost_multiplier);
    } else {
      multiplier = new Decimal(back_office.material_rental_cost_multiplier);
    }
  }
  return multiplier;
}

export function itemLineLabor(item) {
  return item.quantity * item.item.unit_labor_estimate_minutes;
}

export function itemCountsCost(item_counts) {
  if (item_counts.length > 0) {
    return item_counts
      .map((ic) =>
        ic.item.item_type === "FL" ? ic.groups_cost : ic.individuals_cost
      )
      .reduce((p, c) => p.plus(c), new Decimal(0));
  } else {
    return 0;
  }
}

export function itemGroupNamePlural(quantity, group_name) {
  const groupNames = { ...flowerGroupOptions, ...materialGroupOptions };

  return quantity > 1
    ? groupNames[group_name]?.plural
    : groupNames[group_name]?.singular;
}

export function itemPriceGroupName(quantity, item_price) {
  if (item_price) {
    return itemGroupNamePlural(quantity, item_price.group_name);
  } else {
    return itemGroupNamePlural(quantity, "BU");
  }
}

export function itemUnitName(item) {
  if (item.item_type === "FL") {
    return flowerUnitOptions[item.unit_name]?.singular;
  } else {
    return materialUnitOptions[item.type]?.singular;
  }
}

export function itemUnitPluralName(item) {
  if (item.item_type === "FL") {
    return flowerUnitOptions[item.unit_name]?.plural;
  } else {
    return materialUnitOptions[item.type]?.plural;
  }
}

export function itemCategories() {
  return ["focal", "filler", "gesture", "texture", "foliage", "dried"];
}

export const getItemCategories = (item) => {
  return itemCategories().filter((c) => item[c]);
};

export function itemSeasons() {
  return ["spring", "summer", "fall", "winter", "all_seasons"];
}

export const getItemSeasons = (item) => {
  return itemSeasons().filter((s) => item[s]);
};

export function itemColors(item_type) {
  if (item_type === "FL") {
    return flower_colors;
  } else if (item_type === "MA") {
    return material_colors;
  }
}

export function getItemColors(item) {
  let colors = itemColors(item.item_type).filter((c) => item[c.value]);
  return colors;
}

export function itemTypes(item_type) {
  if (item_type === "FL") {
    return flower_type_options;
  } else if (item_type === "MA") {
    return Object.entries(materialUnitOptions).map(([key, obj]) => {
      return { label: obj.label, value: obj.label.toLocaleLowerCase() };
    });
  }
}

export const flower_type_options = [
  { label: "Focal", value: "focal" },
  { label: "Filler", value: "filler" },
  { label: "Gesture", value: "gesture" },
  { label: "Texture", value: "texture" },
  { label: "Foliage", value: "foliage" },
  { label: "Dried", value: "dried" },
];

export const flower_colors = [
  {
    label: "Red",
    value: "red",
    color_code: "#d21632",
  },
  {
    label: "Burgundy",
    value: "burgundy",
    color_code: "#5d1017",
  },
  {
    label: "Pink",
    value: "pink",
    color_code: "#f67fb9",
  },
  {
    label: "Blush",
    value: "blush",
    color_code: "#eecccc",
  },
  {
    label: "Orange",
    value: "orange",
    color_code: "#ff7914",
  },
  {
    label: "Peach",
    value: "peach",
    color_code: "#fa9a85",
  },
  {
    label: "Yellow",
    value: "yellow",
    color_code: "#ffe242",
  },
  {
    label: "Green",
    value: "green",
    color_code: "#0f8c24",
  },
  {
    label: "Blue",
    value: "blue",
    color_code: "#230bc1",
  },
  {
    label: "Lavendar",
    value: "lavendar",
    color_code: "#c5a9d6",
  },
  {
    label: "Purple",
    value: "purple",
    color_code: "#8b3bb7",
  },
  {
    label: "White",
    value: "white",
    color_code: "#ffffff",
  },
  {
    label: "Ivory",
    value: "ivory",
    color_code: "#f9efe3",
  },
  {
    label: "Brown",
    value: "brown",
    color_code: "#6e493a",
  },
  {
    label: "Black",
    value: "black",
    color_code: "#000000",
  },
];

export const material_colors = [
  ...flower_colors,
  {
    label: "Clear",
    value: "clear",
    color_code: "#cfe2f3",
  },
  {
    label: "Off White",
    value: "off_white",
    color_code: "#f3f1f1",
  },
  {
    label: "Zinc",
    value: "zinc",
    color_code: "#efefef",
  },
  {
    label: "Silver",
    value: "silver",
    color_code: "#d9d9d9",
  },
  {
    label: "Grey",
    value: "grey",
    color_code: "#999999",
  },
  {
    label: "Smoke",
    value: "smoke",
    color_code: "#666666",
  },
  {
    label: "Gold",
    value: "gold",
    color_code: "#fbbc04",
  },
  {
    label: "Rose Gold",
    value: "rose_gold",
    color_code: "#e0ad8a",
  },
  {
    label: "Antique Gold",
    value: "antique_gold",
    color_code: "#bf9000",
  },
  {
    label: "Brass",
    value: "brass",
    color_code: "#e1c16e",
  },
  {
    label: "Copper",
    value: "copper",
    color_code: "#b87333",
  },
  {
    label: "Iron",
    value: "iron",
    color_code: "#434343",
  },
  {
    label: "Light Wood",
    value: "light_wood",
    color_code: "#ba8c63",
  },
  {
    label: "Dark Wood",
    value: "dark_wood",
    color_code: "#815438",
  },
  {
    label: "Tan",
    value: "tan",
    color_code: "#f6b26b",
  },
  {
    label: "Terracotta",
    value: "terracotta",
    color_code: "#b45f06",
  },
  {
    label: "Natural",
    value: "natural",
    color_code: "#fce5cd",
  },
  {
    label: "Champagne",
    value: "champagne",
    color_code: "#fff2cc",
  },
  {
    label: "Light Pink",
    value: "light_pink",
    color_code: "#f4cccc",
  },
];

export const getColorByCode = (color_code) => {
  let color = material_colors.find((c) => c.color_code === color_code);

  return color;
};

export const getColorNameByCode = (color_code) => {
  let color = getColorByCode(color_code);

  return color?.label;
};

export const season_options = [
  { label: "Spring", value: "spring" },
  { label: "Summer", value: "summer" },
  { label: "Fall", value: "fall" },
  { label: "Winter", value: "winter" },
  { label: "All", value: "all_seasons" },
];

export const itemPricesDictFromList = (item_prices_list) => {
  let item_prices_dict = {};
  item_prices_list.forEach(
    (item_price, i) => (item_prices_dict[item_price.item] = item_price)
  );
  return item_prices_dict;
};

export function materialTypeName(material_type) {
  return materialUnitOptions[material_type]?.label;
}

export function recipeItemsByType(recipe, type) {
  return recipe.recipe_items.filter((ri) => ri.item.item_type === type);
}

export function recipeFlowers(recipe) {
  return recipeItemsByType(recipe, "FL");
}

export function recipeMaterials(recipe) {
  return recipeItemsByType(recipe, "MA");
}

export const flowerGroupOptions = {
  ST: { label: "Stems", singular: "Stem", plural: "Stems" },
  BR: { label: "Branches", singular: "Branch", plural: "Branches" },
  PL: { label: "Plants", singular: "Plant", plural: "Plants" },
  BU: { label: "Bunches", singular: "Bunch", plural: "Bunches" },
  BO: { label: "Boxes", singular: "Box", plural: "Boxes" },
};

export const materialGroupOptions = {
  SE: { label: "Sets", singular: "Set", plural: "Sets" },
  BO: { label: "Boxes", singular: "Box", plural: "Boxes" },
  CA: { label: "Cases", singular: "Case", plural: "Cases" },
  IT: { label: "Items", singular: "Item", plural: "Items" },
};

export const flowerUnitOptions = {
  ST: { label: "Stem", singular: "Stem", plural: "Stems" },
  BR: { label: "Branch", singular: "Branch", plural: "Branches" },
  PL: { label: "Plant", singular: "Plant", plural: "Plants" },
  BU: { label: "Bunch", singular: "Bunch", plural: "Bunches" },
};

export const materialUnitOptions = {
  AC: { label: "Acrylic", singular: "Acrylic", plural: "Acrylics" },
  BA: { label: "Bag", singular: "Bag", plural: "Bags" },
  BS: { label: "Basket", singular: "Basket", plural: "Baskets" },
  BO: { label: "Boat", singular: "Boat", plural: "Boats" },
  BW: { label: "Bowl", singular: "Bowl", plural: "Bowls" },
  BU: { label: "Budvase", singular: "Budvase", plural: "Budvases" },
  BD: { label: "Backdrop", singular: "Backdrop", plural: "Backdrops" },
  CA: { label: "Candle", singular: "Candle", plural: "Candles" },
  CB: { label: "Candleabra", singular: "Candleabra", plural: "Candleabras" },
  CH: {
    label: "Candle Holder",
    singular: "Candle Holder",
    plural: "Candle Holders",
  },
  CS: {
    label: "Candle Stick",
    singular: "Candle Stick",
    plural: "Candle Sticks",
  },
  CD: { label: "Chandelier", singular: "Chandelier", plural: "Chandeliers" },
  CC: { label: "Cloche", singular: "Cloche", plural: "Cloches" },
  CL: { label: "Column", singular: "Column", plural: "Columns" },
  CO: { label: "Compote", singular: "Compote", plural: "Compotes" },
  CN: { label: "Container", singular: "Container", plural: "Containers" },
  CR: { label: "Crate", singular: "Crate", plural: "Crates" },
  CU: { label: "Cube", singular: "Cube", plural: "Cubes" },
  CY: { label: "Cylinder", singular: "Cylinder", plural: "Cylinders" },
  DS: { label: "Dish", singular: "Dish", plural: "Dishes" },
  DR: { label: "Drapery", singular: "Drapery", plural: "Draperies" },
  DF: {
    label: "Dried Flower",
    singular: "Dried Flower",
    plural: "Dried Flowers",
  },
  EQ: { label: "Equipment", singular: "Equipment", plural: "Equipment" },
  FA: { label: "Fabric", singular: "Fabric", plural: "Fabrics" },
  FO: { label: "Foam", singular: "Foam", plural: "Foam" },
  FR: { label: "Frog", singular: "Frog", plural: "Frogs" },
  FU: { label: "Furniture", singular: "Furniture", plural: "Furniture" },
  GA: { label: "Gate", singular: "Gate", plural: "Gates" },
  GS: {
    label: "General Supplies",
    singular: "General Supply",
    plural: "General Supplies",
  },
  GL: { label: "Globe", singular: "Globe", plural: "Globes" },
  GU: { label: "Glue", singular: "Glue", plural: "Glue" },
  HA: { label: "Hanger", singular: "Hanger", plural: "Hangers" },
  HK: { label: "Hook", singular: "Hook", plural: "Hooks" },
  HP: { label: "Hoop", singular: "Hoop", plural: "Hoops" },
  HU: { label: "Hurricane", singular: "Hurricane", plural: "Hurricanes" },
  LA: { label: "Lantern", singular: "Lantern", plural: "Lanterns" },
  LI: { label: "Lighting", singular: "Lighting", plural: "Lightings" },
  LN: { label: "Linens", singular: "Linen", plural: "Linens" },
  MW: { label: "Mesh Wire", singular: "Mesh Wire", plural: "Mesh Wires" },
  OT: { label: "Other", singular: "Other", plural: "Others" },
  PT: { label: "Paint", singular: "Paint", plural: "Paint" },
  PE: { label: "Pedestal", singular: "Pedestal", plural: "Pedestals" },
  PI: { label: "Pin", singular: "Pin", plural: "Pins" },
  PL: { label: "Planter", singular: "Planter", plural: "Planters" },
  PR: { label: "Prop", singular: "Prop", plural: "Props" },
  RB: { label: "Ribbon", singular: "Ribbon", plural: "Ribbons" },
  RI: { label: "Riser", singular: "Riser", plural: "Risers" },
  SI: { label: "Silk", singular: "Silk", plural: "Silks" },
  SV: { label: "Small Vase", singular: "Small Vase", plural: "Small Vases" },
  ST: { label: "Stand", singular: "Stand", plural: "Stands" },
  SR: { label: "Structure", singular: "Structure", plural: "Structures" },
  TA: { label: "Table", singular: "Table", plural: "Tables" },
  TV: { label: "Tall Vase", singular: "Tall Vase", plural: "Tall Vases" },
  TP: { label: "Tape", singular: "Tape", plural: "Tape" },
  TE: { label: "Terrarium", singular: "Terrarium", plural: "Terrariums" },
  TL: { label: "Tool", singular: "Tool", plural: "Tools" },
  TY: { label: "Tray", singular: "Tray", plural: "Trays" },
  TR: { label: "Trough", singular: "Trough", plural: "Troughs" },
  UR: { label: "Urn", singular: "Urn", plural: "Urns" },
  VA: { label: "Vase", singular: "Vase", plural: "Vases" },
  VO: { label: "Votive", singular: "Votive", plural: "Votives" },
  WI: { label: "Wire", singular: "Wire", plural: "Wires" },
  WR: { label: "Wrap", singular: "Wrap", plural: "Wraps" },
  WS: { label: "Wristlet", singular: "Wristlet", plural: "Wristlets" },
};

export const sortMaterialOptions = (material_options) => {
  return sort(material_options).by([
    { asc: (m) => materialTypeName(m.type) },
    { asc: (m) => m.name },
  ]);
};

export const _itemCounts = (
  item_type,
  filter_inventory,
  event_recipes,
  item_prices
) => {
  if (event_recipes) {
    let items = {};
    for (const event_recipe of event_recipes.filter((recipe) => {
      if (!recipe.event_item_group) {
        return !recipe.is_optional || recipe.is_selected;
      } else {
        let event_item_group = getEventItemByUuid(
          event_recipes,
          recipe.event_item_group
        );
        return !event_item_group.is_optional || event_item_group.is_selected;
      }
    })) {
      let group_quantity = getEventItemGroupQuantity(
        event_recipes,
        event_recipe
      );
      let recipe_quantity = quantityField(event_recipe);
      let recipe_items = item_type
        ? event_recipe.recipe_items.filter(
            (ri) => ri.item.item_type === item_type
          )
        : event_recipe.recipe_items;
      if (filter_inventory) {
        recipe_items = recipe_items.filter(
          (ri) => ri.item_source_type !== "IN"
        );
      }
      for (const recipe_item of recipe_items) {
        if (!(recipe_item.item.uuid in items)) {
          items[recipe_item.item.uuid] = {
            item: recipe_item.item,
            quantity: recipe_item.quantity * recipe_quantity * group_quantity,
            quantity_from_inventory:
              recipe_item.item_source_type === "IN"
                ? recipe_item.quantity * recipe_quantity * group_quantity
                : 0,
            quantity_to_sell:
              recipe_item.item_sale_type === "SE"
                ? recipe_item.quantity * recipe_quantity * group_quantity
                : 0,
            item_price: item_prices[recipe_item.item.uuid],
            recipes: [
              {
                recipe: event_recipe,
                recipe_item: recipe_item,
                recipe_quantity: recipe_quantity * group_quantity,
              },
            ],
          };
        } else {
          items[recipe_item.item.uuid].quantity +=
            recipe_item.quantity * recipe_quantity * group_quantity;
          items[recipe_item.item.uuid].quantity_from_inventory +=
            recipe_item.item_source_type === "IN"
              ? recipe_item.quantity * recipe_quantity * group_quantity
              : 0;
          items[recipe_item.item.uuid].quantity_to_sell +=
            recipe_item.item_sale_type === "SE"
              ? recipe_item.quantity * recipe_quantity * group_quantity
              : 0;
          items[recipe_item.item.uuid].recipes.push({
            recipe: event_recipe,
            recipe_item: recipe_item,
            recipe_quantity: recipe_quantity * group_quantity,
          });
        }
      }
    }
    return items;
  } else {
    return {};
  }
};

export const itemCounts = (
  item_type,
  filter_inventory,
  event_recipes,
  item_prices
) => {
  if (event_recipes) {
    let items = _itemCounts(
      item_type,
      filter_inventory,
      event_recipes,
      item_prices
    );
    items = Object.entries(items).map(([item_uuid, item_count]) => {
      return sumCounts(item_count);
    });
    return items;
  } else {
    return [];
  }
};

const getEventItemByUuid = (event_items, uuid) => {
  return event_items.find((ei) => ei.uuid === uuid);
};

const getEventItemGroupQuantity = (event_items, event_item) => {
  let group_quantity = 1;
  if (event_item.event_item_group) {
    let event_item_group = getEventItemByUuid(
      event_items,
      event_item.event_item_group
    );
    group_quantity = quantityField(event_item_group);
  }
  return group_quantity;
};

export const sumCounts = (item_count) => {
  let num_groups_needed = 0;
  let individuals_in_groups = 0;
  let waste_individual_count = 0;
  let groups_cost = new Decimal(0);
  let individuals_cost = new Decimal(0);
  var num_individuals_needed = 0;
  if (typeof item_count.item_price !== "undefined") {
    if (item_count.item_price.individual_per_group > 0) {
      num_individuals_needed =
        item_count.quantity - item_count.quantity_from_inventory;
      num_groups_needed = Math.ceil(
        num_individuals_needed / item_count.item_price.individual_per_group
      );
      individuals_in_groups =
        num_groups_needed * item_count.item_price.individual_per_group;
      waste_individual_count = individuals_in_groups - item_count.quantity;
      groups_cost = new Decimal(item_count.item_price.price_per_group).times(
        num_groups_needed
      );
      individuals_cost = new Decimal(num_individuals_needed).times(
        new Decimal(item_count.item_price.price_per_group).dividedBy(
          item_count.item_price.individual_per_group
        )
      );
    }
  }
  return {
    ...item_count,
    num_groups_needed: num_groups_needed,
    individuals_in_groups: individuals_in_groups,
    waste_individual_count: waste_individual_count,
    groups_cost: groups_cost,
    individuals_cost: individuals_cost,
  };
};

export const combineItemCounts = (item_counts_list) => {
  var item_uuids = [];
  item_counts_list.forEach((item_counts) => {
    item_uuids = item_uuids.concat(Object.keys(item_counts));
  });
  item_uuids = [...new Set(item_uuids)];
  var combined_item_counts = {};
  item_uuids.forEach((item_uuid) => {
    item_counts_list.forEach((item_counts) => {
      if (item_uuid in item_counts) {
        if (item_uuid in combined_item_counts) {
          combined_item_counts[item_uuid].quantity +=
            item_counts[item_uuid].quantity;
          combined_item_counts[item_uuid].quantity_from_inventory +=
            item_counts[item_uuid].quantity_from_inventory;
          combined_item_counts[item_uuid].quantity_to_sell +=
            item_counts[item_uuid].quantity_to_sell;
          combined_item_counts[item_uuid].recipes = [
            ...combined_item_counts[item_uuid].recipes,
            ...item_counts[item_uuid].recipes,
          ];
        } else {
          combined_item_counts[item_uuid] = {
            ...item_counts[item_uuid],
          };
        }
      }
    });
  });
  return combined_item_counts;
};

export const eventVersionUuidsInItemCount = (item_count) => {
  let event_version_uuids = item_count.recipes.map(
    (recipe) => recipe.recipe.event_version
  );
  event_version_uuids = [...new Set(event_version_uuids)];
  return event_version_uuids;
};

const itemPriceCompareFields = [
  "individual_per_group",
  "price_per_group",
  "vendor_location",
];

export const compareItemPrices = (item_price1, item_price2) => {
  for (const field of itemPriceCompareFields) {
    if (field === "vendor_location") {
      if (
        (!item_price1[field] && item_price2[field]) ||
        (!item_price2[field] && item_price1[field])
      ) {
        return false;
      } else if (!item_price1[field] && !item_price2[field]) {
        return true;
      } else if (item_price1[field].uuid !== item_price2[field].uuid) {
        return false;
      }
    } else {
      if (item_price1[field] !== item_price2[field]) {
        return false;
      }
    }
  }
  return true;
};

export const itemPriceInList = (item_price, item_prices) => {
  for (const ip of item_prices) {
    if (compareItemPrices(ip, item_price)) {
      return true;
    }
  }
  return false;
};

export const MaterialSaleTypes = {
  SE: "Sell",
  RE: "Rent",
};

export const MaterialSourceTypes = {
  IN: "Inventory",
  PU: "Purchase",
  RE: "Rent",
};

export const sortMaterialCountsByLocation = (
  material_counts,
  inventoryItemMap
) => {
  return sort(material_counts).by([
    {
      asc: (mc) =>
        inventoryItemMap[mc.item.uuid]?.inventory_location?.inventory_address
          ?.name,
    },
    {
      asc: (mc) => inventoryItemMap[mc.item.uuid]?.inventory_location?.name,
    },
    {
      asc: (mc) => itemDisplayName(mc.item),
    },
  ]);
};

export const sortMaterialCountsByName = (material_counts) => {
  return sort(material_counts).by([
    {
      asc: (mc) => itemDisplayName(mc.item),
    },
  ]);
};

export const sortPaletteItemsByDisplayName = (palette_items) => {
  return sort(palette_items).by([
    {
      asc: (pi) => itemDisplayName(pi.item),
    },
  ]);
};

export const sortItemsByName = (items) => {
  return sort(items).by([
    {
      asc: (i) => itemDisplayName(i),
    },
  ]);
};

export const sortDraftRecipeItemsByName = (draft_recipe_items) => {
  return sort(draft_recipe_items).by([
    {
      asc: (ri) => ri.n,
    },
  ]);
};
