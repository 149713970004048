import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// import * as Yup from 'yup';

import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import { ProfilePicture } from "./ItemIcon.js";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { FileDropZoneDialog } from "./FileUploader.js";
import CroppedImg from "./CroppedImg.js";

class PersonalSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      updating_profile_picture: false,
    };

    this.toggleEditing = this.toggleEditing.bind(this);
    this.updateProfilePicture = this.updateProfilePicture.bind(this);
    this.updateCompanyLogo = this.updateCompanyLogo.bind(this);
  }

  toggleEditing() {
    this.setState({
      editing: !this.state.editing,
    });
  }

  updateProfilePicture(images, skip_reload) {
    this.props.updatePersonalSettings({
      profile_picture: images[0],
    });
    if (!skip_reload) {
      window.location.reload(false);
    }
  }

  updateCompanyLogo(images) {
    this.props.updatePersonalSettings({
      company_logo: images[0],
    });
  }

  render() {
    return (
      <Box>
        <PersonalSettingsStatic
          {...this.props}
          updateProfilePicture={this.updateProfilePicture}
          toggleEditing={this.toggleEditing}
          handlePasswordReset={this.props.handlePasswordReset}
          updatePersonalSettings={this.props.updatePersonalSettings}
          updateCompanyLogo={this.updateCompanyLogo}
        />
      </Box>
    );
  }
}

const PersonalSettingsStatic = (props) => {
  const [uploadingFile, setUploadingFile] = React.useState(false);
  const [uploadingLogo, setUploadingLogo] = React.useState(false);
  const [changing_password, setChangingPassword] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [editing, setEditing] = React.useState(false);

  const [first_name, setFirstName] = React.useState(props.user.first_name);
  const [last_name, setLastName] = React.useState(props.user.last_name);
  const [phone_number, setPhoneNumber] = React.useState(
    props.user.phone_number
  );
  const [company, setCompany] = React.useState(props.user.company);

  const toggleEditing = () => {
    if (editing) {
      props.updatePersonalSettings({
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_number,
        company: company,
      });
    }
    setEditing(!editing);
  };

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      sx={{ width: "100%", m: "auto" }}
    >
      <Grid item xs={12}>
        <Typography variant="h6" align={fullScreen ? "center" : undefined}>
          Profile Picture
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ProfilePicture
              id="personal-settings-profile-picture"
              onClick={() => setUploadingFile(true)}
              user={props.user}
              width={100}
              height={100}
              sx={{
                m: fullScreen ? "auto" : undefined,
                cursor: "pointer",
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              onClick={() => setUploadingFile(true)}
              color="info"
              size="small"
            >
              {props.user?.profile_picture ? "Edit" : "Upload"}
            </Button>
          </Grid>
          {props.user?.profile_picture ? (
            <Grid item xs="auto">
              <Button
                variant="outlined"
                onClick={() => props.updateProfilePicture([null])}
                color="info"
                size="small"
              >
                Delete
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <FileDropZoneDialog
          open={uploadingFile}
          handleCancel={() => setUploadingFile(false)}
          handleSubmit={props.updateProfilePicture}
          aspect={1}
          maxSelections={1}
          forceRecrop
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" align={fullScreen ? "center" : undefined}>
          Personal Information
        </Typography>
      </Grid>
      <Divider sx={{ width: "100%", mb: ".5rem" }} />
      <PersonalSettingsInput
        label="Email"
        value={props.user.email}
        uneditable
      />
      <PersonalSettingsInput
        label="First Name"
        value={first_name}
        setValue={setFirstName}
        editing={editing}
      />
      <PersonalSettingsInput
        label="Last Name"
        value={last_name}
        setValue={setLastName}
        editing={editing}
      />
      <PersonalSettingsInput
        label="Phone"
        value={phone_number}
        setValue={setPhoneNumber}
        editing={editing}
      />
      <PersonalSettingsInput
        label="Company"
        value={company}
        setValue={setCompany}
        editing={editing}
      />
      <Grid item xs={12}>
        <Box display="flex">
          <Button
            onClick={toggleEditing}
            color="info"
            variant="outlined"
            id="personal-settings-edit-button"
          >
            {editing ? "Save" : "Edit"}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" align={fullScreen ? "center" : undefined}>
          Company Logo{" "}
          <Tooltip title="This logo will be displayed on your contracts and invoices.">
            <HelpOutlineOutlinedIcon fontSize="small" />
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {props.user.company_logo && (
              <CroppedImg
                img={props.user.company_logo}
                // width={"100%"}
                height={"75px"}
                zoom
              />
            )}
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              onClick={() => setUploadingLogo(true)}
              color="info"
              size="small"
            >
              {props.user?.company_logo ? "Edit" : "Upload"}
            </Button>
          </Grid>
          {props.user?.company_logo ? (
            <Grid item xs="auto">
              <Button
                variant="outlined"
                onClick={() => props.updateCompanyLogo([null])}
                color="info"
                size="small"
              >
                Delete
              </Button>
            </Grid>
          ) : null}
        </Grid>

        <FileDropZoneDialog
          open={uploadingLogo}
          handleCancel={() => setUploadingLogo(false)}
          handleSubmit={props.updateCompanyLogo}
          maxSelections={1}
          forceRecrop
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" align={fullScreen ? "center" : undefined}>
          Password
        </Typography>
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid item xs={12}>
        <Box display="flex">
          <Button
            onClick={() => {
              setChangingPassword(true);
              props.handlePasswordReset({
                email: props.user.email,
              });
            }}
            color="info"
            variant="outlined"
            sx={fullScreen ? { m: "auto" } : undefined}
            id="personal-settings-change-password-button"
          >
            Change Password
          </Button>
        </Box>
      </Grid>
      {changing_password ? (
        <Grid item xs={12}>
          <Typography>
            Check your email for password change instructions.
          </Typography>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Typography variant="h6" align={fullScreen ? "center" : undefined}>
          Currency
        </Typography>
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid item xs={12}>
        <Typography>
          {props.user.currency}
          <Tooltip title="This setting is chosen during account creation and cannot be changed.">
            <HelpOutlineOutlinedIcon fontSize="inherit" />
          </Tooltip>
        </Typography>
        {/* <CurrencySelect user={props.user} /> */}
      </Grid>
    </Grid>
  );
};

const PersonalSettingsInput = (props) => {
  const setValue = (e) => {
    props.setValue(e.target.value);
  };
  return (
    <Grid item xs={12}>
      <TextField
        label={props.label}
        value={props.value}
        onChange={setValue}
        fullWidth
        disabled={!props.editing || props.uneditable}
        id={props.label}
      />
    </Grid>
  );
};

export default withRouter(PersonalSettings);
