import debounce from "lodash.debounce";
import { Decimal } from "decimal.js";
import {
  addEventItemApi,
  addItemApi,
  addRecipeImgApi,
  addRecipeProposalSectionApi,
  addRecipeTemplatesToEventApi,
  addTaxGroupToEventApi,
  applyRecipeTemplateApi,
  debouncedUpdateRecipeItemApi,
  deleteRecipeImgApi,
  lockRecipePricesApi,
  removeEventItemApi,
  removeItemApi,
  removeRecipeProposalSectionApi,
  removeTaxGroupFromEventApi,
  updateEventItemApi,
  updateEventItemNoAuthApi,
  updateEventItemOrderApi,
  updateRecipeImgApi,
  updateRecipeItemApi,
  updateRecipeItemOrderApi,
  updateRecipeProposalSectionApi,
} from "../api/EventsApi";
import {
  AmendableEventItemFields,
  priceBelowSuggested,
  priceIsDerived,
  priceNotEqualSuggested,
} from "../utils/event_items_utils";
import {
  itemCostMultiplier,
  itemPricesDictFromList,
} from "../utils/item_utils";
import { AmendableOtherCostFields } from "../utils/other_cost_utils";
import { AmendableRecipeFields } from "../utils/recipe_utils";
import {
  byUUID,
  isOptionalField,
  isPercentField,
  isSelectedField,
  orderByField,
  percentField,
  priceField,
  quantityFieldDec,
  roundToNearest,
  salesTaxField,
  sortByName,
  twoDec,
  DecimalWrapper,
  decimalConfig,
  DecimalPercent,
  sort_by_order,
  snapshot,
  snapshotEventItems,
  quantityField,
  markupPercentageField,
} from "../utils/utils";

Decimal.set(decimalConfig);

const updateEventItemInDb = (data, cb) => {
  updateEventItemApi(data).then((resp) => {
    if (cb) {
      cb(resp.data);
    }
  });
};
const debouncedUpdateEventItemInDb = debounce(updateEventItemInDb, 500);

const updateEventItemInDbUnsecure = (data, cb) => {
  updateEventItemNoAuthApi(data).then((resp) => {
    if (cb) {
      cb(resp.data);
    }
  });
};
const debouncedUpdateEventItemInDbUnsecure = debounce(
  updateEventItemInDbUnsecure,
  500
);

export const eventItemsSlice = (set, get) => ({
  event_items: [],
  recipe_proposal_sections: [],
  draggable: undefined,
  setDraggable: (draggableId) => {
    if (draggableId) {
      let item = get().getRecipeListItemByUuid(draggableId);
      set({
        draggable: item,
      });
    } else {
      set({
        draggable: undefined,
      });
    }
  },
  addEventItem: (data, cb) => {
    if (!data.is_template) {
      get().normalizeEventItemsOrder(data.index, data.event_item_group);
    }
    const contract_exists = get().contractExists() && !data.is_template;
    const event_version = data.is_template ? undefined : get().event_version;
    let event_item_group = data.event_item_group;
    const data_ = {
      event_version_uuid: event_version?.uuid,
      name: data.name,
      event_item_type: data.event_item_type,
      quantity: contract_exists && !event_item_group?.hide_sub_items ? 0 : 1,
      amend_quantity:
        contract_exists && !event_item_group?.hide_sub_items ? 1 : undefined,
      price:
        contract_exists && !event_item_group?.hide_sub_items ? 0 : undefined,
      percent: 0,
      duplicate_uuid: data.duplicate_uuid,
      order: data.index,
      event_item_group_id: data.event_item_group?.uuid,
      is_template: data.is_template,
      is_percent: data.is_percent,
      item_uuids: data.item_uuids,
      item_quantities: data.item_quantities,
      proposal_notes: data.proposal_notes,
    };
    addEventItemApi(data_).then((resp) => {
      if (resp.data.event_item.event_item_group) {
        let event_item_group = get().getEventItemByUuid(
          resp.data.event_item.event_item_group
        );
        if (data.duplicate_uuid) {
          get().normalizeEventItemsOrder(
            resp.data.event_item.order,
            event_item_group
          );
          event_item_group = get().getEventItemByUuid(
            resp.data.event_item.event_item_group
          );
        }
        let event_items_in_group = event_item_group.event_items_in_group;
        let new_items_in_group = [
          ...event_items_in_group,
          resp.data.event_item,
        ].sort(sort_by_order);
        let new_event_item_group = {
          ...event_item_group,
          event_items_in_group: new_items_in_group,
        };
        let event_items = get().event_items.filter(
          (item) => item.uuid !== event_item_group.uuid
        );
        let new_event_items = [...event_items, new_event_item_group].sort(
          sort_by_order
        );
        set({
          event_items: new_event_items,
        });

        if (
          resp.data.event_item.price !== 0 &&
          resp.data.event_item.price !== null
        ) {
          get().updateEventItem({
            uuid: event_item_group.uuid,
            [get().contractExists() ? "amend_price" : "price"]:
              get().contractExists()
                ? get().suggestedEventItemPrice(event_item_group)
                : null,
          });
        }
      } else {
        let new_event_items = get().event_items.map((event_item) => {
          if (
            event_item.order >= resp.data.event_item.order &&
            resp.data.event_item.event_item_type !== "TA"
          ) {
            return {
              ...event_item,
              order: event_item.order + 1,
            };
          } else {
            return event_item;
          }
        });
        new_event_items = [...new_event_items, resp.data.event_item].sort(
          sort_by_order
        );
        set({
          event_items: new_event_items,
        });
      }
      if (resp.data.item_prices) {
        set({
          item_prices: {
            ...get().item_prices,
            ...itemPricesDictFromList(resp.data.item_prices),
          },
        });
      }
      if (resp.data.inventory_items) {
        let current_inventory_item_uuids = get().inventoryItems.map(
          (i) => i.uuid
        );
        let inventory_items = resp.data.inventory_items.filter(
          (i) => !current_inventory_item_uuids.includes(i.uuid)
        );
        if (inventory_items.length > 0) {
          set({
            inventoryItems: [...get().inventoryItems, ...inventory_items],
          });
        }
      }
      if (resp.data.palette_items) {
        let palette_item_uuids = get().event_version.palette_items.map(
          (pi) => pi.uuid
        );
        let new_palette_items = resp.data.palette_items.filter(
          (pi) => !palette_item_uuids.includes(pi.uuid)
        );
        set({
          event_version: {
            ...get().event_version,
            palette_items: [
              ...get().event_version.palette_items,
              ...new_palette_items,
            ],
          },
        });
      }
      if (cb) {
        cb(resp.data.event_item);
      }
      get().updatePercentBasedEventItems();
    });
  },
  addEventItemToGroup: (event_item, event_item_group_uuid) => {
    let event_items = get().event_items;
    let event_item_group = event_items.find(
      (ei) => ei.uuid === event_item_group_uuid
    );
    get().normalizeEventItemsOrder(undefined, event_item_group);
    get().removeEventItemFromGroup(event_item);
    if (event_item_group_uuid) {
      event_items = get().event_items;
      let event_item_group_index = event_items.findIndex(
        (ei) => ei.uuid === event_item_group_uuid
      );
      let new_event_item_group = {
        ...event_item_group,
        event_items_in_group: [
          ...event_item_group.event_items_in_group,
          {
            ...event_item,
            event_item_group: event_item_group_uuid,
            is_optional: false,
            is_selected: true,
            order: event_item_group.event_items_in_group.length,
          },
        ],
      };
      let new_event_items = [
        ...event_items.slice(0, event_item_group_index),
        new_event_item_group,
        ...event_items.slice(event_item_group_index + 1),
      ].sort(sort_by_order);
      set({
        event_items: new_event_items,
      });
    } else {
      event_items = get().event_items;
      let new_event_items = [
        ...event_items,
        {
          ...event_item,
          event_item_group: null,
          is_optional: false,
          is_selected: true,
          order: get().recipeListItems().length,
        },
      ].sort(sort_by_order);
      set({
        event_items: new_event_items,
      });
    }
  },
  addEventItemToState: (event_item) => {
    let event_item_group_uuid = event_item.event_item_group;

    if (event_item_group_uuid) {
      let event_item_group = get().getEventItemByUuid(event_item_group_uuid);
      get().normalizeEventItemsOrder(event_item.order, event_item_group);
      let new_event_items_in_group = [
        ...event_item_group.event_items_in_group,
        event_item,
      ];
      get().updateEventItemInState({
        uuid: event_item_group_uuid,
        event_items_in_group: new_event_items_in_group,
      });
    } else {
      get().normalizeEventItemsOrder(event_item.order);
      set({
        event_items: [...get().event_items, event_item],
      });
    }
  },
  addFees: (item_cost, event_item) => {
    let back_office = get().getBackOfficeForEventItem(event_item);
    let event = get().event;
    if (back_office.price_in_flowerbuddy_fee && !event?.created_during_trial) {
      let flowerbuddy_fee = get().flowerbuddyFee();
      if (flowerbuddy_fee) {
        var flowerbuddy_fee_cost = flowerbuddy_fee.times(item_cost);
        item_cost = item_cost.plus(flowerbuddy_fee_cost);
      }
    }
    if (
      !event_item.is_template &&
      back_office.price_in_stripe_fee &&
      get().webPaymentsEnabled()
    ) {
      let stripe_fee = get().stripeFee();
      var stripe_fee_cost = stripe_fee.times(item_cost);
      item_cost = item_cost.plus(stripe_fee_cost);
    }
    return item_cost;
  },
  addRecipeTemplatesToEvent: (
    recipe_template_uuids,
    index,
    event_item_group_uuid,
    cb
  ) => {
    let data = {
      recipe_template_uuids: recipe_template_uuids,
      event_version_uuid: get().event_version.uuid,
      index: index,
      event_item_group_uuid: event_item_group_uuid,
    };
    addRecipeTemplatesToEventApi(data).then((resp) => {
      let event_item_group_uuid = resp.data.event_items[0].event_item_group;
      if (resp.data.item_prices) {
        set({
          item_prices: {
            ...get().item_prices,
            ...itemPricesDictFromList(resp.data.item_prices),
          },
        });
      }
      if (resp.data.inventory_items) {
        let current_inventory_item_uuids = get().inventoryItems.map(
          (i) => i.uuid
        );
        let inventory_items = resp.data.inventory_items.filter(
          (i) => !current_inventory_item_uuids.includes(i.uuid)
        );
        if (inventory_items.length > 0) {
          set({
            inventoryItems: [...get().inventoryItems, ...inventory_items],
          });
        }
      }
      get().updateOrAddEventItemsInState(resp.data.event_items);
      if (event_item_group_uuid) {
        let event_item_group = get().getEventItemByUuid(event_item_group_uuid);
        get().updateEventItem({
          uuid: event_item_group.uuid,
          [get().contractExists() ? "amend_price" : "price"]:
            get().contractExists()
              ? get().suggestedEventItemPrice(event_item_group)
              : null,
        });
      }
      if (cb) {
        cb(resp.data);
      }
      get().updatePercentBasedEventItems();
    });
  },
  removeEventItemFromGroup: (event_item) => {
    let event_items = get().event_items;
    if (event_item.event_item_group) {
      let event_item_group = event_items.find(
        (ei) => ei.uuid === event_item.event_item_group
      );
      let event_item_group_index = event_items.findIndex(
        (ei) => ei.uuid === event_item.event_item_group
      );
      let event_items_in_group_index =
        event_item_group.event_items_in_group.findIndex(
          (ei) => ei.uuid === event_item.uuid
        );
      let new_event_items_in_group = [
        ...event_item_group.event_items_in_group.slice(
          0,
          event_items_in_group_index
        ),
        ...event_item_group.event_items_in_group.slice(
          event_items_in_group_index + 1
        ),
      ];
      let new_event_item_group = {
        ...event_item_group,
        event_items_in_group: new_event_items_in_group,
      };
      let new_event_items = [
        ...event_items.slice(0, event_item_group_index),
        new_event_item_group,
        ...event_items.slice(event_item_group_index + 1),
      ].sort(sort_by_order);
      set({
        event_items: new_event_items,
      });
    } else {
      let event_item_index = event_items.findIndex(
        (ei) => ei.uuid === event_item.uuid
      );
      let new_event_items = [
        ...event_items.slice(0, event_item_index),
        ...event_items.slice(event_item_index + 1),
      ].sort(sort_by_order);
      set({
        event_items: new_event_items,
      });
    }
  },
  addOtherCost: (name, event_item_group, index, cb, is_percent) => {
    get().addEventItem(
      {
        name: name,
        event_item_type: "OT",
        event_item_group: event_item_group,
        index: index,
        is_percent: is_percent ? true : false,
      },
      cb
    );
  },
  addTax: (data) => {
    const contract_exists = get().contractExists();
    addEventItemApi({
      tax_profile_uuid: data.tax_profile?.uuid,
      quantity: contract_exists ? 0 : 1,
      amend_quantity: contract_exists ? 1 : undefined,
      price: contract_exists ? 0 : undefined,
      event_version_uuid: get().event_version.uuid,
    }).then((resp) => {
      set({
        event_items: [...get().event_items, resp.data.event_item],
      });
    });
  },
  addRecipe: (name, event_item_group, index, cb) => {
    get().addEventItem(
      {
        name: name,
        event_item_type: "RE",
        event_item_group: event_item_group,
        index: index,
      },
      cb
    );
  },
  addRecipeGroup: (name, duplicate_uuid, index) => {
    get().addEventItem({
      name: name,
      event_item_type: "GR",
      duplicate_uuid: duplicate_uuid,
      index: index,
    });
  },
  addRecipeImg: (event_item, data) => {
    if (event_item.recipe_imgs.length < 25) {
      addRecipeImgApi({
        event_recipe_uuid: event_item.uuid,
        ...data,
      }).then((resp) => {
        let new_recipe_imgs = [...event_item.recipe_imgs, ...resp.data];
        get().updateEventItemInState({
          uuid: event_item.uuid,
          recipe_imgs: new_recipe_imgs,
        });
      });
    }
  },
  addRecipeItem: (event_item, item_uuid) => {
    addItemApi(event_item.uuid, item_uuid).then((resp) => {
      let new_recipe_items = [
        ...event_item.recipe_items,
        resp.data.recipe_item,
      ];
      if (!event_item.is_template) {
        get().updateEventItemInState({
          uuid: event_item.uuid,
          recipe_items: new_recipe_items,
        });
        let item_prices = get().item_prices;
        set({
          item_prices: {
            ...item_prices,
            [resp.data.recipe_item.item.uuid]: resp.data.item_price,
          },
        });
        if (resp.data.inventory_item) {
          set({
            inventoryItems: [...get().inventoryItems, resp.data.inventory_item],
          });
        }
        let selectable_items =
          resp.data.recipe_item.item.item_type === "FL"
            ? get().selectableFlowers
            : get().selectableMaterials;
        if (
          !selectable_items
            .map((f) => f.uuid)
            .includes(resp.data.recipe_item.item.uuid)
        ) {
          if (resp.data.recipe_item.item.core_item) {
            selectable_items = selectable_items.filter(
              (f) => f.uuid !== resp.data.recipe_item.item.core_item
            );
          }
          selectable_items = [...selectable_items, resp.data.recipe_item.item];
          let key =
            resp.data.recipe_item.item.item_type === "FL"
              ? "selectableFlowers"
              : "selectableMaterials";
          set({
            [key]: selectable_items,
          });
        }
        if (resp.data.palette_item) {
          set({
            event_version: {
              ...get().event_version,
              palette_items: [
                ...get().event_version.palette_items,
                resp.data.palette_item,
              ],
            },
          });
        }
      } else {
        let item_prices = [
          ...event_item.template_item_prices,
          resp.data.item_price,
        ];
        get().updateEventItemInState({
          uuid: event_item.uuid,
          recipe_items: new_recipe_items,
          template_item_prices: item_prices,
        });
      }
    });
  },
  addRecipeItems: (event_item, item_uuids, cb) => {
    addItemApi(event_item.uuid, item_uuids).then((resp) => {
      let new_recipe_items = [
        ...event_item.recipe_items,
        ...resp.data.map((d) => d.recipe_item),
      ];
      if (!event_item.is_template) {
        get().updateEventItemInState({
          uuid: event_item.uuid,
          recipe_items: new_recipe_items,
        });
        let item_prices = get().item_prices;
        let new_item_prices = itemPricesDictFromList(
          resp.data.map((d) => d.item_price)
        );
        set({
          item_prices: {
            ...item_prices,
            ...new_item_prices,
          },
        });
        let selectable_items =
          resp.data[0].recipe_item.item.item_type === "FL"
            ? get().selectableFlowers
            : get().selectableMaterials;
        let key =
          resp.data[0].recipe_item.item.item_type === "FL"
            ? "selectableFlowers"
            : "selectableMaterials";
        let uuids_to_remove = resp.data
          .filter((d) => d.recipe_item.item.core_item)
          .map((d) => d.recipe_item.item.core_item);
        selectable_items = selectable_items.filter(
          (f) => !uuids_to_remove.includes(f.uuid)
        );
        let selectable_items_uuids = selectable_items.map((f) => f.uuid);
        let items_to_add = resp.data.filter(
          (d) => !selectable_items_uuids.includes(d.recipe_item.item.uuid)
        );
        selectable_items = [
          ...selectable_items,
          ...items_to_add.map((d) => d.recipe_item.item),
        ];
        set({
          [key]: selectable_items,
        });
        let current_inventory_item_uuids = get().inventoryItems.map(
          (i) => i.uuid
        );
        let inventory_items = resp.data
          .filter((d) => d.inventory_item)
          .map((d) => d.inventory_item)
          .filter((i) => !current_inventory_item_uuids.includes(i.uuid));
        if (inventory_items.length > 0) {
          set({
            inventoryItems: [...get().inventoryItems, ...inventory_items],
          });
        }
        let palette_items = resp.data
          .map((d) => d.palette_item)
          .filter((p) => p);
        if (palette_items.length > 0) {
          set({
            event_version: {
              ...get().event_version,
              palette_items: [
                ...get().event_version.palette_items,
                ...palette_items,
              ],
            },
          });
        }
      } else {
        let item_prices = [
          ...event_item.template_item_prices,
          ...resp.data.map((d) => d.item_price),
        ];
        get().updateEventItemInState({
          uuid: event_item.uuid,
          recipe_items: new_recipe_items,
          template_item_prices: item_prices,
        });
      }
      if (cb) {
        cb();
      }
    });
  },
  addRecipeItemState: (event_recipe_uuid, data) => {
    let event_items = get().event_items;
    let recipe_ind = event_items.findIndex(
      (recipe) => recipe.uuid === event_recipe_uuid
    );
    let event_recipe = event_items[recipe_ind];
    event_recipe.recipe_items = [...event_recipe.recipe_items, data];
    set({
      event_items: [
        ...event_items.slice(0, recipe_ind),
        event_recipe,
        ...event_items.slice(recipe_ind + 1),
      ],
    });
  },
  addRecipeProposalSection: (name, order) => {
    get().normalizeEventItemsOrder(order);
    if (order === undefined) {
      order = get().recipeListItems().length + 1;
    }
    addRecipeProposalSectionApi({
      name: name,
      order: order,
      event_version_uuid: get().event_version.uuid,
    }).then((resp) => {
      set({
        recipe_proposal_sections: [
          ...get().recipe_proposal_sections,
          resp.data,
        ],
      });
    });
  },
  addTaxGroupToEvent: (data) => {
    let event_version = get().event_version;
    let tax_groups_in_event = [...event_version.tax_groups, data.tax_group];
    set({
      event_version: { ...event_version, tax_groups: tax_groups_in_event },
    });
    data.tax_group.taxes.forEach((tax) => {
      let tax_profile_uuids_in_event = get()
        .taxes()
        .map((tax) => tax.tax_profile);
      if (!tax_profile_uuids_in_event.includes(tax.uuid)) {
        get().addTax({ tax_profile: tax });
      }
    });
    addTaxGroupToEventApi({
      ...data,
      event_version_uuid: event_version.uuid,
    }).then((resp) => {
      if (!resp.data.success) {
        set({
          event_version: event_version,
        });
      }
    });
  },
  allEventItems: (include_groups, include_templates) => {
    let event_items = get()
      .event_items.sort(sort_by_order)
      .map((ei) => {
        if (ei.event_item_type === "GR") {
          return include_groups
            ? [ei, ...ei.event_items_in_group.sort(sort_by_order)]
            : ei.event_items_in_group;
        } else {
          return [ei];
        }
      })
      .flat();
    if (!include_templates) {
      event_items = event_items.filter((ei) => !ei.is_template);
    }
    return event_items;
  },
  allRecipesAndCosts: (include_groups) => {
    return get()
      .allEventItems(include_groups)
      .filter((ei) => ei.event_item_type !== "TA");
  },
  allRecipesAndCostsWithAmendments: () => {
    return get()
      .allRecipesAndCosts(true)
      .filter((ei) => get().eventItemHasAmendments(ei));
  },
  allRecipesAndCostsHaveAmendments: () => {
    return get().allRecipesAndCostsWithAmendments().length > 0;
  },
  allRecipesAndCostsBelowSuggested: () => {
    return get()
      .allRecipesAndCosts(true)
      .filter((ei) => {
        let suggested_price = get().suggestedEventItemPrice(ei);
        return (
          priceNotEqualSuggested(ei, suggested_price) &&
          get().eventItemPrice(ei).cmp(suggested_price) < 0
        );
      }).length;
  },
  applyRecipeTemplate: (data, cb) => {
    applyRecipeTemplateApi(data).then((resp) => {
      get().updateOrAddEventItemsInState(resp.data.event_items);
      set({
        item_prices: {
          ...get().item_prices,
          ...itemPricesDictFromList(resp.data.item_prices),
        },
      });
      get().updatePercentBasedEventItems();
      if (cb) {
        cb(resp.data);
      }
    });
  },
  completedByOptions: () => {
    let recipes = get().recipes();
    let completed_by_options = recipes.map((recipe) => {
      return recipe.build_progress
        ? recipe.build_progress.map((bp) => {
            return bp.completed_by ? bp.completed_by : undefined;
          })
        : undefined;
    });
    let filtered_completed_by_options = completed_by_options
      .flat(2)
      .filter((x) => x !== undefined);
    return [...new Set(filtered_completed_by_options)];
  },
  contractedEventItemPrice: (event_item) => {
    if (priceIsDerived(event_item)) {
      return get().suggestedEventItemPrice(event_item, true);
    } else {
      return priceField(event_item, true);
    }
  },
  deleteEventItem: (uuid, cb) => {
    let event_item = get().getEventItemByUuid(uuid);

    get().deleteEventItemInState(uuid);
    if (event_item?.event_item_group) {
      let event_item_group = get().getEventItemByUuid(
        event_item.event_item_group
      );
      get().updateEventItem({
        uuid: event_item_group.uuid,
        [get().contractExists() ? "amend_price" : "price"]:
          get().contractExists()
            ? get().suggestedEventItemPrice(event_item_group)
            : null,
      });
    }
    if (event_item) {
      get().updatePercentBasedEventItems();
    }
    removeEventItemApi(uuid).then((resp) => {
      if (cb) {
        cb();
      }
    });
  },
  deleteEventItemInState: (uuid) => {
    let event_item = get().getEventItemByUuid(uuid);
    if (
      event_item?.event_item_group &&
      get().getEventItemByUuid(event_item.event_item_group)
    ) {
      let event_item_group = get().getEventItemByUuid(
        event_item.event_item_group
      );
      let event_items_in_group = event_item_group.event_items_in_group;
      let new_items_in_group = event_items_in_group.filter(
        (ei) => ei.uuid !== uuid
      );
      let new_event_item_group = {
        ...event_item_group,
        event_items_in_group: new_items_in_group,
      };
      let event_items = get().event_items.filter(
        (item) => item.uuid !== event_item_group.uuid
      );
      let new_event_items = [...event_items, new_event_item_group].sort(
        sort_by_order
      );
      set({
        event_items: new_event_items,
      });
    } else {
      let event_items = get().event_items;
      let new_event_items = event_items.filter(
        (event_item) => event_item.uuid !== uuid
      );
      set({
        event_items: new_event_items,
      });
    }
  },
  deleteRecipeImg: (event_item, uuid) => {
    let new_recipe_imgs = event_item.recipe_imgs.filter(
      (img) => img.uuid !== uuid
    );
    get().updateEventItemInState({
      uuid: event_item.uuid,
      recipe_imgs: new_recipe_imgs,
    });
    deleteRecipeImgApi(uuid).then((resp) => {
      console.log(resp);
    });
  },
  deleteRecipeItem: (event_item, uuid) => {
    let new_recipe_items = event_item.recipe_items.filter(
      (item) => item.uuid !== uuid
    );
    get().updateEventItemInState({
      uuid: event_item.uuid,
      recipe_items: new_recipe_items,
    });
    removeItemApi(uuid).then((resp) => {
      if (!resp.data.success) {
        console.log(resp.data);
      }
    });
  },
  deleteRecipeProposalSection: (uuid) => {
    let recipe_proposal_sections = get().recipe_proposal_sections;
    set({
      recipe_proposal_sections: recipe_proposal_sections.filter(
        (section) => section.uuid !== uuid
      ),
    });
    removeRecipeProposalSectionApi(uuid).then((resp) => {
      snapshot(resp);
    });
  },
  deleteTaxGroupFromEvent: (uuid) => {
    let event_version = get().event_version;
    let tax_groups_in_event = event_version.tax_groups.filter(
      (tax_group) => tax_group.uuid !== uuid
    );
    set({
      event_version: { ...event_version, tax_groups: tax_groups_in_event },
    });
    removeTaxGroupFromEventApi({
      tax_group_uuid: uuid,
      event_version_uuid: event_version.uuid,
    }).then((resp) => {
      if (!resp.data.success) {
        set({
          event_version: event_version,
        });
      }
    });
  },
  duplicateEventItem: (event_item, cb) => {
    get().addEventItem({ duplicate_uuid: event_item.uuid }, cb);
  },
  effectiveTaxPercentRate: (ignore_amendments) => {
    let event_price_pre_tax = get().eventPricePreTax(ignore_amendments);
    let total_precent_taxes_price =
      get().totalPercentTaxesPrice(ignore_amendments);
    let event_effective_precent_tax_rate =
      total_precent_taxes_price.dividedBy(event_price_pre_tax);
    return event_effective_precent_tax_rate;
  },
  eventItemGroupPrice: (event_item, ignore_amendments, taxable) => {
    var group_price = priceField(event_item, ignore_amendments);
    if (group_price !== null) {
      return DecimalWrapper(parseFloat(group_price));
    } else {
      return new Decimal(
        get().suggestedEventItemGroupPrice(
          event_item,
          ignore_amendments,
          taxable
        )
      );
    }
  },
  eventItemGroupPriceWithTax: (event_item, ignore_amendments) => {
    return get().proposal.spread_tax
      ? get()
          .eventItemGroupPrice(event_item, ignore_amendments)
          .plus(get().eventItemTax(event_item, ignore_amendments))
      : get().eventItemGroupPrice(event_item, ignore_amendments);
  },
  eventItemGroupPriceWithTaxAmended: (event_item) => {
    return !get()
      .eventItemGroupPriceWithTax(event_item, true)
      .eq(get().eventItemGroupPriceWithTax(event_item, false));
  },
  eventItemGroupProfit: (event_item, ignore_amendments) => {
    return get()
      .eventItemGroupPrice(event_item, ignore_amendments)
      .minus(get().unitEventItemGroupCost(event_item, ignore_amendments));
  },
  eventItemGroupTotalPrice: (event_item, ignore_amendments) => {
    let unit_price = get().eventItemGroupPrice(event_item, ignore_amendments);
    return unit_price.times(quantityFieldDec(event_item, ignore_amendments));
  },
  eventItemGroupTotalPriceWithTax: (event_item, ignore_amendments) => {
    return get().eventItemGroupTotalPrice(event_item, ignore_amendments);
  },
  eventItemGroupTotalProfit: (event_item, ignore_amendments) => {
    let unit_profit = get().eventItemGroupProfit(event_item, ignore_amendments);
    return unit_profit.times(quantityFieldDec(event_item, ignore_amendments));
  },
  eventItemHasAmendments: (event_item) => {
    var res = false;
    AmendableEventItemFields.forEach((field) => {
      if (field == "price") {
        if (get().eventItemPriceIsAmended(event_item)) {
          res = true;
        }
      } else {
        if (
          event_item["amend_" + field] !== null &&
          event_item[field] !== event_item["amend_" + field]
        ) {
          res = true;
        }
      }
    });
    return res;
  },
  eventItemsHaveAmendments: () => {
    return get().eventItemsWithAmendments() > 0;
  },
  eventItemPrice: (event_item, ignore_amendments, taxable) => {
    switch (event_item.event_item_type) {
      case "TA":
        return get().taxPrice(event_item, ignore_amendments);
      case "RE":
        return get().recipePrice(event_item, ignore_amendments);
      case "OT":
        return get().unitOtherCostPrice(event_item, ignore_amendments);
      case "GR":
        return get().eventItemGroupPrice(
          event_item,
          ignore_amendments,
          taxable
        );
    }
  },
  eventItemPriceIsAmended: (event_item) => {
    return (
      get()
        .eventItemPrice(event_item, true)
        .cmp(get().eventItemPrice(event_item, false)) !== 0
    );
  },
  eventItemPriceIsLow: (event_item) => {
    let back_office = get().back_office;
    let suggested_price = get().suggestedEventItemPrice(event_item);
    return priceBelowSuggested(
      event_item,
      suggested_price,
      back_office.price_error_tolerance
    );
  },
  eventItemPriceWithTax: (event_item, ignore_amendments) => {
    switch (event_item.event_item_type) {
      case "TA":
        return get().taxPrice(event_item, ignore_amendments);
      case "RE":
        return get().recipePriceWithTax(event_item, ignore_amendments);
      case "OT":
        return get().otherCostPriceWithTax(event_item, ignore_amendments);
      case "GR":
        return get().eventItemGroupPriceWithTax(event_item, ignore_amendments);
    }
  },
  eventItemPriceWithTaxAmended: (event_item) => {
    switch (event_item.event_item_type) {
      case "RE":
        return get().recipePriceWithTaxAmended(event_item, true);
      case "OT":
        return get().otherCostPriceWithTaxAmended(event_item, true);
      case "GR":
        return get().eventItemGroupPriceWithTax(event_item, true);
    }
  },
  eventItemProfit: (event_item, ignore_amendments) => {
    let price = get().eventItemPrice(event_item, ignore_amendments);
    let cost = get().unitEventItemCost(event_item, ignore_amendments);
    let profit = price.minus(cost);
    return profit;
  },
  eventItemsBelowSuggested: (exclude_taxes) => {
    let back_office = get().back_office;
    let event_items = get().allEventItems(true, false);
    event_items = event_items.filter((ei) => {
      let suggested_price = get().suggestedEventItemPrice(ei);
      return priceBelowSuggested(
        ei,
        suggested_price,
        back_office.price_error_tolerance
      );
    });
    if (exclude_taxes) {
      event_items = event_items.filter((ei) => ei.event_item_type !== "TA");
    }
    return event_items;
  },
  eventItemsWithAmendments: () => {
    return get()
      .allEventItems(true)
      .filter((event_item) => get().eventItemHasAmendments(event_item)).length;
  },
  eventItemTax: (event_item, ignore_amendments) => {
    switch (event_item.event_item_type) {
      case "TA":
        return get().taxPrice(event_item, ignore_amendments);
      case "RE":
        if (salesTaxField(event_item, ignore_amendments)) {
          let percent_taxes = get()
            .taxes()
            .filter((tax) => isPercentField(tax, ignore_amendments));
          let event_total_price_pre_tax =
            get().eventPricePreTax(ignore_amendments);
          let effective_rates = percent_taxes.map((tax) => {
            if (priceField(tax, ignore_amendments) !== null) {
              return get()
                .taxPrice(tax, ignore_amendments)
                .dividedBy(event_total_price_pre_tax);
            } else {
              return new Decimal(percentField(tax, ignore_amendments) / 100);
            }
          });
          let recipe_taxes = effective_rates.map((rate) =>
            rate.times(get().recipePrice(event_item, ignore_amendments))
          );
          return recipe_taxes.reduce((a, b) => a.plus(b), new Decimal(0));
        } else {
          return new Decimal(0);
        }
      case "OT":
        if (salesTaxField(event_item)) {
          let percent_taxes = get()
            .taxes()
            .filter((tax) => isPercentField(tax, ignore_amendments));
          let event_total_price_pre_tax =
            get().eventPricePreTax(ignore_amendments);
          let effective_rates = percent_taxes.map((tax) => {
            if (priceField(tax, ignore_amendments) !== null) {
              return get()
                .taxPrice(tax, ignore_amendments)
                .dividedBy(event_total_price_pre_tax);
            } else {
              return new Decimal(percentField(tax, ignore_amendments) / 100);
            }
          });
          let other_cost_taxes = effective_rates.map((rate) =>
            rate.times(get().unitOtherCostPrice(event_item, ignore_amendments))
          );
          return other_cost_taxes.reduce((a, b) => a.plus(b), new Decimal(0));
        } else {
          return new Decimal(0);
        }
      case "GR":
        return event_item.event_items_in_group
          .map((ei) => get().eventItemTax(ei, ignore_amendments))
          .reduce((a, b) => a.plus(b), new Decimal(0));
    }
  },
  eventItemTaxTotal: (event_item, ignore_amendments) => {
    let event_item_tax = get().eventItemTax(event_item, ignore_amendments);
    return quantityFieldDec(event_item, ignore_amendments).times(
      event_item_tax
    );
  },
  eventItemTotalPrice: (event_item, ignore_amendments, taxable) => {
    let quantity = quantityFieldDec(event_item, ignore_amendments);
    let event_item_price = get().eventItemPrice(
      event_item,
      ignore_amendments,
      taxable
    );
    let event_item_total_price = quantity.times(event_item_price);
    return event_item_total_price;
  },
  eventItemTotalPriceWithTax: (event_item, ignore_amendments) => {
    return get().proposal.spread_tax && !event_item.is_template
      ? get()
          .eventItemTotalPrice(event_item, ignore_amendments)
          .plus(get().eventItemTaxTotal(event_item, ignore_amendments))
      : get().eventItemTotalPrice(event_item, ignore_amendments);
  },
  eventItemTotalPriceWithTaxAmended: (event_item) => {
    return !get()
      .eventItemTotalPriceWithTax(event_item, true)
      .eq(get().eventItemTotalPriceWithTax(event_item, false));
  },
  eventItemTotalProfit: (event_item, ignore_amendments) => {
    return get()
      .eventItemTotalPrice(event_item, ignore_amendments)
      .minus(get().totalEventItemCost(event_item, ignore_amendments));
  },
  eventRecipeItemsCost: (recipe, item_type) => {
    if (recipe.event_item_type === "GR") {
      return recipe.event_items_in_group
        .map((ei) =>
          get().eventRecipeItemsCost(ei, item_type).times(quantityField(ei))
        )
        .reduce((a, b) => a.plus(b), new Decimal(0));
    } else {
      let recipe_items = recipe.recipe_items;
      if (item_type) {
        recipe_items = recipe_items.filter(
          (ri) => ri.item.item_type === item_type
        );
      }
      if (recipe_items.length > 0) {
        return recipe_items
          .map((recipe_item) => get().itemLineCost(recipe_item))
          .reduce((p, c) => p.plus(c), new Decimal(0));
      } else {
        return new Decimal(0);
      }
    }
  },
  eventRecipeItemsPrice: (recipe, item_type) => {
    let back_office = get().getBackOfficeForEventItem(recipe);
    let recipe_items = recipe.recipe_items;
    if (item_type) {
      recipe_items = recipe_items.filter(
        (ri) => ri.item.item_type === item_type
      );
    }
    if (recipe_items.length > 0) {
      return recipe_items
        .map((recipe_item) => {
          let multiplier = itemCostMultiplier(recipe_item, back_office);
          let cost = get().itemLineCost(recipe_item, true);
          let price = cost.times(multiplier);
          return price;
        })
        .reduce((p, c) => p.plus(c), new Decimal(0));
    } else {
      return new Decimal(0);
    }
  },
  getBackOfficeForEventItem: (event_item) => {
    if (event_item.is_template) {
      return event_item.template_back_office;
    } else {
      return get().back_office;
    }
  },
  getEventItemAmendments: (event_item) => {
    let amendments = [];
    if (quantityField(event_item, false) !== quantityField(event_item, true)) {
      amendments.push({
        field: "Quantity",
        contracted_value: quantityField(event_item, true).toFixed(0),
        amended_value: quantityField(event_item, false).toFixed(0),
      });
    }
    if (
      !get()
        .eventItemPrice(event_item, false)
        .eq(get().eventItemPrice(event_item, true))
    ) {
      amendments.push({
        field: "Price Ea",
        contracted_value: get().formatCurrency(
          get().eventItemPrice(event_item, true)
        ),
        amended_value: get().formatCurrency(
          get().eventItemPrice(event_item, false)
        ),
      });
    }
    if (
      !get()
        .eventItemTotalPrice(event_item, false)
        .eq(get().eventItemTotalPrice(event_item, true))
    ) {
      amendments.push({
        field: "Total Price",
        contracted_value: get().formatCurrency(
          get().eventItemTotalPrice(event_item, true)
        ),
        amended_value: get().formatCurrency(
          get().eventItemTotalPrice(event_item, false)
        ),
      });
    }
    return amendments;
  },
  getEventItemByUuid: (uuid) => {
    var item = get().event_items.find((i) => i.uuid === uuid);
    let event_items = get().event_items;
    if (!item) {
      let group_items = event_items.filter(
        (event_item) => event_item.event_item_type === "GR"
      );
      let items_in_groups = group_items
        .map((group) => group.event_items_in_group)
        .reduce((a, b) => a.concat(b), []);
      item = items_in_groups.find((i) => i.uuid === uuid);
    }
    return item;
  },
  getEventItemGroupName: (event_item) => {
    if (!event_item.event_item_group) {
      return "";
    } else {
      let event_item_group = get().getEventItemByUuid(
        event_item.event_item_group
      );
      if (!event_item_group) {
        return "";
      }
      return event_item_group.name;
    }
  },
  getEventItemGroupOrder: (event_item) => {
    if (!event_item.event_item_group) {
      return -1;
    } else {
      let event_item_group = get().getEventItemByUuid(
        event_item.event_item_group
      );
      if (!event_item_group) {
        return -1;
      }
      return event_item_group.order;
    }
  },
  getEventItemGroupQuantity: (event_item, ignore_amendments) => {
    if (!event_item.event_item_group) {
      return 1;
    } else {
      let event_item_group = get().getEventItemByUuid(
        event_item.event_item_group
      );
      if (!event_item_group) {
        return 1;
      }
      return quantityFieldDec(event_item_group, ignore_amendments);
    }
  },
  getEventItemsWithAmendments: () => {
    return get()
      .allEventItems(true)
      .filter((event_item) => get().eventItemHasAmendments(event_item));
  },
  getRecipeListItemByUuid: (uuid) => {
    let item = get().getEventItemByUuid(uuid);
    if (!item) {
      item = get().recipe_proposal_sections.find((i) => i.uuid === uuid);
    }
    return item;
  },
  getRecipeListIndexByUuid: (uuid) => {
    let items = get().recipeListItems();
    return items.findIndex((i) => i.uuid === uuid);
  },
  handleEventItemDragEnd: (result) => {
    if (!result.destination && !result.combine) {
      return;
    }
    var item = get().getRecipeListItemByUuid(result.draggableId);
    if (result.combine && !["RE", "OT"].includes(item.event_item_type)) {
      return "Only recipes and other costs can be added to recipe groups";
    }
    if (result.combine) {
      var dest_event_item_group_uuid = result.combine.draggableId;
      let event_item_group = get().getEventItemByUuid(
        dest_event_item_group_uuid
      );
      if (event_item_group.event_item_type !== "GR") {
        return "Recipes and costs can only be added to recipe groups";
      }
      if (item.event_item_type === "OT" && item.is_percent) {
        return "Percent based costs cannot be added to recipe groups";
      }
      get().addEventItemToGroup(item, dest_event_item_group_uuid);
      get().updateEventItem({
        uuid: item.uuid,
        event_item_group: dest_event_item_group_uuid,
      });
      get().updateEventItemOrder({
        uuid: result.draggableId,
        action: "index",
        index: event_item_group.event_items_in_group.length,
      });
    } else if (result.destination.droppableId === "remove-from-group") {
      let event_item_group_uuid = item.event_item_group;
      get().addEventItemToGroup(item, undefined);
      get().updateEventItem({
        uuid: item.uuid,
        event_item_group: null,
      });
      let group_index = get().getRecipeListIndexByUuid(event_item_group_uuid);
      get().updateEventItemOrder({
        uuid: result.draggableId,
        action: "index",
        index: group_index + 1,
      });
    } else if (
      result.destination &&
      result.destination?.droppableId !== result.source.droppableId
    ) {
      let destination = result.destination.droppableId.split(":")[1];
      get().addEventItemToGroup(item, destination);
      get().updateEventItem({
        uuid: item.uuid,
        event_item_group: destination,
      });
      get().updateEventItemOrder({
        uuid: result.draggableId,
        action: "index",
        index: result.destination.index,
      });
    } else {
      get().updateEventItemOrder({
        uuid: result.draggableId,
        action: "index",
        index: result.destination.index,
      });
    }
  },
  handleRecipeItemDragEnd: (result, event_item) => {
    let [event_item_uuid, recipe_item_uuid] = result.draggableId.split(":");
    let data = {
      uuid: recipe_item_uuid,
      action: "index",
      index: result.destination.index,
      event_item: event_item,
    };
    get().updateRecipeItemOrder(data);
  },
  imgsInEvent: () => {
    let imgs = [];
    let img_uuids = [];
    for (const recipe of get().event_items.filter((ei) => !ei.is_template)) {
      for (const recipe_img of recipe.recipe_imgs) {
        if (!img_uuids.includes(recipe_img.uuid)) {
          img_uuids.push(recipe_img.uuid);
          imgs.push(recipe_img.cropped_image);
        }
      }
    }
    return imgs;
  },
  initializeEventItems: (data) => set({ event_items: data }),
  initializeRecipeProposalSections: (data) =>
    set({ recipe_proposal_sections: data }),
  lockEventItemPrices: () => {
    lockRecipePricesApi({
      event_version_uuid: get().event_version.uuid,
      event_items: get()
        .allEventItems(true)
        .map((ei) => ({
          uuid: ei.uuid,
          amend_price: get().eventItemPrice(ei).toFixed(2),
          price: get().eventItemPrice(ei, true).toFixed(2),
        })),
    }).then((resp) => {
      set({
        event_items: resp.data.event_items,
      });
    });
  },
  normalizeEventItemsOrder: (index_to_add, event_item_group) => {
    if (event_item_group) {
      get()._normalizeEventItemsOrderInGroup(index_to_add, event_item_group);
    } else {
      get()._normalizeEventItemsOrder(index_to_add);
    }
  },
  _normalizeEventItemsOrder: (index_to_add) => {
    let taxes = get().taxes().sort(sort_by_order);
    let items = get().recipeListItems().sort(sort_by_order);
    let new_items = items.map((item, i) => {
      if (index_to_add == undefined || i < index_to_add) {
        return { ...item, order: i };
      } else {
        return { ...item, order: i + 1 };
      }
    });
    let event_items = new_items.filter((item) => item.event_item_type);
    let recipe_proposal_sections = new_items.filter(
      (item) => !item.event_item_type
    );
    set({
      event_items: [...event_items, ...taxes],
      recipe_proposal_sections: recipe_proposal_sections,
    });
  },
  _normalizeEventItemsOrderInGroup: (index_to_add, event_item_group) => {
    let event_items_in_group = event_item_group.event_items_in_group;
    let new_items_in_group = event_items_in_group.map((item, i) => {
      if (index_to_add == undefined || i < index_to_add) {
        return { ...item, order: i };
      } else {
        return { ...item, order: i + 1 };
      }
    });
    let new_event_item_group = {
      ...event_item_group,
      event_items_in_group: new_items_in_group,
    };
    let event_items = get().event_items.filter(
      (item) => item.uuid !== event_item_group.uuid
    );
    let new_event_items = [...event_items, new_event_item_group].sort(
      sort_by_order
    );
    set({
      event_items: new_event_items,
    });
  },
  otherCostCost: (other_cost) => {
    return quantityFieldDec(other_cost).times(
      get().unitOtherCostCost(other_cost)
    );
  },
  otherCostHasAmendments: (other_cost) => {
    var res = false;
    AmendableOtherCostFields.forEach((field) => {
      if (field == "price") {
        if (
          other_cost["amend_" + field] !== null &&
          parseFloat(other_cost[field]) !==
            parseFloat(other_cost["amend_" + field])
        ) {
          res = true;
        }
      } else {
        if (
          other_cost["amend_" + field] !== null &&
          other_cost[field] !== other_cost["amend_" + field]
        ) {
          res = true;
        }
      }
    });
    return res;
  },
  otherCostPrice: (other_cost, ignore_amendments) => {
    return quantityFieldDec(other_cost, ignore_amendments).times(
      get().unitOtherCostPrice(other_cost, ignore_amendments)
    );
  },
  otherCostPriceWithTax: (other_cost, ignore_amendments) => {
    return get().proposal.spread_tax
      ? get()
          .otherCostPrice(other_cost, ignore_amendments)
          .plus(get().eventItemTaxTotal(other_cost, ignore_amendments))
      : get().otherCostPrice(other_cost, ignore_amendments);
  },
  otherCostPriceWithTaxAmended: (other_cost) => {
    return !get()
      .otherCostPriceWithTax(other_cost, true)
      .eq(get().otherCostPriceWithTax(other_cost, false));
  },
  otherCosts: () => {
    return get().event_items.filter(
      (item) => item.event_item_type === "OT" && !item.is_template
    );
  },
  otherCostsBelowSuggested: () => {
    return get()
      .otherCosts()
      .filter((other_cost) => {
        let suggested_price = get().suggestedOtherCostPrice(other_cost);
        return (
          priceNotEqualSuggested(other_cost, suggested_price) &&
          get().otherCostPrice(other_cost).cmp(suggested_price) < 0
        );
      }).length;
  },
  otherCostsCost: () => {
    let other_costs_cost_pre_fee = get().otherCostsCostPreFee();
    let flowerbuddy_fee_cost = get().flowerbuddyFeeCost();
    let stripe_fee_cost = get().stripeFeeCost();
    return other_costs_cost_pre_fee
      .plus(flowerbuddy_fee_cost)
      .plus(stripe_fee_cost);
  },
  otherCostsCostPreFee: () => {
    let other_costs = get()
      .allRecipesAndCosts()
      .filter((item) => {
        return item.event_item_type === "OT";
      })
      .filter((ei) => {
        if (!ei.event_item_group) {
          return !ei.is_optional || ei.is_selected;
        }
        let group = get().getEventItemByUuid(ei.event_item_group);
        return !group.is_optional || group.is_selected;
      });
    if (other_costs.length > 0) {
      return other_costs
        .map((other_cost) => {
          let cost = get().otherCostCost(other_cost);
          let group_quantity = get().getEventItemGroupQuantity(other_cost);
          return cost.times(group_quantity);
        })
        .reduce((p, c) => p.plus(c), new Decimal(0));
    } else {
      return new Decimal(0);
    }
  },
  otherCostsPrice: (ignore_amendments) => {
    let event_other_costs = get()
      .allRecipesAndCosts()
      .filter((item) => item.event_item_type === "OT");
    if (event_other_costs.length > 0) {
      let other_costs_prices = event_other_costs.map((other_cost) =>
        get()
          .otherCostPrice(other_cost, ignore_amendments)
          .times(get().getEventItemGroupQuantity(other_cost))
      );
      return other_costs_prices.reduce((p, c) => p.plus(c), new Decimal(0));
    } else {
      return new Decimal(0);
    }
  },
  otherCostsPriceWithTax: (ignore_amendments) => {
    let event_other_costs = get().otherCosts();
    if (event_other_costs.length > 0) {
      return event_other_costs
        .map((other_cost) =>
          get().otherCostPriceWithTax(other_cost, ignore_amendments)
        )
        .reduce((p, c) => p.plus(c), new Decimal(0));
    } else {
      return new Decimal(0);
    }
  },
  otherCostsHaveAmendments: () => {
    return get().otherCostsWithAmendments() > 0;
  },
  otherCostsWithAmendments: () => {
    var other_costs_with_amendments = get()
      .otherCosts()
      .filter((other_cost) => get().otherCostHasAmendments(other_cost));
    return other_costs_with_amendments.length;
  },
  overheadCosts: () => {
    let flowerbuddy_fee_cost = get().flowerbuddyFeeCost();
    let stripe_fee_cost = get().stripeFeeCost();
    return flowerbuddy_fee_cost.plus(stripe_fee_cost);
  },
  recipeLaborByType: (recipe, labor_type) => {
    if (recipe.event_item_type === "GR") {
      return recipe.event_items_in_group
        .map((ei) =>
          get().recipeLaborByType(ei, labor_type).times(quantityField(ei))
        )
        .reduce((a, b) => a.plus(b), new Decimal(0));
    } else {
      let labor_cost_record_name =
        labor_type === "build"
          ? "build_labor_cost_hourly"
          : "install_labor_cost_hourly";
      return new Decimal(
        get().getBackOfficeForEventItem(recipe)[labor_cost_record_name]
      )
        .times(
          new Decimal(
            get().recipeLaborTimeEstimateMinutesByType(recipe, labor_type)
          )
        )
        .dividedBy(new Decimal(60));
    }
  },
  recipeBuildLabor: (recipe) => {
    return get().recipeLaborByType(recipe, "build");
  },
  recipeInstallLabor: (recipe) => {
    return get().recipeLaborByType(recipe, "install");
  },
  recipeBuildLaborHoursComplete: () => {
    let recipes = get().recipesAndGroups();
    if (recipes.length > 0) {
      return recipes
        .map((recipe) => {
          let num_complete = recipe.build_progress
            ? recipe.build_progress.filter(
                (progress) => progress.status == "CO"
              ).length
            : 0;
          return new Decimal(
            get().recipeLaborTimeEstimateMinutesByType(recipe, "build")
          )
            .dividedBy(60)
            .times(new Decimal(num_complete));
        })
        .reduce((p, c) => p.plus(c), new Decimal(0));
    } else {
      return new Decimal(0);
    }
  },
  recipeBuildLaborHoursRemaining: () => {
    let hours_total = get().recipeBuildLaborHoursTotal();
    let hours_complete = get().recipeBuildLaborHoursComplete();
    return hours_total.minus(hours_complete);
  },
  recipeBuildLaborHoursTotal: () => {
    let recipes = get().recipesAndGroups();
    if (recipes.length > 0) {
      return recipes
        .filter((recipe) => !recipe.is_optional || recipe.is_selected)
        .map((recipe) =>
          new Decimal(
            get().recipeLaborTimeEstimateMinutesByType(recipe, "build")
          )
            .dividedBy(new Decimal(60))
            .times(DecimalWrapper(quantityField(recipe)))
        )
        .reduce((p, c) => p.plus(c), new Decimal(0));
    } else {
      return new Decimal(0);
    }
  },
  recipeBuildLaborPercentComplete: (recipe) => {
    if (!recipe.build_progress) {
      return new Decimal(0);
    } else {
      let num_complete = recipe.build_progress.filter(
        (progress) => progress.status == "CO"
      ).length;
      let group_quantity = get().getEventItemGroupQuantity(recipe);
      return new Decimal(num_complete).dividedBy(
        DecimalWrapper(quantityField(recipe) * group_quantity)
      );
    }
  },
  recipeBuildLaborPercentCompleteTotal: () => {
    let hours_total = get().recipeBuildLaborHoursTotal();
    let hours_complete = get().recipeBuildLaborHoursComplete();
    return hours_total > 0
      ? new Decimal(hours_complete).dividedBy(new Decimal(hours_total))
      : new Decimal(0);
  },
  recipeLaborRetailPrice: (recipe, labor_type) => {
    let back_office = get().getBackOfficeForEventItem(recipe);
    let cost = new Decimal(back_office[labor_type + "_labor_cost_hourly"]);
    let price = cost.times(
      new Decimal(back_office[labor_type + "_labor_cost_multiplier"])
    );
    let design_fee = cost.times(back_office.design_fee_percent / 100);
    price = price.plus(design_fee);
    price = get().addFees(price, recipe);
    return price;
  },
  recipeHasAmendments: (recipe) => {
    var res = false;
    AmendableRecipeFields.forEach((field) => {
      if (field == "price") {
        if (
          recipe["amend_" + field] !== null &&
          parseFloat(recipe[field]) !== parseFloat(recipe["amend_" + field])
        ) {
          res = true;
        }
      } else {
        if (
          recipe["amend_" + field] !== null &&
          recipe[field] !== recipe["amend_" + field]
        ) {
          res = true;
        }
      }
    });
    return res;
  },
  recipeInstallLaborHoursTotal: () => {
    let recipes = get().recipesAndGroups();
    if (recipes.length > 0) {
      return recipes
        .filter((recipe) => !recipe.is_optional || recipe.is_selected)
        .map((recipe) =>
          new Decimal(
            get().recipeLaborTimeEstimateMinutesByType(recipe, "install")
          )
            .dividedBy(new Decimal(60))
            .times(DecimalWrapper(quantityField(recipe)))
        )
        .reduce((p, c) => p.plus(c), new Decimal(0));
    } else {
      return new Decimal(0);
    }
  },
  recipeLabor: (recipe) => {
    return get()
      .recipeBuildLabor(recipe)
      .plus(get().recipeInstallLabor(recipe));
  },
  recipeLaborPrice: (recipe) => {
    let back_office = get().getBackOfficeForEventItem(recipe);
    return get()
      .recipeBuildLabor(recipe)
      .times(new Decimal(back_office.build_labor_cost_multiplier))
      .plus(
        get()
          .recipeInstallLabor(recipe)
          .times(new Decimal(back_office.install_labor_cost_multiplier))
      );
  },
  recipeLaborTimeEstimateMinutesByType: (recipe, labor_type) => {
    if (recipe.event_item_type === "GR") {
      let group_labor_estimate = recipe.event_items_in_group
        .map((ei) => {
          let labor_estimate =
            labor_type === "build"
              ? ei.build_labor_time_estimate_minutes
              : ei.instalation_labor_time_estimate_minutes;
          return labor_estimate * quantityField(ei);
        })
        .reduce((a, b) => a + b, 0);
      return group_labor_estimate;
    } else {
      return labor_type === "build"
        ? recipe.build_labor_time_estimate_minutes
        : recipe.instalation_labor_time_estimate_minutes;
    }
  },
  recipeListItems: () => {
    let recipe_list_items = [
      ...get().event_items.filter(
        (item) => item.event_item_type !== "TA" && !item.is_template
      ),
      ...get().recipe_proposal_sections,
    ].sort(sort_by_order);
    return recipe_list_items;
  },
  recipePrice: (recipe, ignore_amendments) => {
    var recipe_price = priceField(recipe, ignore_amendments);
    if (recipe_price !== null) {
      return DecimalWrapper(parseFloat(recipe_price));
    } else {
      return new Decimal(get().suggestedRecipePrice(recipe));
    }
  },
  recipePriceWithTax: (recipe, ignore_amendments) => {
    return get().proposal.spread_tax
      ? get()
          .recipePrice(recipe, ignore_amendments)
          .plus(get().eventItemTax(recipe, ignore_amendments))
      : get().recipePrice(recipe, ignore_amendments);
  },
  recipePriceWithTaxAmended: (recipe) => {
    return !get()
      .recipePriceWithTax(recipe, true)
      .eq(get().recipePriceWithTax(recipe, false));
  },
  recipeProfit: (recipe) => {
    return get().recipePrice(recipe).minus(get().unitRecipeCost(recipe));
  },
  recipes: () => {
    return get().event_items.filter(
      (item) => item.event_item_type === "RE" && !item.is_template
    );
  },
  recipeGroups: () => {
    return get().event_items.filter(
      (item) => item.event_item_type === "GR" && !item.is_template
    );
  },
  recipesAndGroups: () => {
    return [...get().recipes(), ...get().recipeGroups()].sort(sort_by_order);
  },
  recipesAndGroupsWithSubItems: () => {
    return get()
      .recipesAndGroups()
      .map((recipe) => {
        if (recipe.event_item_type === "GR") {
          let sub_items = recipe.event_items_in_group
            .filter((ei) => ei.event_item_type === "RE")
            .sort(sort_by_order);
          if (sub_items.length > 0) {
            return [recipe, ...sub_items];
          } else {
            return [];
          }
        } else {
          return [recipe];
        }
      })
      .flat();
  },
  recipesBelowSuggested: () => {
    return get()
      .recipes()
      .filter((recipe) => {
        let suggested_price = get().suggestedRecipePrice(recipe);
        return (
          priceNotEqualSuggested(recipe, suggested_price) &&
          get().recipePrice(recipe).cmp(suggested_price) < 0
        );
      }).length;
  },
  recipesHaveAmendments: () => {
    return get().recipesWithAmendments() > 0;
  },
  recipesLabor: () => {
    let event_recipes = get().event_items.filter((item) => !item.is_template);
    if (event_recipes.length > 0) {
      let labor = event_recipes
        .filter(
          (recipe) => !(isOptionalField(recipe) & !isSelectedField(recipe))
        )
        .map((recipe) =>
          get().recipeLabor(recipe).times(quantityFieldDec(recipe))
        );
      if (labor.length > 0) {
        return labor.reduce((p, c) => p.plus(c), new Decimal(0));
      } else {
        return new Decimal(0);
      }
    } else {
      return new Decimal(0);
    }
  },
  recipesNotInGroups: () => {
    return get()
      .recipes()
      .filter((recipe) => !recipe.event_item_group);
  },
  recipesWithAmendments: () => {
    var recipes_with_amendments = get()
      .recipes()
      .filter((recipe) => get().recipeHasAmendments(recipe));
    return recipes_with_amendments.length;
  },
  recipeTotalPrice: (recipe, ignore_amendments) => {
    return quantityFieldDec(recipe, ignore_amendments).times(
      get().recipePrice(recipe, ignore_amendments)
    );
  },
  recipeTotalPriceWithTax: (recipe, ignore_amendments) => {
    return get().proposal.spread_tax
      ? get()
          .recipeTotalPrice(recipe, ignore_amendments)
          .plus(get().eventItemTaxTotal(recipe, ignore_amendments))
      : get().recipeTotalPrice(recipe, ignore_amendments);
  },
  recipeTotalPriceWithTaxAmended: (recipe) => {
    return !get()
      .recipeTotalPriceWithTax(recipe, false)
      .eq(get().recipeTotalPriceWithTax(recipe, true));
  },
  recipeTotalProfit: (recipe) => {
    return quantityFieldDec(recipe).times(get().recipeProfit(recipe));
  },
  suggestedEventItemGroupPrice: (event_item, ignore_amendments, taxable) => {
    let event_items_in_group = event_item.event_items_in_group;
    let price = event_items_in_group
      .filter((ei) => salesTaxField(ei, ignore_amendments) || !taxable)
      .map((ei) => get().eventItemTotalPrice(ei, ignore_amendments))
      .reduce((p, c) => p.plus(c), new Decimal(0));
    return price;
  },
  suggestedEventItemPrice: (event_item, ignore_amendments) => {
    switch (event_item.event_item_type) {
      case "TA":
        return get().suggestedTaxPrice(event_item, ignore_amendments);
      case "RE":
        return get().suggestedRecipePrice(event_item);
      case "OT":
        return get().suggestedOtherCostPrice(event_item, ignore_amendments);
      case "GR":
        return get().suggestedEventItemGroupPrice(event_item);
    }
  },
  suggestedEventItemPriceTotal: (event_item, ignore_amendments) => {
    return quantityFieldDec(event_item, ignore_amendments).times(
      get().suggestedEventItemPrice(event_item)
    );
  },
  suggestedRecipePrice: (recipe) => {
    let back_office = get().getBackOfficeForEventItem(recipe);
    let event = get().event;
    let item_price = get().eventRecipeItemsPrice(recipe);
    let labor_price = get().recipeLaborPrice(recipe);
    let design_fee = get()
      .unitRecipeCost(recipe)
      .times(new Decimal(back_office.design_fee_percent / 100));
    let suggested_price = item_price.plus(labor_price).plus(design_fee);
    if (back_office.price_in_flowerbuddy_fee && !event?.created_during_trial) {
      let flowerbuddy_fee = get().flowerbuddyFee();
      if (flowerbuddy_fee) {
        var flowerbuddy_fee_cost = flowerbuddy_fee.times(suggested_price);
        suggested_price = suggested_price.plus(flowerbuddy_fee_cost);
      }
    }
    if (
      !recipe.is_template &&
      back_office.price_in_stripe_fee &&
      get().webPaymentsEnabled()
    ) {
      let stripe_fee = get().stripeFee();
      var stripe_fee_cost = stripe_fee.times(suggested_price);
      suggested_price = suggested_price.plus(stripe_fee_cost);
    }

    return Decimal(
      roundToNearest(suggested_price, back_office.round_suggested_prices)
    ).toDecimalPlaces(2);
  },
  suggestedTaxPrice: (event_item, ignore_amendments) => {
    if (quantityFieldDec(event_item, ignore_amendments) > 0) {
      if (isPercentField(event_item, ignore_amendments)) {
        let event_items = get().event_items.filter(
          (e) =>
            e.event_item_type !== "TA" &&
            !e.is_template &&
            salesTaxField(e, ignore_amendments) &&
            (!isOptionalField(e, ignore_amendments) ||
              isSelectedField(e, ignore_amendments))
        );

        const prices = event_items.map((e) =>
          get().eventItemTotalPrice(e, ignore_amendments, true)
        );
        if (get().proposal.spread_tax) {
          const taxes = prices.map((p) =>
            p.times(percentField(event_item, ignore_amendments) / 100)
          );
          return taxes.reduce((p, c) => p.plus(c), new Decimal(0));
        } else {
          const total_price = prices.reduce(
            (partialSum, a) => partialSum.plus(a),
            new Decimal(0)
          );
          return DecimalPercent(percentField(event_item, ignore_amendments))
            .times(total_price)
            .toDecimalPlaces(2);
        }
      } else {
        return DecimalWrapper(parseFloat(event_item.cost));
      }
    } else {
      return new Decimal(0);
    }
  },
  taxes: () => {
    return get().event_items.filter(
      (item) => item.event_item_type === "TA" && !item.is_template
    );
  },
  taxesHaveAmendments: () => {
    return get().taxesWithAmendments() > 0;
  },
  taxesNotEqualSuggested: () => {
    return get()
      .taxes()
      .filter((tax) => {
        let suggested_price = get().suggestedTaxPrice(tax);
        return priceNotEqualSuggested(tax, suggested_price);
      }).length;
  },
  taxesWithAmendments: () => {
    var taxes_with_amendments = get()
      .taxes()
      .filter((tax) => get().eventItemHasAmendments(tax));
    return taxes_with_amendments.length;
  },
  taxLines: (ignore_amendments) => {
    if (!get().proposal.group_tax) {
      let event_taxes = get()
        .taxes()
        .filter((tax) => quantityFieldDec(tax, ignore_amendments) > 0);
      if (event_taxes.length > 0) {
        return event_taxes.sort(sortByName).map((tax) => ({
          name: tax.name,
          percent: isPercentField(tax, ignore_amendments)
            ? percentField(tax, ignore_amendments)
            : undefined,
          amount: get().eventItemTax(tax, ignore_amendments),
        }));
      } else {
        return [];
      }
    } else {
      let amount = get().proposal.spread_tax
        ? get().eventTaxNonSpreadable(ignore_amendments)
        : get().eventTax(ignore_amendments);
      if (amount > 0) {
        return [
          {
            name: "Tax",
            amount: amount,
          },
        ];
      } else {
        return [];
      }
    }
  },
  taxLinesTotalAmount: (ignore_amendments) => {
    return get()
      .taxLines(ignore_amendments)
      .reduce((partialSum, a) => partialSum.plus(a.amount), new Decimal(0));
  },
  taxLinesTotalAmountAmended: () => {
    return !get()
      .taxLinesTotalAmount(false)
      .eq(get().taxLinesTotalAmount(true));
  },
  taxPrice: (tax, ignore_amendments) => {
    if (priceIsDerived(tax)) {
      return get().suggestedTaxPrice(tax, ignore_amendments);
    }
    let tax_price = priceField(tax, ignore_amendments);
    if (tax_price !== null) {
      return DecimalWrapper(tax_price);
    } else {
      return get().suggestedTaxPrice(tax, ignore_amendments);
    }
  },
  totalEventItemCost: (event_item) => {
    let unit_cost = get().unitEventItemCost(event_item);
    return unit_cost.times(quantityFieldDec(event_item));
  },
  totalEventItemGroupCost: (event_item) => {
    let unit_cost = get().unitEventItemGroupCost(event_item);
    return unit_cost.times(quantityFieldDec(event_item));
  },
  totalEventItemsPrice: (ignore_amendments, ignore_percent_costs) => {
    let event_items = get().event_items.filter(
      (item) => item.event_item_type !== "TA" && !item.is_template
    );
    if (ignore_percent_costs) {
      event_items = event_items.filter((item) => !item.is_percent);
    }
    if (event_items.length > 0) {
      return event_items
        .filter(
          (event_item) =>
            !(
              isOptionalField(event_item, ignore_amendments) &
              !isSelectedField(event_item, ignore_amendments)
            )
        )
        .map((event_item) =>
          get().eventItemTotalPrice(event_item, ignore_amendments)
        )
        .reduce((p, c) => p.plus(c), new Decimal(0));
    } else {
      return new Decimal(0);
    }
  },
  totalPercentTaxesPrice: (ignore_amendments) => {
    let taxes = get()
      .taxes()
      .filter((tax) => isPercentField(tax, ignore_amendments));
    let prices = taxes.map((tax) => get().taxPrice(tax, ignore_amendments));
    return prices.reduce((partialSum, a) => partialSum.plus(a), new Decimal(0));
  },
  totalRecipeCost: (recipe) => {
    let unit_cost = get().unitRecipeCost(recipe);
    return unit_cost.times(quantityFieldDec(recipe));
  },
  totalRecipesPrice: (ignore_amendments) => {
    let event_recipes = get()
      .allRecipesAndCosts()
      .filter((item) => item.event_item_type === "RE");
    if (event_recipes.length > 0) {
      let price = event_recipes
        .filter(
          (recipe) =>
            !(
              isOptionalField(recipe, ignore_amendments) &
              !isSelectedField(recipe, ignore_amendments)
            )
        )
        .map((recipe) =>
          get()
            .recipeTotalPrice(recipe, ignore_amendments)
            .times(get().getEventItemGroupQuantity(recipe))
        );
      if (price.length > 0) {
        return price.reduce((p, c) => p.plus(c), new Decimal(0));
      } else {
        return new Decimal(0);
      }
    } else {
      return new Decimal(0);
    }
  },
  totalTaxPercentDiscrepancy: (ignore_amendments) => {
    let total_percent_taxes_price =
      get().totalPercentTaxesPrice(ignore_amendments);
    let recipes_and_other_costs = [
      ...get()
        .recipes()
        .filter(
          (r) =>
            !isOptionalField(r, ignore_amendments) ||
            isSelectedField(r, ignore_amendments)
        ),
      ...get().otherCosts(),
    ];
    let event_item_taxes = recipes_and_other_costs.map((e) =>
      get().eventItemTaxTotal(e, ignore_amendments).toDecimalPlaces(2)
    );
    let total_event_item_taxes = event_item_taxes.reduce(
      (p, c) => p.plus(c),
      new Decimal(0)
    );
    return total_percent_taxes_price.minus(total_event_item_taxes);
  },
  unitEventItemCost: (event_item) => {
    switch (event_item.event_item_type) {
      case "RE":
        return get().unitRecipeCost(event_item);
      case "OT":
        return get().unitOtherCostCost(event_item);
      case "GR":
        return get().unitEventItemGroupCost(event_item);
    }
  },
  unitEventItemGroupCost: (event_item) => {
    let event_items_in_group = event_item.event_items_in_group;
    let cost = event_items_in_group
      .map((ei) => get().totalEventItemCost(ei))
      .reduce((p, c) => p.plus(c), new Decimal(0));
    return cost;
  },
  unitOtherCostCost: (other_cost, include_negative, ignore_amendments) => {
    if (other_cost.is_percent) {
      let event_price = get().totalEventItemsPrice(ignore_amendments, true);
      let cost = event_price.times(
        percentField(other_cost, ignore_amendments) / 100
      );
      if (cost.gte(0) || include_negative) {
        return cost;
      } else {
        return new Decimal(0);
      }
    } else {
      return new Decimal(other_cost.cost ? other_cost.cost : 0);
    }
  },
  unitOtherCostPrice: (other_cost, ignore_amendments) => {
    if (priceIsDerived(other_cost)) {
      return get().suggestedOtherCostPrice(other_cost, ignore_amendments);
    }
    let other_cost_price = priceField(other_cost, ignore_amendments);
    if (other_cost_price !== null) {
      return DecimalWrapper(other_cost_price);
    } else {
      return get().suggestedOtherCostPrice(other_cost, ignore_amendments);
    }
  },
  unitRecipeCost: (recipe) => {
    let item_cost = new Decimal(0);
    if (recipe.recipe_items.length > 0) {
      item_cost = get().eventRecipeItemsCost(recipe);
    }
    let labor_cost = get().recipeLabor(recipe);
    return item_cost.plus(labor_cost);
  },
  suggestedOtherCostPrice: (other_cost, ignore_amendments) => {
    let cost = get().unitOtherCostCost(other_cost, true, ignore_amendments);
    let price_with_markup = cost.times(
      markupPercentageField(other_cost, ignore_amendments)
        .dividedBy(100)
        .plus(1)
    );
    let back_office = get().getBackOfficeForEventItem(other_cost);
    let event = get().event;
    if (back_office.price_in_flowerbuddy_fee) {
      let flowerbuddy_fee = get().flowerbuddyFee();
      if (flowerbuddy_fee && !event.created_during_trial) {
        let flowerbuddy_fee_markup = flowerbuddy_fee.times(price_with_markup);
        if (flowerbuddy_fee_markup.gt(0)) {
          price_with_markup = price_with_markup.plus(flowerbuddy_fee_markup);
        }
      }
    }
    if (back_office.price_in_stripe_fee && get().webPaymentsEnabled()) {
      let stripe_fee_markup = get().stripeFee().times(price_with_markup);
      if (stripe_fee_markup.gt(0)) {
        price_with_markup = price_with_markup.plus(stripe_fee_markup);
      }
    }
    return Decimal(
      roundToNearest(price_with_markup, back_office.round_suggested_prices)
    ).toDecimalPlaces(2);
  },
  updateEventItem: (data, do_not_debounce, no_auth) => {
    if ("is_optional" in data && !data.is_optional) {
      data.is_selected = true;
    }
    if ("hide_sub_items" in data && data.hide_sub_items) {
      data.hide_sub_item_prices = true;
    }
    let event_item = get().getEventItemByUuid(data.uuid);
    let event_item_group = get().getEventItemByUuid(
      event_item.event_item_group
    );
    if (get().contractExists()) {
      let amendable_fields = AmendableEventItemFields;
      if (event_item_group) {
        if (event_item_group.hide_sub_items) {
          amendable_fields = [];
        } else if (event_item_group.hide_sub_item_prices) {
          amendable_fields = amendable_fields.filter(
            (field) => field !== "price"
          );
        }
      }
      if (event_item.event_item_type === "OT" && !event_item.is_percent) {
        amendable_fields = amendable_fields.filter(
          (field) => field !== "markup_percentage"
        );
      }
      amendable_fields.forEach((field) => {
        let price_amended = false;
        if (field in data) {
          data["amend_" + field] = data[field];
          delete data[field];
          price_amended = true;
        }
        if (price_amended && get().allPaymentsPaid()) {
          get().addFinalPayment();
        }
      });
    }
    event_item = get().updateEventItemInState(data);
    let updates = [data];
    if (
      event_item.event_item_group &&
      (data.price !== undefined ||
        data.amend_price !== undefined ||
        data.quantity !== undefined ||
        data.amend_quantity !== undefined)
    ) {
      let group = get().getEventItemByUuid(event_item.event_item_group);
      let group_data = {
        uuid: group.uuid,
        [get().contractExists() ? "amend_price" : "price"]:
          get().contractExists() ? get().suggestedEventItemPrice(group) : null,
      };
      get().updateEventItemInState(group_data);
      updates.push(group_data);
    }
    if (event_item.event_item_type !== "TA") {
      let percent_updates = get().getUpdatesForPercentBasedEventItems(
        event_item.uuid
      );
      updates = [...updates, ...percent_updates];
    }
    if (no_auth) {
      if (do_not_debounce) {
        updateEventItemInDbUnsecure(updates);
      } else {
        debouncedUpdateEventItemInDbUnsecure(updates);
      }
    } else {
      if (do_not_debounce) {
        updateEventItemInDb(updates);
      } else {
        debouncedUpdateEventItemInDb(updates);
      }
    }
    let final_event_item_state = get().getEventItemByUuid(data.uuid);
  },
  updateEventItemInState: (data) => {
    let event_items = get().event_items;
    let index = event_items.findIndex(byUUID(data.uuid));
    if (index === -1) {
      return get().updateEventItemInGroupInState(data);
    } else {
      let event_item = event_items[index];
      let new_event_items = [
        ...event_items.slice(0, index),
        { ...event_item, ...data },
        ...event_items.slice(index + 1),
      ];
      set({
        event_items: new_event_items,
      });
      return { ...event_item, ...data };
    }
  },
  updateEventItemInGroupInState: (data) => {
    let event_items = get().event_items;
    let event_item_groups = event_items.filter(
      (item) => item.event_item_type === "GR"
    );
    let event_item_group = event_item_groups.find((group) => {
      let uuids_in_group = group.event_items_in_group.map((i) => i.uuid);
      return uuids_in_group.includes(data.uuid);
    });
    let items_in_group = event_item_group.event_items_in_group;
    let index = items_in_group.findIndex(byUUID(data.uuid));
    let item = items_in_group[index];
    let new_item = { ...item, ...data };
    let new_items = [
      ...items_in_group.slice(0, index),
      new_item,
      ...items_in_group.slice(index + 1),
    ];
    let new_event_item_group = {
      ...event_item_group,
      event_items_in_group: new_items,
    };
    let new_event_items = [
      ...event_items.filter((item) => item.uuid !== event_item_group.uuid),
      new_event_item_group,
    ].sort(sort_by_order);
    set({
      event_items: new_event_items,
    });
    return new_item;
  },
  recipe_list_event_item_types: ["RE", "GR"],
  other_cost_list_event_item_types: ["OT"],
  otherCostListItems: () => {
    return [...get().otherCosts(), ...get().otherCostGroups()];
  },
  updateEventItemOrder(data) {
    let item = get().getRecipeListItemByUuid(data.uuid);
    if (item.event_item_group) {
      get()._updateEventItemOrderInGroup(data);
    } else {
      get()._updateEventItemOrder(data);
    }
  },
  _updateEventItemOrderInGroup: (data) => {
    let item = get().getRecipeListItemByUuid(data.uuid);
    let event_item_group = get().getEventItemByUuid(item.event_item_group);
    let items = event_item_group.event_items_in_group.sort(sort_by_order);
    const src = items.findIndex(byUUID(item.uuid));
    var dest;
    if (data.action === "up") {
      dest = src - 1;
      if (dest < 0) {
        return;
      }
    } else if (data.action === "down") {
      dest = src + 1;
      if (dest >= items.length) {
        return;
      }
    } else if (data.action === "index") {
      dest = data.index;
    } else {
      return;
    }
    let new_items = items.filter((i) => i.uuid !== item.uuid);

    new_items = [...new_items.slice(0, dest), item, ...new_items.slice(dest)];
    new_items = new_items.map((item, index) => {
      return { ...item, order: index };
    });
    event_item_group.event_items_in_group = new_items;
    let new_event_items = [
      ...get().event_items.filter(
        (item) => item.uuid !== event_item_group.uuid
      ),
      event_item_group,
    ];
    set({
      event_items: new_event_items,
    });
    let request_data = new_items.map((item) => {
      return {
        uuid: item.uuid,
        order: item.order,
        event_item_type: item.event_item_type,
      };
    });
    updateEventItemOrderApi(request_data).then((resp) => {
      snapshot("RESP", resp.data);
    });
  },
  _updateEventItemOrder: (data, in_state_only) => {
    let items = get().recipeListItems().sort(sort_by_order);
    let item = items.find(byUUID(data.uuid));
    const src = items.findIndex(byUUID(item.uuid));
    var dest;
    if (data.action === "up") {
      dest = src - 1;
      if (dest < 0) {
        return;
      }
    } else if (data.action === "down") {
      dest = src + 1;
      if (dest >= items.length) {
        return;
      }
    } else if (data.action === "index") {
      dest = data.index;
    } else {
      return;
    }
    let new_items = items.filter((i) => i.uuid !== item.uuid);
    new_items = [...new_items.slice(0, dest), item, ...new_items.slice(dest)];
    new_items = new_items.map((item, index) => {
      return { ...item, order: index };
    });
    let new_event_items = new_items.filter((item) => item.event_item_type);
    let new_recipe_proposal_sections = new_items.filter(
      (item) => !item.event_item_type
    );
    set({
      event_items: [...new_event_items, ...get().taxes()],
      recipe_proposal_sections: new_recipe_proposal_sections,
    });
    if (!in_state_only) {
      let request_data = new_items.map((item) => {
        return {
          uuid: item.uuid,
          order: item.order,
          event_item_type: item.event_item_type,
        };
      });
      updateEventItemOrderApi(request_data).then((resp) => {
        snapshot("RESP", resp.data);
      });
    }
  },
  updateEventItemUnsecure: (data, do_not_debounce) => {
    get().updateEventItem(data, do_not_debounce, true);
  },
  updateOrAddEventItemsInState: (event_items) => {
    event_items.forEach((item) => {
      let event_item = get().getEventItemByUuid(item.uuid);
      if (event_item) {
        get().updateEventItemInState(item);
      } else {
        get().addEventItemToState(item);
      }
    });
  },
  updateRecipeItem: (event_item, data) => {
    let recipe_items = event_item.recipe_items;
    let recipe_item_index = recipe_items.findIndex(byUUID(data.uuid));
    let recipe_item = recipe_items[recipe_item_index];
    let new_recipe_items = [
      ...recipe_items.slice(0, recipe_item_index),
      { ...recipe_item, ...data },
      ...recipe_items.slice(recipe_item_index + 1),
    ];
    get().updateEventItemInState({
      uuid: event_item.uuid,
      recipe_items: new_recipe_items,
    });
    debouncedUpdateRecipeItemApi(data);
  },
  updateRecipeItemOrder: (data) => {
    const event_item = data.event_item;
    const recipe_item = event_item.recipe_items.find(byUUID(data.uuid));
    var dest;
    let other_recipe_items = event_item.recipe_items.filter(
      (item) => item.item.item_type !== recipe_item.item.item_type
    );
    let recipe_items = event_item.recipe_items
      .filter((item) => item.item.item_type == recipe_item.item.item_type)
      .sort(sort_by_order);
    const src = recipe_items.findIndex(byUUID(recipe_item.uuid));
    if (data.action === "up") {
      dest = src - 1;
      if (dest < 0) {
        return;
      }
    } else if (data.action === "down") {
      dest = src + 1;
      if (dest >= recipe_items.length) {
        return;
      }
    } else if (data.action === "index") {
      dest = data.index;
    } else {
      return;
    }
    let new_recipe_items = recipe_items.filter(
      (item) => item.uuid !== recipe_item.uuid
    );
    new_recipe_items = [
      ...new_recipe_items.slice(0, dest),
      recipe_item,
      ...new_recipe_items.slice(dest),
    ];
    new_recipe_items = new_recipe_items.map((item, index) => {
      return { ...item, order: index };
    });
    get().updateEventItemInState({
      uuid: event_item.uuid,
      recipe_items: [...new_recipe_items, ...other_recipe_items],
    });
    let request_data = new_recipe_items.map((item) => {
      return { uuid: item.uuid, order: item.order };
    });
    updateRecipeItemOrderApi(request_data).then((resp) => {
      snapshot("RESP", resp.data);
    });
  },
  updateRecipeProposalSection: (data) => {
    let recipe_proposal_sections = get().recipe_proposal_sections;
    let index = recipe_proposal_sections.findIndex(byUUID(data.uuid));
    let recipe_proposal_section = recipe_proposal_sections[index];
    set({
      recipe_proposal_sections: [
        ...recipe_proposal_sections.slice(0, index),
        { ...recipe_proposal_section, ...data },
        ...recipe_proposal_sections.slice(index + 1),
      ],
    });
    updateRecipeProposalSectionApi(data).then((resp) => {
      snapshot("RESP", resp.data);
    });
  },
  getUpdatesForPercentBasedEventItems: (filter_event_item_uuid) => {
    let updates = [];
    let percent_costs = get()
      .otherCosts()
      .filter(
        (oc) =>
          oc.is_percent &&
          oc.price !== null &&
          oc.uuid !== filter_event_item_uuid
      );
    percent_costs.forEach((pc) => {
      if (!get().eventItemPrice(pc).eq(get().suggestedOtherCostPrice(pc))) {
        let cost_data = {
          uuid: pc.uuid,
          [get().contractExists() ? "amend_price" : "price"]:
            get().contractExists() ? get().suggestedOtherCostPrice(pc) : null,
        };
        get().updateEventItemInState(cost_data);
        updates.push(cost_data);
      }
    });
    let taxes = get()
      .taxes()
      .filter(
        (t) =>
          isPercentField(t) &&
          t.price !== null &&
          t.uuid !== filter_event_item_uuid
      );
    taxes.forEach((tax) => {
      if (!get().eventItemPrice(tax).eq(get().suggestedTaxPrice(tax))) {
        let tax_data = {
          uuid: tax.uuid,
          [get().contractExists() ? "amend_price" : "price"]:
            get().contractExists() ? get().suggestedTaxPrice(tax) : null,
        };
        get().updateEventItemInState(tax_data);
        updates.push(tax_data);
      }
    });
    return updates;
  },
  updatePercentBasedEventItems: (no_auth, do_not_debounce) => {
    let updates = get().getUpdatesForPercentBasedEventItems();
    if (updates.length > 0) {
      if (no_auth) {
        if (do_not_debounce) {
          updateEventItemInDbUnsecure(updates);
        } else {
          debouncedUpdateEventItemInDbUnsecure(updates);
        }
      } else {
        if (do_not_debounce) {
          updateEventItemInDb(updates);
        } else {
          debouncedUpdateEventItemInDb(updates);
        }
      }
    }
  },
  updateRecipeImgInState: (data) => {
    let recipe = get().getEventItemByUuid(data.event_item_uuid);
    let recipe_imgs = recipe.recipe_imgs;
    let recipe_img_index = recipe_imgs.findIndex(byUUID(data.uuid));
    let recipe_img = recipe_imgs[recipe_img_index];
    let new_recipe_imgs = [
      ...recipe_imgs.slice(0, recipe_img_index),
      { ...recipe_img, ...data },
      ...recipe_imgs.slice(recipe_img_index + 1),
    ];
    get().updateEventItemInState({
      uuid: recipe.uuid,
      recipe_imgs: new_recipe_imgs,
    });
  },
  handleRecipeImgDragEnd: (event_item_uuid, imgs) => {
    imgs = imgs.map((img, index) => {
      return { ...img, order: index };
    });
    get().updateEventItemInState({
      uuid: event_item_uuid,
      recipe_imgs: imgs,
    });
    let updates = imgs.map((img) => {
      return { uuid: img.uuid, order: img.order };
    });
    updateRecipeImgApi(updates);
  },
});
