import debounce from "lodash.debounce";
import { unsecureAPI } from "../api";

export const getUserEvents = (
  active,
  inventory_transactions,
  item_uuid,
  event_status
) => {
  return unsecureAPI.get("api/user_events/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: {
      active: active,
      inventory_transactions: inventory_transactions,
      item_uuid: item_uuid,
      status: event_status,
    },
  });
};

export const getEventOrderDataApi = (event_uuids) => {
  return unsecureAPI.post(
    "api/event_order_data/",
    { event_uuids: event_uuids },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const getUserDashboard = (n) => {
  let data = { n: n };
  return unsecureAPI.get("api/user_dashboard/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: data,
  });
};

export const getEventDashboardApi = (event_uuid) => {
  return unsecureAPI.get("api/event_dashboard/" + event_uuid, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addUserEvent = (data) => {
  return unsecureAPI.post("api/user_events/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addUserEventFromFormSubmission = (data) => {
  return unsecureAPI.post("api/create_event_from_submission/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteUserEvent = (event_uuid) => {
  return unsecureAPI.delete("api/user_events/" + event_uuid, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateUserEvent = (data) => {
  return unsecureAPI.put("api/user_events/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getDelinquentEvents = (data) => {
  return unsecureAPI.get("api/user_events/delinquent", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getEventTemplates = (data) => {
  return unsecureAPI.get("api/user_events/templates", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateEventItemApi = (data) => {
  return unsecureAPI.put("api/event_item/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateRecipeProposalSectionApi = (data) => {
  return unsecureAPI.put("api/recipe_proposal_section/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateEventRecipeApi = (data) => {
  return unsecureAPI.put("api/event_recipe/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const lockRecipePricesApi = (data) => {
  return unsecureAPI.put("api/event_recipe/lock_prices", data);
};

export const updateEventRecipeNoAuthApi = (data) => {
  return unsecureAPI.put("api/event_recipe_no_auth/", data);
};

export const updateEventItemNoAuthApi = (data) => {
  return unsecureAPI.put("api/event_item_no_auth/", data);
};

export const updateOtherCostApi = (data) => {
  return unsecureAPI.put("api/other_cost/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateRecipeItemApi = (data) => {
  return unsecureAPI.put("api/recipe_item/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const debouncedUpdateRecipeItemApi = debounce(updateRecipeItemApi, 500);

export const addEventItemApi = (data) => {
  return unsecureAPI.post("api/event_item/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addRecipeProposalSectionApi = (data) => {
  return unsecureAPI.post("api/recipe_proposal_section/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getTasksApi = () => {
  return unsecureAPI.get("api/task/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addTaskApi = (data) => {
  return unsecureAPI.post("api/task/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateTaskApi = (data) => {
  return unsecureAPI.put("api/task/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteTaskApi = (data) => {
  return unsecureAPI.delete("api/task/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: data,
  });
};

export const getSelectableItems = (item_type) => {
  return unsecureAPI.get("api/selectable_items/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: {
      item_type: item_type,
    },
  });
};

export const addItemApi = (event_recipe_uuid, item_uuid) => {
  let data = {
    event_recipe_uuid: event_recipe_uuid,
    item_uuid: item_uuid,
  };
  return unsecureAPI.post("api/recipe_item/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addPaletteColorApi = (data) => {
  return unsecureAPI.post("api/proposal_color/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updatePaletteColorApi = (data) => {
  return unsecureAPI.put("api/proposal_color/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addProposalItemApi = (data) => {
  return unsecureAPI.post("api/proposal_item/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateProposalItemApi = (data) => {
  return unsecureAPI.put("api/proposal_item/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateProposalImgApi = (data) => {
  return unsecureAPI.put("api/proposal_img/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addProposalImgApi = (data) => {
  return unsecureAPI.post("api/proposal_img/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateRecipeImgApi = (data) => {
  return unsecureAPI.put("api/recipe_img/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addProposalNoteApi = (data) => {
  return unsecureAPI.post("api/proposal_notes/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addRecipeImgApi = (data) => {
  return unsecureAPI.post("api/recipe_img/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteRecipeImgApi = (recipe_img_uuid) => {
  return unsecureAPI.delete("api/recipe_img/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: {
      recipe_img_uuid: recipe_img_uuid,
    },
  });
};

export const removeItemApi = (recipe_item_uuid) => {
  return unsecureAPI.delete("api/recipe_item/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: {
      recipe_item_uuid: recipe_item_uuid,
    },
  });
};

export const removePaletteColorApi = (palette_color_uuid) => {
  return unsecureAPI.delete("api/proposal_color/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: {
      uuid: palette_color_uuid,
    },
  });
};

export const removeProposalItemApi = (proposal_item_uuid) => {
  return unsecureAPI.delete("api/proposal_item/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: {
      uuid: proposal_item_uuid,
    },
  });
};

export const removeProposalImgApi = (proposal_img_uuid) => {
  return unsecureAPI.delete("api/proposal_img/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: {
      uuid: proposal_img_uuid,
    },
  });
};

export const removeProposalNoteApi = (note_uuid) => {
  return unsecureAPI.delete("api/proposal_notes/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: {
      uuid: note_uuid,
    },
  });
};

export const removeEventItemApi = (event_item_uuid) => {
  return unsecureAPI.delete("api/event_item/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: {
      uuid: event_item_uuid,
    },
  });
};

export const removeRecipeProposalSectionApi = (
  recipe_proposal_section_uuid
) => {
  return unsecureAPI.delete("api/recipe_proposal_section/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: {
      uuid: recipe_proposal_section_uuid,
    },
  });
};

export const createItemApi = (data) => {
  return unsecureAPI.post("api/item/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateEventVersionApi = (data) => {
  return unsecureAPI.put("api/event_version/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateBackOfficeApi = (data) => {
  return unsecureAPI.put("api/back_office/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateProposalApi = (data) => {
  return unsecureAPI.put("api/proposal/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const debouncedUpdateProposalApi = debounce(updateProposalApi, 500);

export const updateProposalSectionApi = (data) => {
  return unsecureAPI.put("api/proposal_section/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateProposalNoteApi = (data) => {
  return unsecureAPI.put("api/proposal_notes/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getItemPriceApi = (
  item_uuid,
  vendor_location_uuid,
  event_version_uuid
) => {
  return unsecureAPI.get("api/item_price/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: {
      item_uuid: item_uuid,
      vendor_location_uuid: vendor_location_uuid,
      event_version_uuid: event_version_uuid,
    },
  });
};

export const updateItemApi = (data) => {
  return unsecureAPI.put("api/item/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateItemPriceApi = (data) => {
  return unsecureAPI.put("api/item_price/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getSelectableVendors = (item_uuid, event_version_uuid) => {
  let data = {
    item_uuid: item_uuid,
    event_version_uuid: event_version_uuid,
  };
  return unsecureAPI.get("api/selectable_vendors/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: data,
  });
};

export const getVendorLocations = (vendor_uuid) => {
  let data = { vendor_uuid: vendor_uuid };
  return unsecureAPI.get("api/vendor_location/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: data,
  });
};

export const getVendorDefaults = () => {
  return unsecureAPI.get("api/vendor_defaults/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const createVendorApi = (data) => {
  return unsecureAPI.post("api/vendor/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const createVendorLocationApi = (data) => {
  return unsecureAPI.post("api/vendor_location/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateEventItemOrderApi = (data) => {
  return unsecureAPI.put("api/event_item_ordering/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateRecipeOrderApi = (data) => {
  return unsecureAPI.put("api/recipe_ordering/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateOtherCostOrderApi = (data) => {
  return unsecureAPI.put("api/other_cost_ordering/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateRecipeItemOrderApi = (data) => {
  return unsecureAPI.put("api/recipe_item_ordering/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateProposalNotesOrderApi = (data) => {
  return unsecureAPI.put("api/proposal_notes_ordering/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const getProposalView = (proposal_uuid) => {
  let data = { uuid: proposal_uuid };
  return unsecureAPI.get("api/proposal_view/", {
    params: data,
  });
};

export const deleteClientApi = (client_uuid) => {
  return unsecureAPI.delete("api/client/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: {
      uuid: client_uuid,
    },
  });
};

export const addClientApi = (data) => {
  return unsecureAPI.post("api/client/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateClientApi = (data) => {
  return unsecureAPI.put("api/client/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const uploadEventImgApi = (data, event_uuid) => {
  return unsecureAPI.post(
    "api/event_img/",
    { ...data, event_uuid: event_uuid },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const getEventCoordinatorsApi = () => {
  return unsecureAPI.get("api/event_coordinator/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addEventCoordinatorApi = (data) => {
  return unsecureAPI.post("api/event_coordinator/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateEventCoordinator = (data) => {
  return unsecureAPI.put("api/event_coordinator/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addEventContactApi = (data) => {
  return unsecureAPI.post("api/event_contact/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const removeEventContactApi = (data) => {
  return unsecureAPI.delete("api/event_contact/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: data,
  });
};

export const getLocationsApi = () => {
  return unsecureAPI.get("api/location/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const createNewLocationApi = (data) => {
  return unsecureAPI.post("api/location/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addEventLocationApi = (data) => {
  return unsecureAPI.post("api/event_location/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteEventLocationApi = (uuid) => {
  return unsecureAPI.delete("api/event_location/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: {
      uuid: uuid,
    },
  });
};

export const updateEventLocationApi = (data) => {
  return unsecureAPI.put("api/event_location/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateLocationApi = (data) => {
  return unsecureAPI.put("api/location/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const swapEventItemApi = (data) => {
  return unsecureAPI.post("api/swap_event_item/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addTaxGroupToEventApi = (data) => {
  return unsecureAPI.post("api/event_tax_group/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const removeTaxGroupFromEventApi = (data) => {
  return unsecureAPI.delete("api/event_tax_group/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: data,
  });
};

export const getBuildPublicView = (event_uuid) => {
  let data = { event_uuid: event_uuid };
  return unsecureAPI.get("api/build_public/", {
    params: data,
  });
};

export const getEventItemsApi = (event_uuid) => {
  let data = { event_uuid: event_uuid };
  return unsecureAPI.get("api/event_items/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    params: data,
  });
};

export const getRecipeTemplatesApi = () => {
  return unsecureAPI.get("api/recipe_templates/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addRecipeTemplatesToEventApi = (data) => {
  return unsecureAPI.post("api/recipe_templates_to_event/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const applyRecipeTemplateApi = (data) => {
  return unsecureAPI.post("api/apply_recipe_template/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteProposalAttachmentApi = (data) => {
  return unsecureAPI.delete("api/proposal_attachment/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: data,
  });
};

export const addProposalAttachmentApi = (data) => {
  return unsecureAPI.post("api/proposal_attachment/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteProposalSectionApi = (uuid) => {
  return unsecureAPI.delete("api/proposal_section/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: {
      uuid: uuid,
    },
  });
};

export const addProposalSectionApi = (data) => {
  return unsecureAPI.post("api/proposal_section/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateProposalAttachmentApi = (data) => {
  return unsecureAPI.put("api/proposal_attachment/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const bulkUpdateProposalSectionsApi = (data) => {
  return unsecureAPI.put("api/proposal_section/bulk_update/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const bulkUpdateItemPricesApi = (data) => {
  return unsecureAPI.post("api/item_price/bulk_update/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const generateOrderPdfApi = (data) => {
  return unsecureAPI.post("api/generate_order_pdf/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addEventAttachmentApi = (data) => {
  return unsecureAPI.post("api/event_attachment/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteEventAttachmentApi = (data) => {
  return unsecureAPI.delete("api/event_attachment/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: data,
  });
};

export const updateEventAttachmentApi = (data) => {
  return unsecureAPI.put("api/event_attachment/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const generateEventItemApi = (event_version_uuid, prompt) => {
  return unsecureAPI.post(
    "api/event_item_ai/",
    {
      event_version_uuid,
      prompt,
    },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    }
  );
};
