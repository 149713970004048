import React from "react";
import { withRouter } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  Button,
  Link,
  Tooltip,
  Divider,
  Chip,
  DialogActions,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { LogoBee } from "./Navbar";
import { format_utc_timestamp, sortByName } from "../utils/utils";
import EventItem from "./EventItem";
import { useFormik } from "formik";
import * as Yup from "yup";
import { EditTaxGroupDialog, EditTaxProfileDialog } from "./TaxProfileSettings";
import { addEventItemApi } from "../api/EventsApi";
import { addTaxGroupApi } from "../api/DataApi";
import { useAccountState } from "../state/store";
import { track_event } from "../utils/event_tracking";

const OtherCosts = (props) => {
  const [
    addOtherCost,
    addTax,
    addTaxGroupToEvent,
    deleteTaxGroupFromEvent,
    eventLocked,
    event_version,
    handleEventItemDragEnd,
    otherCosts,
    otherCostsBelowSuggested,
    taxes,
    taxesNotEqualSuggested,
    tax_groups,
    tax_profiles,
  ] = useAccountState((state) => [
    state.addOtherCost,
    state.addTax,
    state.addTaxGroupToEvent,
    state.deleteTaxGroupFromEvent,
    state.eventLocked,
    state.event_version,
    state.handleEventItemDragEnd,
    state.otherCosts,
    state.otherCostsBelowSuggested,
    state.taxes,
    state.taxesNotEqualSuggested,
    state.tax_groups,
    state.tax_profiles,
  ]);

  const [addingTax, setAddingTax] = React.useState(false);
  const [addingTaxGroup, setAddingTaxGroup] = React.useState(false);

  return (
    <Box sx={{ height: "calc(100vh - 225px)", overflow: "auto" }}>
      <Box sx={{ mb: "calc(100vh/2)" }}>
        <Grid container sx={{ width: "100%", maxWidth: "800px", m: "auto" }}>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={"auto"}>
              <Typography variant="h6">Taxes</Typography>
            </Grid>
            <Grid item xs={"auto"} container spacing={1}>
              <Grid item xs={"auto"}>
                <Button
                  variant="contained"
                  color="secondary"
                  aria-label="add"
                  sx={{ mb: ".25rem" }}
                  onClick={() => {
                    setAddingTax(true);
                  }}
                  size="small"
                  disabled={eventLocked()}
                  id="add-tax-button"
                >
                  <AddOutlinedIcon />
                  Add Tax
                </Button>
                <AddTaxDialog
                  open={addingTax}
                  handleCancel={() => setAddingTax(false)}
                  handleSubmit={addTax}
                  tax_profiles={tax_profiles}
                  taxes={taxes()}
                  addTax={addTax}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <Button
                  variant="contained"
                  color="secondary"
                  aria-label="add"
                  sx={{ mb: ".25rem" }}
                  onClick={() => {
                    setAddingTaxGroup(true);
                  }}
                  size="small"
                  disabled={eventLocked()}
                  id="add-tax-group-button"
                >
                  <AddOutlinedIcon />
                  Add Tax Group
                </Button>
                <AddTaxGroupDialog
                  open={addingTaxGroup}
                  handleCancel={() => setAddingTaxGroup(false)}
                  handleSubmit={addTaxGroupToEvent}
                  tax_profiles={tax_profiles}
                  taxes={taxes()}
                  tax_groups={tax_groups}
                  addTax={addTax}
                  event_version={event_version}
                />
              </Grid>
            </Grid>
          </Grid>
          {taxesNotEqualSuggested() > 0 ? (
            <Grid item xs={12}>
              <Alert severity="error">
                Some taxes prices are not equal to their calculated costs.
              </Alert>
            </Grid>
          ) : null}
          {event_version.tax_groups.length > 0 ? (
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              spacing={1}
              sx={{ mb: ".5rem" }}
            >
              <Grid item xs="auto">
                <Typography>Tax Groups:</Typography>
              </Grid>
              {event_version.tax_groups.sort(sortByName).map((tax_group) => (
                <Grid item xs="auto">
                  <TaxGroupChip
                    tax_group={tax_group}
                    removeTaxGroup={deleteTaxGroupFromEvent}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
          <Grid item xs={12} id="taxes-body">
            {taxes().length > 0 ? (
              taxes()
                .sort(sortByName)
                .map((tax) => <EventItem tax key={tax.uuid} event_item={tax} />)
            ) : (
              <Box>
                <Box
                  sx={{
                    borderStyle: "dotted",
                    borderColor: "info.light",
                    p: "2rem",
                    borderRadius: "7.5px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "info.light",
                      fontWeight: "normal",
                    }}
                    variant="h6"
                  >
                    No taxes have been added yet.{" "}
                    {!eventLocked() ? (
                      <Link
                        onClick={() => {
                          setAddingTax(true);
                        }}
                        color="inherit"
                        sx={{ "&:hover": { cursor: "pointer" } }}
                      >
                        Add Tax
                      </Link>
                    ) : null}
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>
          <Divider sx={{ width: "100%", mt: "1rem", mb: "1rem" }} />
          <Grid item xs={12} id="overhead-costs">
            <OverheadCards />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const TaxGroupChip = (props) => {
  const [deleting, setDeleting] = React.useState(false);
  return (
    <>
      <Chip
        label={props.tax_group.name}
        onDelete={() => {
          setDeleting(true);
        }}
        id={`tax-group-chip-${props.tax_group.uuid}`}
      />
      <DeleteTaxGroupDialog
        tax_group={props.tax_group}
        removeTaxGroup={props.removeTaxGroup}
        handleCancel={() => setDeleting(false)}
        open={deleting}
      />
    </>
  );
};

const DeleteTaxGroupDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Remove Tax Group</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to remove the tax group "{props.tax_group.name}"
          from this event? This will only remove the tax group from this event.
          No taxes will be removed as part of this action.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="info"
          variant="outlined"
          onClick={props.handleCancel}
          id="cancel-remove-tax-group-button"
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={() => {
            props.removeTaxGroup(props.tax_group.uuid);
            props.handleCancel();
          }}
          id="confirm-remove-tax-group-button"
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const OverheadCards = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box>
      <Typography sx={{ ml: fullScreen ? "0.5rem" : undefined }} variant="h6">
        Overhead Costs
      </Typography>
      <FlowerbuddyFeeCard />
      <StripeFeeCard />
    </Box>
  );
};

const FlowerbuddyFeeCard = (props) => {
  const [back_office, event, flowerbuddyFeeCost, formatCurrency, userPlanType] =
    useAccountState((state) => [
      state.back_office,
      state.event,
      state.flowerbuddyFeeCost,
      state.formatCurrency,
      state.userPlanType,
    ]);

  return (
    <Paper
      sx={{
        width: "100%",
        m: "auto",
      }}
    >
      <Box sx={{ p: "1rem" }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={4} sm={2} container justifyContent="center">
            <Grid item xs="auto">
              <LogoBee imgStyle={{ maxHeight: "70px", m: "auto" }} />
            </Grid>
          </Grid>
          <Grid item xs={8} sm={6} container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                {userPlanType() == "PPE" ? "1%" : "2%"} of pre-tax price total
              </Typography>
            </Grid>
            {back_office.price_in_flowerbuddy_fee ? (
              <Grid item xs={12}>
                <Typography>
                  <Tooltip
                    title="You have this fee built into your pricing formula. As long as you
              keep your prices at (or above) the suggested amount, you're
              covered!"
                  >
                    <CheckCircleOutlineOutlinedIcon
                      fontSize="inherit"
                      color="success"
                    />
                  </Tooltip>
                  You have this fee built into your pricing formula
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography>
                  <Tooltip title="You are not including this fee in your pricing. Enable FlowerBuddy Fee in the Overhead section on the Details tab.">
                    <CancelOutlinedIcon fontSize="inherit" color="error" />
                  </Tooltip>
                  You are not including this fee in your pricing
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            container
            justifyContent="flex-end"
            spacing={1}
          >
            {event.flowerbuddy_fee_paid &&
            ["CS", "ES"].includes(event.flowerbuddy_fee_trigger) ? (
              event.flowerbuddy_fee_invoice?.status_transitions?.paid_at ? (
                <Grid item xs="auto">
                  <Tooltip
                    title={format_utc_timestamp(
                      event.flowerbuddy_fee_invoice?.status_transitions?.paid_at
                    )}
                  >
                    <Chip label="Paid" size="small" />
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item xs="auto">
                  <Chip label="Paid" size="small" />
                </Grid>
              )
            ) : null}
            {event.flowerbuddy_fee_paid &&
            event.flowerbuddy_fee_trigger == "BE" ? (
              <Grid item xs="auto">
                <Tooltip title={"Booked during FlowerBuddy BETA"}>
                  <Chip label="BETA" size="small" />
                </Tooltip>
              </Grid>
            ) : null}
            {event.created_during_trial ? (
              <Grid item xs="auto">
                <Tooltip
                  title={
                    "The FlowerBuddy fee is set to zero since this event was created during your free trial."
                  }
                >
                  <Chip label="Trial" size="small" />
                </Tooltip>
              </Grid>
            ) : null}
            {userPlanType() == "UNL" &&
            event.flowerbuddy_fee_trigger !== "BE" ? (
              <Grid item xs="auto">
                <Tooltip
                  title={
                    "No event fees are charged with your FlowerBuddy Unlimited plan!"
                  }
                >
                  <Chip label="Unlimited" size="small" />
                </Tooltip>
              </Grid>
            ) : null}
            <Grid
              item
              xs="auto"
              container
              alignItems={"center"}
              justifyContent="flex-end"
            >
              <Grid item xs="auto">
                <Typography sx={{ fontWeight: "bold" }} textAlign={"right"}>
                  {formatCurrency(flowerbuddyFeeCost())}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const StripeFeeCard = (props) => {
  const [
    back_office,
    formatCurrency,
    stripeFeeCost,
    webPaymentsEnabled,
    userPlanType,
  ] = useAccountState((state) => [
    state.back_office,
    state.formatCurrency,
    state.stripeFeeCost,
    state.webPaymentsEnabled,
    state.userPlanType,
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper
      sx={{
        width: "100%",
        m: "auto",
        mt: "1rem",
      }}
    >
      <Box sx={{ p: "1rem" }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={4} sm={2} container justifyContent="center">
            <Grid item xs="auto">
              <img
                width="100px"
                src="https://flowerbuddy-static-media.s3.us-west-2.amazonaws.com/stripe_logo_small.png"
              />
            </Grid>
          </Grid>
          <Grid item xs={8} sm={6} container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                {"2.9% of total price + 30 cents per transaction"}
                <Tooltip
                  title={
                    "The actual stripe fee is 2.9%. FlowerBuddy accounts for a 3.15% fee since there are some costs that are not captured by this overhead item but affect the total cost of this fee (i.e. tax)."
                  }
                >
                  <HelpOutlineOutlinedIcon fontSize="inherit" />
                </Tooltip>
              </Typography>
              {userPlanType() === "FRE" && (
                <Typography>
                  + 0.5% FlowerBuddy payment processing fee
                  <Tooltip
                    title={
                      "The Freemium plan incurs an additional payment processing fee. Upgrade your plan to eliminate the FlowerBuddy payment processing fee."
                    }
                  >
                    <HelpOutlineOutlinedIcon fontSize="inherit" />
                  </Tooltip>
                </Typography>
              )}
            </Grid>
            {back_office.price_in_stripe_fee ? (
              <Grid item xs={12}>
                <Typography>
                  <Tooltip
                    title="You have this fee built into your pricing formula. As long as you
              keep your prices at (or above) the suggested amount, you're
              covered!"
                  >
                    <CheckCircleOutlineOutlinedIcon
                      fontSize="inherit"
                      color="success"
                    />
                  </Tooltip>
                  You have this fee built into your pricing formula
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography>
                  <Tooltip title="You are not including this fee in your pricing. Enable Stripe Fee in the Overhead section on the Details tab.">
                    <CancelOutlinedIcon fontSize="inherit" color="error" />
                  </Tooltip>
                  You are not including this fee in your pricing
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            container
            alignItems={"center"}
            justifyContent="flex-end"
          >
            {!webPaymentsEnabled() ? (
              <Grid item xs="auto">
                <Tooltip title="This fee is set to zero because web payments are disabled for this event.">
                  <ErrorOutlineOutlinedIcon fontSize="inherit" color="info" />
                </Tooltip>
              </Grid>
            ) : null}

            <Grid item xs="auto">
              <Typography sx={{ fontWeight: "bold" }} textAlign={"right"}>
                {formatCurrency(stripeFeeCost())}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const AddTaxDialog = (props) => {
  const [adding, setAdding] = React.useState(false);
  const sortTaxProfiles = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
  const filterTaxProfiles = (tax_profile) => {
    let tax_uuids = props.taxes.map((t) => t.tax_profile);
    return !tax_uuids.includes(tax_profile.uuid);
  };
  const [options, setOptions] = React.useState(
    props.tax_profiles.sort(sortTaxProfiles).filter(filterTaxProfiles)
  );

  React.useEffect(() => {
    setOptions(
      props.tax_profiles.sort(sortTaxProfiles).filter(filterTaxProfiles)
    );
  }, [props.tax_profiles, props.taxes]);

  const validationSchema = Yup.object({
    tax_profile: Yup.object().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      tax_profile: null,
    },
    onSubmit: (values, { resetForm }) => {
      props.handleSubmit(values);
      resetForm();
      props.handleCancel();
    },
    validationSchema: validationSchema,
  });

  const addTaxProfile = (data) => {
    addEventItemApi({ ...data, event_item_type: "TA" }).then((resp) => {
      setOptions(
        [...props.tax_profiles, resp.data.event_item]
          .sort(sortTaxProfiles)
          .filter(filterTaxProfiles)
      );
      formik.setFieldValue("tax_profile", resp.data.event_item);
      track_event("Added Tax Profile");
    });
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>Add Tax</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ width: "300px", mt: ".25rem" }}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={options}
              renderInput={(params) => (
                <TextField {...params} label="Tax Profile" />
              )}
              value={formik.values.tax_profile}
              onChange={(event, newValue) => {
                formik.setFieldValue("tax_profile", newValue);
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.uuid === value.uuid
              }
              noOptionsText={
                <Typography>
                  No tax profiles match your search.{" "}
                  <Link
                    onClick={() => {
                      setAdding(true);
                    }}
                  >
                    Create a new tax profile?
                  </Link>
                </Typography>
              }
            />
            <EditTaxProfileDialog
              open={adding}
              handleCancel={() => {
                setAdding(false);
              }}
              handleSubmit={addTaxProfile}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="cancel-add-tax"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={formik.handleSubmit}
          id="confirm-add-tax"
        >
          Add Tax
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddTaxGroupDialog = (props) => {
  const [adding, setAdding] = React.useState(false);
  const [taxGroupsInEvent, setTaxGroupsInEvent] = React.useState(
    props.event_version.tax_groups.map((t) => t.uuid)
  );

  const [options, setOptions] = React.useState(
    props.tax_groups.sort(sortByName)
  );

  React.useEffect(() => {
    setOptions(props.tax_groups.sort(sortByName));
  }, [props.tax_groups]);

  React.useEffect(() => {
    setTaxGroupsInEvent(props.event_version.tax_groups.map((t) => t.uuid));
  }, [props.event_version.tax_groups]);

  const validationSchema = Yup.object({
    tax_group: Yup.object().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      tax_group: null,
    },
    onSubmit: (values, { resetForm }) => {
      props.handleSubmit(values);
      resetForm();
      props.handleCancel();
    },
    validationSchema: validationSchema,
  });

  const addTaxGroup = (data) => {
    addTaxGroupApi(data).then((resp) => {
      setOptions([...props.tax_groups, resp.data].sort(sortByName));
      formik.setFieldValue("tax_group", resp.data);
    });
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>Add Tax Group</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ width: "300px", mt: ".25rem" }}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={options}
              getOptionDisabled={(option) =>
                taxGroupsInEvent.includes(option.uuid)
              }
              renderInput={(params) => (
                <TextField {...params} label="Tax Group" />
              )}
              value={formik.values.tax_group}
              onChange={(event, newValue) => {
                formik.setFieldValue("tax_group", newValue);
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.uuid === value.uuid
              }
              noOptionsText={
                <Typography>
                  No tax groups match your search.{" "}
                  <Link
                    onClick={() => {
                      setAdding(true);
                    }}
                  >
                    Create a new tax group?
                  </Link>
                </Typography>
              }
            />
            <EditTaxGroupDialog
              open={adding}
              handleCancel={() => {
                setAdding(false);
              }}
              handleSubmit={addTaxGroup}
              tax_profiles={props.tax_profiles}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={formik.handleSubmit}
        >
          Add Tax
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(OtherCosts);
