import React from "react";
import { withRouter } from "react-router-dom";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Typography,
  Link,
  Alert,
  Grid,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Menu,
  TextField,
  IconButton,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EventItem from "./EventItem";
import { useAccountState } from "../state/store";
import { snapshot, sort_by_order } from "../utils/utils";
import RecipeProposalSection from "./RecipeProposalSection";
import EventItemDividerButtons from "./EventItemDividerButtons";
import { RecipeTemplateSelector } from "./RecipeTemplates";
import { UnderPricedEventItemTable } from "./Proposal";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

import LocalFloristOutlinedIcon from "@mui/icons-material/LocalFloristOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import SegmentOutlinedIcon from "@mui/icons-material/SegmentOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { PaletteItemsList } from "./PaletteItems";
import { G } from "@react-pdf/renderer";
import { LoadingButton } from "@mui/lab";
import { generateEventItemApi } from "../api/EventsApi";
import { ItemIcon } from "./ItemIcon";
import {
  itemDisplayName,
  sortDraftRecipeItemsByName,
} from "../utils/item_utils";
import { QuantityInput } from "./QuantityInput";
import AddItemDialog from "./AddItemDialog";
import { add } from "lodash";

const EventBuild = (props) => {
  const [
    addOtherCost,
    addRecipe,
    addRecipeGroup,
    addRecipeProposalSection,
    addRecipeTemplatesToEvent,
    draggable,
    eventItemsBelowSuggested,
    eventLocked,
    getRecipeListItemByUuid,
    handleEventItemDragEnd,
    recipeListItems,
    recipesBelowSuggested,
    setDraggable,
  ] = useAccountState((state) => [
    state.addOtherCost,
    state.addRecipe,
    state.addRecipeGroup,
    state.addRecipeProposalSection,
    state.addRecipeTemplatesToEvent,
    state.draggable,
    state.eventItemsBelowSuggested,
    state.eventLocked,
    state.getRecipeListItemByUuid,
    state.handleEventItemDragEnd,
    state.recipeListItems,
    state.recipesBelowSuggested,
    state.setDraggable,
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [addingTemplate, setAddingTemplate] = React.useState(false);
  const [newTemplatesLoading, setNewTemplatesLoading] = React.useState(false);
  const toggleAddingTemplate = () => {
    setAddingTemplate(!addingTemplate);
  };
  const handleAddRecipeTemplates = (recipe_template_uuids) => {
    setNewTemplatesLoading(true);
    addRecipeTemplatesToEvent(
      recipe_template_uuids,
      undefined,
      undefined,
      () => {
        setNewTemplatesLoading(false);
      }
    );
    toggleAddingTemplate();
  };

  const handleDragEnd = (result) => {
    setDraggable(null);
    let error = handleEventItemDragEnd(result);
    if (error) {
      setDragDropError(error);
    }
  };

  const handleBeforeDragStart = (result) => {
    setDraggable(result.draggableId);
  };

  const [dragDropError, setDragDropError] = React.useState(undefined);

  const [underPricedDialogOpen, setUnderPricedDialogOpen] =
    React.useState(false);

  const toggleUnderPricedDialog = () => {
    setUnderPricedDialogOpen(!underPricedDialogOpen);
  };

  return (
    <Box sx={{ height: "calc(100vh - 225px)", overflow: "auto" }}>
      <Box sx={{ mb: "calc(100vh/2)" }}>
        <Grid
          container
          sx={{ width: "100%", maxWidth: "800px", m: "auto" }}
          spacing={1}
        >
          <Grid item xs={12} container justifyContent={"flex-end"} spacing={1}>
            <Grid item xs="auto">
              {newTemplatesLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={eventLocked()}
                  onClick={toggleAddingTemplate}
                  variant="outlined"
                  size="small"
                  color={"info"}
                >
                  <SearchOutlinedIcon /> Templates
                </Button>
              )}
              <RecipeTemplateSelector
                open={addingTemplate}
                handleCancel={toggleAddingTemplate}
                handleSubmit={handleAddRecipeTemplates}
              />
            </Grid>
            <Grid item xs="auto">
              <DropdownButton />
            </Grid>
          </Grid>
          {eventItemsBelowSuggested(true).length > 0 ? (
            <Grid item xs={12}>
              <Alert
                severity="error"
                id="recipes-below-suggested-price-alert"
                onClick={toggleUnderPricedDialog}
                sx={{ cursor: "pointer" }}
              >
                {eventItemsBelowSuggested(true).length}{" "}
                {eventItemsBelowSuggested(true).length > 1
                  ? "items are"
                  : "item is"}{" "}
                priced below their suggested retail price (click for more info).
              </Alert>
              <UnderPricedEventItemDialog
                open={underPricedDialogOpen}
                handleCancel={toggleUnderPricedDialog}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            {recipeListItems().length > 0 ? (
              <DragDropContext
                onDragEnd={handleDragEnd}
                // onDragStart={handleDragStart}
                onBeforeCapture={handleBeforeDragStart}
              >
                <Droppable
                  droppableId="event-items"
                  type="parent"
                  isCombineEnabled
                >
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <Box
                        sx={{
                          mb: "calc(100vh/4)",
                        }}
                      >
                        {recipeListItems()
                          .sort(sort_by_order)
                          .map((event_item, i) => {
                            return event_item.event_item_type ? (
                              <EventItem
                                key={event_item.uuid}
                                event_item={event_item}
                                handleTabSelect={props.handleTabSelect}
                                id={`event-item-${event_item.uuid}`}
                                index={i}
                              />
                            ) : (
                              <RecipeProposalSection
                                recipe_proposal_section={event_item}
                                index={i}
                                key={event_item.uuid}
                              />
                            );
                          })}
                        {provided.placeholder}
                        <EventItemDividerButtons />
                      </Box>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Box
                sx={{
                  borderStyle: "dotted",
                  borderColor: "info.light",
                  p: "2rem",
                  borderRadius: "7.5px",
                }}
              >
                <Typography
                  sx={{
                    color: "info.light",
                    fontWeight: "normal",
                  }}
                  variant="h6"
                >
                  No items have been added.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={dragDropError !== undefined}
        autoHideDuration={5000}
        onClose={() => setDragDropError(undefined)}
      >
        <Alert
          severity="warning"
          sx={{ width: "100%" }}
          onClose={() => setDragDropError(undefined)}
        >
          {dragDropError}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const DropdownButton = () => {
  const [
    addOtherCost,
    addRecipe,
    addRecipeGroup,
    addRecipeProposalSection,
    eventLocked,
    getFeatureFlagValue,
  ] = useAccountState((state) => [
    state.addOtherCost,
    state.addRecipe,
    state.addRecipeGroup,
    state.addRecipeProposalSection,
    state.eventLocked,
    state.getFeatureFlagValue,
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddRecipe = () => {
    addRecipe("New Recipe");
    handleClose();
  };

  const handleAddCost = () => {
    addOtherCost("New Other Cost");
    handleClose();
  };

  const handleAddCostPercent = () => {
    addOtherCost("New Other Cost", undefined, undefined, undefined, true);
    handleClose();
  };

  const handleAddRecipeProposalSection = () => {
    addRecipeProposalSection("New Proposal Section");
  };

  const handleAddRecipeGroup = () => {
    addRecipeGroup("New Recipe Group");
  };

  const [generatingRecipe, setGeneratingRecipe] = React.useState(false);
  const toggleGeneratingRecipe = () => {
    setGeneratingRecipe(!generatingRecipe);
  };

  const options = () => {
    let opts = [
      {
        label: "Recipe",
        action: handleAddRecipe,
        icon: LocalFloristOutlinedIcon,
      },
      {
        label: "Fixed Cost",
        action: handleAddCost,
        icon: AttachMoneyOutlinedIcon,
      },
      {
        label: "Percent Cost",
        action: handleAddCostPercent,
        icon: PercentOutlinedIcon,
      },
      {
        label: "Group",
        action: handleAddRecipeGroup,
        icon: ListAltOutlinedIcon,
      },
      {
        label: "Section",
        action: handleAddRecipeProposalSection,
        icon: SegmentOutlinedIcon,
      },
    ];

    if (getFeatureFlagValue("recipe_generator")) {
      opts.push({
        label: "Generate Recipe",
        action: toggleGeneratingRecipe,
        icon: AutoAwesomeOutlinedIcon,
      });
    }
    return opts;
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        size="small"
        color="secondary"
        disabled={eventLocked()}
      >
        <AddOutlinedIcon /> Add Item
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options().map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              option.action();
              handleClose();
            }}
          >
            <option.icon sx={{ mr: ".5rem" }} />
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      {generatingRecipe && (
        <GenerateRecipeDialog
          open={generatingRecipe}
          handleCancel={toggleGeneratingRecipe}
        />
      )}
    </>
  );
};

const UnderPricedEventItemDialog = (props) => {
  const [eventItemsBelowSuggested] = useAccountState((state) => [
    state.eventItemsBelowSuggested,
  ]);
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        {eventItemsBelowSuggested().length} items are priced below their
        suggested retail price.
      </DialogTitle>
      <DialogContent>
        <UnderPricedEventItemTable />
        <Alert severity="info" sx={{ mt: ".5rem" }}>
          Find items highlighted in red and click the{" "}
          <WarningAmberRoundedIcon color="error" fontSize="inherit" /> for more
          info.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const GenerateRecipeDialog = (props) => {
  const [addEventItem, addPaletteItem, event_version] = useAccountState(
    (state) => [state.addEventItem, state.addPaletteItem, state.event_version]
  );
  const [prompt, setPrompt] = React.useState("");
  const handleChange = (event) => {
    setPrompt(event.target.value);
  };

  const [draftRecipe, setDraftRecipe] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const handleGenerate = () => {
    setLoading(true);
    setError(false);
    generateEventItemApi(event_version.uuid, prompt)
      .then((response) => {
        setDraftRecipe(response.data);
        setLoading(false);
        setPrompt("");
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };

  const [addingFlowers, setAddingFlowers] = React.useState(false);
  const toggleAddingFlowers = () => {
    setAddingFlowers(!addingFlowers);
  };
  const [loadingAddFlowers, setLoadingAddFlowers] = React.useState(false);

  const addFlowers = (flowers) => {
    let new_flowers = flowers.map((flower) => {
      return { i: flower.uuid, q: 1, n: itemDisplayName(flower) };
    });
    let new_draft_recipe = {
      ...draftRecipe,
      flowers: [...draftRecipe.flowers, ...new_flowers],
    };
    setDraftRecipe(new_draft_recipe);
  };

  const handleAddFlowers = (flowers) => {
    let palette_item_uuids = event_version.palette_items.map(
      (palette_item) => palette_item.item.uuid
    );
    let palette_items_to_add = flowers.filter(
      (flower) => !palette_item_uuids.includes(flower.uuid)
    );
    if (palette_items_to_add.length > 0) {
      setLoadingAddFlowers(true);
      addPaletteItem(
        palette_items_to_add.map((flower) => flower.uuid),
        (palette_items) => {
          let flowers_to_add = flowers.map((flower) => {
            let palette_item = palette_items.find(
              (palette_item) =>
                palette_item.item.uuid === flower.uuid ||
                palette_item.item.core_item === flower.uuid
            );
            return palette_item.item;
          });
          addFlowers(flowers_to_add);
          setLoadingAddFlowers(false);
        }
      );
    } else {
      addFlowers(flowers);
    }
  };

  const itemsInDraftRecipe = () => {
    let item_uuids_in_draft = draftRecipe.flowers.map((flower) => flower.i);
    return event_version.palette_items
      .filter((palette_item) =>
        item_uuids_in_draft.includes(palette_item.item.uuid)
      )
      .map((palette_item) => palette_item.item);
  };

  const [submitting, setSubmitting] = React.useState(false);

  const handleSave = () => {
    setSubmitting(true);
    addEventItem(
      {
        name: draftRecipe.name,
        event_item_type: "RE",
        index: props.index,
        event_item_group: props.event_item_group,
        item_uuids: draftRecipe.flowers.map((flower) => flower.i),
        item_quantities: draftRecipe.flowers.reduce((acc, flower) => {
          acc[flower.i] = flower.q;
          return acc;
        }, {}),
        proposal_notes: draftRecipe.description,
      },
      () => {
        setSubmitting(false);
        props.handleCancel();
      }
    );
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Generate Recipe</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold" }}>Event Flowers</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                FlowerBuddy AI will create a recipe by selecting from your event
                flowers.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PaletteItemsList maxItems={6} />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold" }}>
                Recipe Generator
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                {draftRecipe
                  ? "List updates for FlowerBuddy AI to make to the draft recipe below."
                  : "Type a simple description of the recipe for FlowerBuddy AI to create."}
              </Typography>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  multiline
                  minRows={4}
                  value={prompt}
                  onChange={handleChange}
                  placeholder={
                    draftRecipe
                      ? "Use less greenery."
                      : "Bridal bouquet, loose, flowing, 15 to 20 stems"
                  }
                />
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    Error generating recipe. Try again.
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12} container justifyContent={"flex-end"}>
                <Grid item xs="auto">
                  <LoadingButton
                    onClick={handleGenerate}
                    color="secondary"
                    size="small"
                    variant="contained"
                    loading={loading}
                    disabled={
                      !prompt || event_version.palette_items.length === 0
                    }
                  >
                    <AutoAwesomeOutlinedIcon size="small" />{" "}
                    {draftRecipe ? "Refine" : "Generate"}
                  </LoadingButton>
                </Grid>
              </Grid>
              {draftRecipe && (
                <Grid item xs={12} container>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item xs="auto">
                      <Typography sx={{ fontWeight: "bold" }}>
                        Draft Recipe
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{ mb: "0.5rem" }}
                        loading={loadingAddFlowers}
                        onClick={toggleAddingFlowers}
                      >
                        Add Flowers to Draft
                      </LoadingButton>
                      <AddItemDialog
                        open={addingFlowers}
                        handleCancel={toggleAddingFlowers}
                        addItems={handleAddFlowers}
                        filter_items={itemsInDraftRecipe()}
                        item_type="FL"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{
                      border: "1px dashed",
                      borderColor: "info.light",
                      borderRadius: "5px",
                      p: "1rem",
                      maxHeight: "300px",
                      overflow: "auto",
                    }}
                  >
                    <Grid item xs={12}>
                      <DraftRecipeText
                        draftRecipe={draftRecipe}
                        setDraftRecipe={setDraftRecipe}
                        id="name"
                        label="Name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DraftRecipeText
                        draftRecipe={draftRecipe}
                        setDraftRecipe={setDraftRecipe}
                        id="description"
                        label="Description"
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12} container spacing={0.5}>
                      {sortDraftRecipeItemsByName(draftRecipe.flowers)?.map(
                        (flower) => (
                          <DraftRecipeFlower
                            key={flower.i}
                            flower={flower}
                            setDraftRecipe={setDraftRecipe}
                            draftRecipe={draftRecipe}
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCancel}
          color="info"
          variant="outlined"
          size="small"
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSave}
          color="secondary"
          variant="contained"
          size="small"
          disabled={!draftRecipe}
          loading={submitting}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const DraftRecipeText = (props) => {
  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState(props.draftRecipe[props.id]);
  React.useEffect(() => {
    setValue(props.draftRecipe[props.id]);
  }, [props.draftRecipe[props.id]]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    setEditing(false);
    props.setDraftRecipe({ ...props.draftRecipe, [props.id]: value });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{props.label}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item xs={11}>
          {editing ? (
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus
              multiline={props.multiline}
            />
          ) : (
            <Typography variant="caption">
              {props.draftRecipe[props.id]}
            </Typography>
          )}
        </Grid>
        <Grid item xs={"auto"}>
          <IconButton size="small" onClick={() => setEditing(!editing)}>
            <EditOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DraftRecipeFlower = (props) => {
  const [event_version] = useAccountState((state) => [state.event_version]);

  const getFlower = () => {
    return event_version.palette_items.find(
      (palette_item) => palette_item.item.uuid === props.flower.i
    )?.item;
  };

  const [flower, setFlower] = React.useState(getFlower());

  React.useEffect(() => {
    setFlower(getFlower());
  }, [event_version.palette_items]);

  const updateQuantity = (q) => {
    let flowers = props.draftRecipe.flowers;
    let index = flowers.findIndex((flower) => flower.i === props.flower.i);
    let new_flowers = [
      ...flowers.slice(0, index),
      { ...props.flower, q: q },
      ...flowers.slice(index + 1),
    ];
    props.setDraftRecipe({ ...props.draftRecipe, flowers: new_flowers });
  };

  const handleQuantityChange = (event) => {
    updateQuantity(event.target.value);
  };

  const increaseRecipeItemQuantity = () => {
    updateQuantity(props.flower.q + 1);
  };

  const decreaseRecipeItemQuantity = () => {
    if (props.flower.q > 0) {
      updateQuantity(props.flower.q - 1);
    }
  };

  const handleDelete = () => {
    let flowers = props.draftRecipe.flowers;
    let index = flowers.findIndex((flower) => flower.i === props.flower.i);
    let new_flowers = [...flowers.slice(0, index), ...flowers.slice(index + 1)];
    props.setDraftRecipe({ ...props.draftRecipe, flowers: new_flowers });
  };

  return (
    <Grid
      item
      xs={12}
      container
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        "&:hover": { backgroundColor: "info.light", borderRadius: "5px" },
      }}
    >
      <Grid item xs={8} container spacing={0.5} alignItems={"center"}>
        <Grid item xs="auto">
          {flower && <ItemIcon width={25} height={25} item={flower} />}
        </Grid>
        <Grid item xs={11}>
          <Typography variant="caption">{props.flower.n}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={4} container justifyContent={"flex-end"} spacing={1}>
        <Grid item xs={6}>
          <QuantityInput
            value={props.flower.q}
            onChange={handleQuantityChange}
            height={25}
            increase={increaseRecipeItemQuantity}
            decrease={decreaseRecipeItemQuantity}
          />
        </Grid>
        <Grid item xs="auto">
          <IconButton onClick={handleDelete} size="small">
            <DeleteOutlineOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(EventBuild);
