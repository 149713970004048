import debounce from "lodash.debounce";
import { unsecureAPI } from "../api";

export const AddPaletteItemApi = (item_uuid, event_version_uuid) => {
  return unsecureAPI.post(
    "api/palette_item/",
    { item_uuid, event_version_uuid },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const RemovePaletteItemApi = (palette_item_uuid) => {
  return unsecureAPI.delete(`api/palette_item/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: { palette_item_uuid },
  });
};

export const GeneratePaletteApi = (
  prompt,
  event_version_uuid,
  current_palette_item_uuids
) => {
  return unsecureAPI.post(
    "api/palette_item_ai/",
    { prompt, event_version_uuid, current_palette_item_uuids },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const UpdatePaletteApi = (item_uuids, event_version_uuid) => {
  return unsecureAPI.put(
    "api/palette_item/",
    { item_uuids, event_version_uuid },
    {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    }
  );
};
