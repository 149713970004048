import React from "react";
import { withRouter } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import itemCost, {
  flowerUnitOptions,
  itemDisplayName,
  MaterialSaleTypes,
  MaterialSourceTypes,
} from "../utils/item_utils.js";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Select,
  ButtonGroup,
  InputAdornment,
  Chip,
  Divider,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ItemPriceFields from "./ItemPriceFields.js";
import AddItemDialog from "./AddItemDialog.js";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { ItemIcon } from "./ItemIcon.js";
import { useAccountState } from "../state/store.js";
import { AddInventoryItemDialog } from "./InventoryItems.js";
import { NotInStockButton } from "./Build.js";
import { quantityField, sort_by_order } from "../utils/utils.js";
import { get, result } from "lodash";
import { LoadingButton } from "@mui/lab";
import { QuantityInput } from "./QuantityInput.js";
import { StackedItemName } from "./StackedItemName.js";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { QuantityInputMobile } from "./QuantityInputMobile.js";

const RecipeItems = (props) => {
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const item_name = props.item_type === "FL" ? "Flower" : "Hard Good";
  const [
    addRecipeItem,
    addRecipeItems,
    event_items,
    handleRecipeItemDragEnd,
    itemsInEvent,
  ] = useAccountState((state) => [
    state.addRecipeItem,
    state.addRecipeItems,
    state.event_items,
    state.handleRecipeItemDragEnd,
    state.itemsInEvent,
  ]);

  const [addingItem, setAddingItem] = React.useState(false);

  const toggleAddingItem = () => {
    setAddingItem(!addingItem);
  };

  const [loadingAddItem, setLoadingAddItem] = React.useState(false);

  const addItems = (items) => {
    setLoadingAddItem(true);
    addRecipeItems(
      props.recipe,
      items.map((item) => item.uuid),
      () => {
        setLoadingAddItem(false);
      }
    );
  };

  const _handleRecipeItemDragEnd = (result) => {
    handleRecipeItemDragEnd(result, props.recipe);
  };

  return (
    <Grid container justifyContent={"center"}>
      {!fullScreen && (
        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          columnSpacing={2}
        >
          <ColumnHeader width={4} value={""} />
          <ColumnHeader width={2} value={"qty."} />
          <ColumnHeader width={2} value={"cost / ea."} />
          <ColumnHeader width={2} value={"retail / ea."} />
          <ColumnHeader width={1} value={""} />
        </Grid>
      )}

      <Grid item xs={12}>
        <DragDropContext onDragEnd={_handleRecipeItemDragEnd}>
          <Droppable droppableId="recipe-items">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {props.items
                  .filter((ri) => ri.item.item_type == props.item_type)
                  .sort(sort_by_order)
                  .map((item, index) => (
                    <RecipeItem
                      key={item.uuid}
                      item={item}
                      recipe={props.recipe}
                      id={props.id + "-" + item.uuid}
                      index={index}
                      event_item={props.recipe}
                    />
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      <Grid item xs="auto">
        <LoadingButton
          onClick={toggleAddingItem}
          variant="outlined"
          color="secondary"
          size="small"
          startIcon={<AddOutlinedIcon />}
          sx={{ mt: "1rem" }}
          id="add-item-button"
          loading={loadingAddItem}
        >
          {`Add ${item_name}`}
        </LoadingButton>
      </Grid>

      {addingItem ? (
        <AddItemDialog
          open={addingItem}
          handleCancel={toggleAddingItem}
          addItems={addItems}
          filter_items={props.items.map((ri) => ri.item)}
          item_type={props.item_type}
          disableEventItems={props.recipe.is_template}
        />
      ) : null}
    </Grid>
  );
};

const ColumnHeader = (props) => {
  return (
    <Grid item xs={props.width} container>
      <Grid item xs="auto">
        <Typography variant="caption">{props.value}</Typography>
      </Grid>
    </Grid>
  );
};

const RecipeItem = (props) => {
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const isFlower = () => {
    return props.item.item.item_type === "FL";
  };
  const item_name = isFlower() ? "Flower" : "Hard Good";
  const [
    deleteRecipeItem,
    itemPrice,
    item_prices,
    formatCurrency,
    getEventItemByUuid,
    getInventoryItemForItem,
    itemLineCost,
    updateItemPrice,
    updateRecipeItem,
    updateRecipeItemOrder,
    user,
  ] = useAccountState((state) => [
    state.deleteRecipeItem,
    state.itemPrice,
    state.item_prices,
    state.formatCurrency,
    state.getEventItemByUuid,
    state.getInventoryItemForItem,
    state.itemLineCost,
    state.updateItemPrice,
    state.updateRecipeItem,
    state.updateRecipeItemOrder,
    state.user,
  ]);
  const getItemPrice = () => {
    if (props.recipe.is_template) {
      return props.recipe.template_item_prices.find(
        (tp) => tp.item === props.item.item.uuid
      );
    } else {
      return item_prices[props.item.item.uuid];
    }
  };

  const [editingItemCost, setEditingItemCost] = React.useState(false);

  const toggleEditingItemCost = () => {
    setEditingItemCost(!editingItemCost);
  };

  const [quantity, setQuantity] = React.useState(
    parseFloat(props.item.quantity).toFixed(2)
  );

  React.useEffect(() => {
    setQuantity(parseFloat(props.item.quantity).toFixed(2));
  }, [props.item.quantity]);

  const decreaseRecipeItemQuantity = () => {
    if (props.item.quantity > 1) {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        quantity: (parseFloat(props.item.quantity) - 1).toFixed(2),
      });
      setQuantity((parseFloat(props.item.quantity) - 1).toFixed(2));
    } else if (props.item.quantity > 0.1) {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        quantity: (parseFloat(props.item.quantity) - 0.1).toFixed(2),
      });
      setQuantity((parseFloat(props.item.quantity) - 0.1).toFixed(2));
    }
  };

  const increaseRecipeItemQuantity = () => {
    if (props.item.quantity >= 1) {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        quantity: (parseFloat(props.item.quantity) + 1).toFixed(2),
      });
      setQuantity((parseFloat(props.item.quantity) + 1).toFixed(2));
    } else {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        quantity: (parseFloat(props.item.quantity) + 0.1).toFixed(2),
      });
      setQuantity((parseFloat(props.item.quantity) + 0.1).toFixed(2));
    }
  };

  const removeItem = () => {
    deleteRecipeItem(props.recipe, props.item.uuid);
  };

  const updateItemCost = (data) => {
    let item_price_data = {
      ...getItemPrice(),
      ...data,
    };
    updateItemPrice(item_price_data, () => {
      setEditingItemCost(false);
    });
  };

  const updateRecipeItemQuantity = (e) => {
    if (e.target.value !== "" && e.target.value != ".") {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        quantity: parseFloat(e.target.value).toFixed(2),
      });
    }
  };

  const updateRecipeItemSaleType = (e) => {
    if (e.target.value !== "") {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        item_sale_type: e.target.value,
      });
    }
  };

  const updateRecipeItemSourceType = (e) => {
    if (e.target.value !== "") {
      updateRecipeItem(props.recipe, {
        uuid: props.item.uuid,
        item_source_type: e.target.value,
      });
    }
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleQuantityBlur = (e) => {
    try {
      if (parseFloat(e.target.value) > 0) {
        updateRecipeItemQuantity(e);
      } else {
        setQuantity(0);
        updateRecipeItemQuantity({ target: { value: 0 } });
      }
    } catch {
      console.log(e.target.value);
    }
  };

  const [addingInventoryItem, setAddingInventoryItem] = React.useState(false);

  return (
    <Draggable
      draggableId={`${props.recipe?.uuid}:${props.item.uuid}`}
      index={props.index}
    >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          {!fullScreen && (
            <Grid
              container
              sx={{ mt: ".25rem" }}
              alignItems="center"
              columnSpacing={2}
              justifyContent={"space-between"}
            >
              <Grid
                item
                xs={4}
                container
                alignItems={"center"}
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item xs={1}>
                  <div {...provided.dragHandleProps}>
                    <DragIndicatorIcon sx={{ color: "info.light", p: 0 }} />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <ItemIcon item={props.item.item} width={40} height={40} />
                </Grid>
                <Grid item xs={8}>
                  <StackedItemName item={props.item.item} />
                </Grid>
              </Grid>

              <Grid item xs={2} container alignItems="center" spacing={1}>
                <Grid item xs={9}>
                  <QuantityInput
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    height={30}
                    increase={increaseRecipeItemQuantity}
                    decrease={decreaseRecipeItemQuantity}
                  />
                </Grid>
                {isFlower() && (
                  <Grid item xs={3}>
                    <Tooltip
                      title={
                        flowerUnitOptions[props.item.item.unit_name].plural
                      }
                    >
                      <Box
                        sx={{
                          border: "1px solid",
                          borderColor: "lightgray",
                          backgroundColor: "lightgray",
                          borderRadius: "50%",
                          display: "flex",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            m: "auto",
                            color: "info.main",
                            fontSize: ".65rem",
                          }}
                        >
                          {props.item.item.unit_name}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Grid>
                )}
                {!isFlower() && (
                  <Grid item xs={3}>
                    {props.item.item_source_type === "IN" && (
                      <>
                        {!getInventoryItemForItem(props.item.item) &&
                          user.inventory_enabled &&
                          !props.event_item.is_template && (
                            <>
                              <Tooltip title="Item not in inventory. Click to add it.">
                                <IconButton
                                  size="small"
                                  onClick={() => setAddingInventoryItem(true)}
                                >
                                  <WarningAmberRoundedIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                              <AddInventoryItemDialog
                                open={addingInventoryItem}
                                handleCancel={() =>
                                  setAddingInventoryItem(false)
                                }
                                item={props.item.item}
                              />
                            </>
                          )}
                        {/* TODO: fix this. This warning starts to show up if you have things for sale, and have sold them, */}
                        {getInventoryItemForItem(props.item.item) &&
                          user.inventory_enabled &&
                          !props.event_item.is_template &&
                          getInventoryItemForItem(props.item.item)
                            .quantity_total <
                            props.item.quantity *
                              quantityField(props.recipe) && (
                            <>
                              <NotInStockButton
                                inventory_item={getInventoryItemForItem(
                                  props.item.item
                                )}
                                num_additional_needed={
                                  props.item.quantity *
                                    quantityField(props.recipe) -
                                  getInventoryItemForItem(props.item.item)
                                    .quantity_total
                                }
                              />
                            </>
                          )}
                      </>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid item xs={2} container justifyContent={"space-between"}>
                <Grid item xs="auto">
                  <Typography
                    sx={{ textAlign: "center", ml: "auto", mr: "auto" }}
                  >
                    {formatCurrency(itemCost(getItemPrice()))}
                    <Tooltip
                      title={
                        itemCost(getItemPrice()).eq(0)
                          ? "No cost is set for this item. Click to set a cost."
                          : "Edit the cost of this item."
                      }
                    >
                      <IconButton
                        onClick={toggleEditingItemCost}
                        size="small"
                        sx={{ mb: ".25rem" }}
                        id={props.id + "-edit-cost-button"}
                      >
                        {itemCost(getItemPrice()).eq(0) ? (
                          <WarningAmberRoundedIcon
                            fontSize="inherit"
                            sx={{ color: "error.main" }}
                          />
                        ) : (
                          <EditOutlinedIcon fontSize="inherit" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                {!isFlower() && (
                  <Grid item xs="auto">
                    <Tooltip
                      title="Specify where you are sourcing this item from (inventory, purchasing for this event, renting for this event)."
                      placement="top"
                    >
                      <Select
                        name="type"
                        label="Type"
                        id={props.id + "-source-type-select"}
                        value={props.item.item_source_type}
                        renderValue={(value) => value}
                        onChange={updateRecipeItemSourceType}
                        variant="standard"
                        sx={{
                          fontSize: ".5rem",
                          "& .MuiSelect-select": {
                            padding: 0,
                            paddingRight: "8px !important",
                          },
                        }}
                        IconComponent={SmallIcon}
                      >
                        <MenuItem value="IN" sx={{ color: "secondary.main" }}>
                          Inventory
                        </MenuItem>
                        <MenuItem value="PU" sx={{ color: "secondary.main" }}>
                          Purchase
                        </MenuItem>
                        <MenuItem value="RE" sx={{ color: "secondary.main" }}>
                          Rent
                        </MenuItem>
                      </Select>
                    </Tooltip>
                  </Grid>
                )}

                {editingItemCost ? (
                  <EditItemCostDialog
                    item={props.item}
                    item_price={getItemPrice()}
                    open={editingItemCost}
                    handleCancel={toggleEditingItemCost}
                    handleSubmit={updateItemCost}
                  />
                ) : null}
              </Grid>
              <Grid item xs={2} container justifyContent={"space-between"}>
                <Grid item xs="auto">
                  <Typography>
                    {formatCurrency(itemPrice(props.item))}
                  </Typography>
                </Grid>
                {!isFlower() && (
                  <Grid item xs="auto">
                    <Tooltip
                      title="Specify if you are renting or selling this item to your client."
                      placement="top"
                    >
                      <Select
                        name="type"
                        label="Type"
                        id={props.id + "-sale-type-select"}
                        value={props.item.item_sale_type}
                        renderValue={(value) => value}
                        onChange={updateRecipeItemSaleType}
                        variant="standard"
                        sx={{
                          fontSize: ".5rem",
                          "& .MuiSelect-select": {
                            padding: 0,
                            paddingRight: "8px !important",
                          },
                        }}
                        IconComponent={SmallIcon}
                      >
                        <MenuItem value="RE" sx={{ color: "secondary.main" }}>
                          Rent
                        </MenuItem>
                        <MenuItem value="SE" sx={{ color: "secondary.main" }}>
                          Sell
                        </MenuItem>
                      </Select>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={1} container>
                <Typography
                  sx={{
                    textAlign: "right",
                    ml: "auto",
                    // mr: "auto",
                    display: "flex",
                  }}
                >
                  <IconButton
                    onClick={removeItem}
                    size="small"
                    id={props.id + "-delete-button"}
                  >
                    <DeleteOutlineOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Typography>
              </Grid>
            </Grid>
          )}
          {fullScreen && (
            <Grid
              container
              sx={{ mt: ".25rem" }}
              alignItems="center"
              columnSpacing={2}
              justifyContent={"space-between"}
            >
              <Grid
                item
                xs={12}
                container
                alignItems={"center"}
                // justifyContent="space-between"
                spacing={1}
              >
                <Grid item xs={1}>
                  <div {...provided.dragHandleProps}>
                    <DragIndicatorIcon
                      sx={{ color: "info.light", p: 0, fontSize: "1rem" }}
                    />
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <StackedItemName item={props.item.item} />
                </Grid>
                <Grid item xs={4}>
                  <QuantityInputMobile
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={handleQuantityBlur}
                    height={30}
                    increase={increaseRecipeItemQuantity}
                    decrease={decreaseRecipeItemQuantity}
                  />
                </Grid>
                {isFlower() && (
                  <Grid item xs={1}>
                    <Tooltip
                      title={
                        flowerUnitOptions[props.item.item.unit_name].plural
                      }
                    >
                      <Box
                        sx={{
                          border: "1px solid",
                          borderColor: "lightgray",
                          backgroundColor: "lightgray",
                          borderRadius: "50%",
                          display: "flex",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            m: "auto",
                            color: "info.main",
                            fontSize: ".65rem",
                          }}
                        >
                          {props.item.item.unit_name}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Grid>
                )}
                {!isFlower() && (
                  <Grid item xs={1}>
                    {props.item.item_source_type === "IN" && (
                      <>
                        {!getInventoryItemForItem(props.item.item) &&
                          user.inventory_enabled &&
                          !props.event_item.is_template && (
                            <>
                              <Tooltip title="Item not in inventory. Click to add it.">
                                <IconButton
                                  size="small"
                                  onClick={() => setAddingInventoryItem(true)}
                                >
                                  <WarningAmberRoundedIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                              <AddInventoryItemDialog
                                open={addingInventoryItem}
                                handleCancel={() =>
                                  setAddingInventoryItem(false)
                                }
                                item={props.item.item}
                              />
                            </>
                          )}

                        {getInventoryItemForItem(props.item.item) &&
                          user.inventory_enabled &&
                          !props.event_item.is_template &&
                          getInventoryItemForItem(props.item.item)
                            .quantity_total <
                            props.item.quantity *
                              quantityField(props.recipe) && (
                            <>
                              <NotInStockButton
                                inventory_item={getInventoryItemForItem(
                                  props.item.item
                                )}
                                num_additional_needed={
                                  props.item.quantity *
                                    quantityField(props.recipe) -
                                  getInventoryItemForItem(props.item.item)
                                    .quantity_total
                                }
                              />
                            </>
                          )}
                      </>
                    )}
                  </Grid>
                )}
                <Grid item xs={1} container>
                  <Typography
                    sx={{
                      textAlign: "right",
                      ml: "auto",
                      // mr: "auto",
                      display: "flex",
                    }}
                  >
                    <IconButton
                      onClick={removeItem}
                      size="small"
                      id={props.id + "-delete-button"}
                    >
                      <DeleteOutlineOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
              {itemCost(getItemPrice()).eq(0) && (
                <Grid item xs={12} container>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={toggleEditingItemCost}
                      size="small"
                      id={props.id + "-edit-cost-button"}
                      sx={{ p: 0 }}
                    >
                      {itemCost(getItemPrice()).eq(0) ? (
                        <WarningAmberRoundedIcon
                          fontSize="inherit"
                          sx={{ color: "error.main" }}
                        />
                      ) : (
                        <EditOutlinedIcon fontSize="inherit" />
                      )}
                    </IconButton>
                  </Grid>

                  <Grid item xs={11}>
                    <Typography
                      variant="caption"
                      sx={{ color: "text.secondary" }}
                      onClick={toggleEditingItemCost}
                    >
                      Above item has no cost. Click to add.
                    </Typography>
                  </Grid>
                  {editingItemCost && (
                    <EditItemCostDialog
                      item={props.item}
                      item_price={getItemPrice()}
                      open={editingItemCost}
                      handleCancel={toggleEditingItemCost}
                      handleSubmit={updateItemCost}
                    />
                  )}
                </Grid>
              )}

              {/* <Grid
                item
                xs={12}
                container
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={1}
              >
                <Grid item xs={5} container alignItems="center" spacing={1}>
                  <Grid item xs={9}>
                    <QuantityInputMobile
                      value={quantity}
                      onChange={handleQuantityChange}
                      onBlur={handleQuantityBlur}
                      height={30}
                      increase={increaseRecipeItemQuantity}
                      decrease={decreaseRecipeItemQuantity}
                    />
                  </Grid>
                  {isFlower() && (
                    <Grid item xs={3}>
                      <Tooltip
                        title={
                          flowerUnitOptions[props.item.item.unit_name].plural
                        }
                      >
                        <Box
                          sx={{
                            border: "1px solid",
                            borderColor: "lightgray",
                            backgroundColor: "lightgray",
                            borderRadius: "50%",
                            display: "flex",
                            width: "20px",
                            height: "20px",
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              m: "auto",
                              color: "info.main",
                              fontSize: ".65rem",
                            }}
                          >
                            {props.item.item.unit_name}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Grid>
                  )}
                  {!isFlower() && (
                    <Grid item xs={3}>
                      {props.item.item_source_type === "IN" && (
                        <>
                          {!getInventoryItemForItem(props.item.item) &&
                            user.inventory_enabled &&
                            !props.event_item.is_template && (
                              <>
                                <Tooltip title="Item not in inventory. Click to add it.">
                                  <IconButton
                                    size="small"
                                    onClick={() => setAddingInventoryItem(true)}
                                  >
                                    <WarningAmberRoundedIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                                <AddInventoryItemDialog
                                  open={addingInventoryItem}
                                  handleCancel={() =>
                                    setAddingInventoryItem(false)
                                  }
                                  item={props.item.item}
                                />
                              </>
                            )}
                          
                          {getInventoryItemForItem(props.item.item) &&
                            user.inventory_enabled &&
                            !props.event_item.is_template &&
                            getInventoryItemForItem(props.item.item)
                              .quantity_total <
                              props.item.quantity *
                                quantityField(props.recipe) && (
                              <>
                                <NotInStockButton
                                  inventory_item={getInventoryItemForItem(
                                    props.item.item
                                  )}
                                  num_additional_needed={
                                    props.item.quantity *
                                      quantityField(props.recipe) -
                                    getInventoryItemForItem(props.item.item)
                                      .quantity_total
                                  }
                                />
                              </>
                            )}
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item xs="auto">
                    <Typography
                      sx={{ textAlign: "center", ml: "auto", mr: "auto" }}
                    >
                      {formatCurrency(itemCost(getItemPrice()))}
                      <Tooltip
                        title={
                          itemCost(getItemPrice()).eq(0)
                            ? "No cost is set for this item. Click to set a cost."
                            : "Edit the cost of this item."
                        }
                      >
                        <IconButton
                          onClick={toggleEditingItemCost}
                          size="small"
                          id={props.id + "-edit-cost-button"}
                          sx={{ p: 0 }}
                        >
                          {itemCost(getItemPrice()).eq(0) ? (
                            <WarningAmberRoundedIcon
                              fontSize="inherit"
                              sx={{ color: "error.main" }}
                            />
                          ) : (
                            <EditOutlinedIcon fontSize="inherit" />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>

                  {!isFlower() && (
                    <Grid item xs="auto">
                      <Tooltip
                        title="Specify if you are renting or selling this item to your client."
                        placement="top"
                      >
                        <Select
                          name="type"
                          label="Type"
                          id={props.id + "-sale-type-select"}
                          value={props.item.item_sale_type}
                          renderValue={(value) => value}
                          onChange={updateRecipeItemSaleType}
                          variant="standard"
                          sx={{
                            fontSize: ".5rem",
                            "& .MuiSelect-select": {
                              padding: 0,
                              paddingRight: "0 !important",
                            },
                          }}
                          IconComponent={SmallIcon}
                        >
                          <MenuItem value="RE" sx={{ color: "secondary.main" }}>
                            Rent
                          </MenuItem>
                          <MenuItem value="SE" sx={{ color: "secondary.main" }}>
                            Sell
                          </MenuItem>
                        </Select>
                      </Tooltip>
                    </Grid>
                  )}

                  {editingItemCost ? (
                    <EditItemCostDialog
                      item={props.item}
                      item_price={getItemPrice()}
                      open={editingItemCost}
                      handleCancel={toggleEditingItemCost}
                      handleSubmit={updateItemCost}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={1} container justifyContent={"space-between"}>
                  {!isFlower() && (
                    <Grid item xs="auto">
                      <Tooltip
                        title="Specify where you are sourcing this item from (inventory, purchasing for this event, renting for this event)."
                        placement="top"
                      >
                        <Select
                          name="type"
                          label="Type"
                          id={props.id + "-source-type-select"}
                          value={props.item.item_source_type}
                          renderValue={(value) => value}
                          onChange={updateRecipeItemSourceType}
                          variant="standard"
                          sx={{
                            fontSize: ".5rem",
                            "& .MuiSelect-select": {
                              padding: 0,
                              paddingRight: "0 !important",
                            },
                          }}
                          IconComponent={SmallIcon}
                        >
                          <MenuItem value="IN" sx={{ color: "secondary.main" }}>
                            Inventory
                          </MenuItem>
                          <MenuItem value="PU" sx={{ color: "secondary.main" }}>
                            Purchase
                          </MenuItem>
                          <MenuItem value="RE" sx={{ color: "secondary.main" }}>
                            Rent
                          </MenuItem>
                        </Select>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={1} container>
                  <Typography
                    sx={{
                      textAlign: "right",
                      ml: "auto",
                      // mr: "auto",
                      display: "flex",
                    }}
                  >
                    <IconButton
                      onClick={removeItem}
                      size="small"
                      id={props.id + "-delete-button"}
                    >
                      <DeleteOutlineOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
          )}
        </div>
      )}
    </Draggable>
  );
};

const SmallIcon = (props) => (
  <ArrowDropDownIcon {...props} style={{ fontSize: 16 }} /> // Adjust the size here
);

export const EditItemCostDialog = (props) => {
  const item_name = props.item.item.item_type === "FL" ? "Flower" : "Hard Good";
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const formik = useFormik({
    initialValues: {
      group_name: props.item_price.group_name,
      individual_per_group: props.item_price.individual_per_group,
      price_per_group: props.item_price.price_per_group,
      minimum_group_quantity: props.item_price.minimum_group_quantity,
      online_only: false,
      vendor_location: props.item_price.vendor_location,
      update_all_events: true,
    },
    onSubmit: (values) => {
      props.handleCancel();
      props.handleSubmit(values);
      formik.resetForm();
    },
    enableReinitialize: true,
  });

  return (
    <Dialog
      onClose={props.handleCancel}
      open={props.open}
      fullScreen={fullScreen}
    >
      <Box sx={fullScreen ? null : { width: "350px" }}>
        <DialogTitle style={{ textAlign: "center" }}>
          Edit Cost of {itemDisplayName(props.item.item)}
        </DialogTitle>
        <DialogContent>
          <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
            <Box sx={{ width: "100%" }}>
              <ItemPriceFields
                item={props.item}
                item_price={props.item_price}
                formik={formik}
                updatingVendor={props.updatingVendor}
              />
              {!props.item_price.event_item &&
                !props.disableUpdateAllEvents && (
                  <Tooltip
                    title={`Check this box to update the cost of this ${item_name.toLocaleLowerCase()} for all active events. Uncheck this box to update the cost for this event only.`}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={formik.values.update_all_events}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "update_all_events",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Update For All Events"
                    />
                  </Tooltip>
                )}
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleCancel}
            size="small"
            sx={{ ml: "auto" }}
            variant="outlined"
            color="info"
            id="cancel-edit-item-cost-button"
          >
            Discard
          </Button>
          <Button
            onClick={formik.handleSubmit}
            size="small"
            variant="contained"
            color="secondary"
            sx={{ ml: "auto" }}
            id="submit-edit-item-cost-button"
          >
            Update
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default withRouter(RecipeItems);
