import React from "react";
import { withRouter } from "react-router-dom";

import axios from "axios";

import { useAccountState } from "../state/store.js";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { sort } from "fast-sort";

import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  IconButton,
  Snackbar,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Autocomplete,
  Link,
  Tooltip,
  FormControlLabel,
  Switch,
  TableHead,
  Table,
  TableRow,
  TableCell,
  Chip,
  Alert,
} from "@mui/material";

import { CopyToClipboard } from "react-copy-to-clipboard";

import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { eventDateFormatted } from "../utils/event_utils";
import BackOfficeSettings, {
  round_suggested_prices_options,
} from "./BackOfficeSettings";
import Contract from "./Contract.js";
import AutoEmailSettings from "./AutoEmailSettings";
import { TimezonePicker } from "./TimezonePicker";
import ViewSettings from "./ViewSettings";
import {
  formatDate,
  orderByField,
  sort_by_order,
  sortByKey,
  sortByName,
  toProperCase,
} from "../utils/utils.js";
import AmendmentBadge from "./AmendmentBadge.js";
import moment from "moment-timezone";
import AddressField from "./AdressField.js";
import {
  locationAddress,
  locationNameParts,
  locationOptionName,
} from "../utils/location_utils.js";
import { RichTextFieldEditable } from "./RichTextField.js";
import { PDFUploader } from "./FileUploader.js";
import Tags from "./Tags.js";
import { useLocaleContext } from "./locale.js";
import { documentIsSigned, getSignerInfo } from "../utils/document_utils.js";
import { EmbeddedSignDialog } from "./Sign.js";
import { ExpandablePreview } from "./ExpandablePreview.js";
import PaymentScheduleEditor, {
  PaymentScheduleSettings,
  PaymentSchedule,
} from "./PaymentSchedule.js";
import { PaletteItemsList } from "./PaletteItems.js";

const EventDetails = (props) => {
  const [
    back_office,
    contract_sections,
    contractIsEnabled,
    event,
    eventDateAmended,
    is_template,
    name,
    notes,
    notes_rich_text,
    paymentScheduleErrors,
    updateBackOffice,
    updateEvent,
    unsignedDocumentExists,
  ] = useAccountState((state) => [
    state.back_office,
    state.contract_sections,
    state.contractIsEnabled,
    state.event,
    state.eventDateAmended,
    state.event.is_template,
    state.event.name,
    state.event.notes,
    state.event.notes_rich_text,
    state.paymentScheduleErrors,
    state.updateBackOffice,
    state.updateEvent,
    state.unsignedDocumentExists,
  ]);

  const [_name, _setName] = React.useState(name);

  React.useEffect(() => {
    _setName(name);
  }, [name]);

  return (
    <Box
      sx={{
        height: "calc(100vh - 225px)",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          maxWidth: "800px",
          mt: "1rem",
          ml: "auto",
          mr: "auto",
          mb: "calc(100vh/2)",
        }}
      >
        <Grid container spacing={2}>
          <EventDetailsSection
            title="Key Event Data"
            id="key-event-data"
            showBadge={eventDateAmended()}
            badgeContent={"1"}
            badgeSeverity="error"
          >
            <Details />
          </EventDetailsSection>
          {!event.is_template && (
            <EventDetailsSection title="Contact Info" id="contact-info">
              <Grid container spacing={2}>
                <Grid item xs={12} container>
                  <EventDetailsContentHeader>Clients</EventDetailsContentHeader>
                  <Clients />
                  <Grid item xs={12}>
                    <AddClientButton />
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <EventDetailsContentHeader>
                    Professional Contacts
                  </EventDetailsContentHeader>
                  <ProfessionalContacts />
                  <Grid item xs={12}>
                    <AddProfessionalContactButton />
                  </Grid>
                </Grid>
              </Grid>
            </EventDetailsSection>
          )}

          <EventDetailsSection
            title="Notes"
            id="notes"
            fullWidth={!event.is_template}
          >
            <EventDetailsContentData>
              <NotesField />
            </EventDetailsContentData>
          </EventDetailsSection>
          <EventDetailsSection
            title="Event Flowers"
            id="event-flowers"
            fullWidth
          >
            <PaletteItemsList />
          </EventDetailsSection>
          {!event.is_template && (
            <EventDetailsSection
              title="Timeline"
              id="timeline"
              defaultCollapsed
            >
              <EventLocations />
            </EventDetailsSection>
          )}
          {!event.is_template && (
            <EventDetailsSection
              title="Files"
              id="files"
              defaultCollapsed
              showBadge={unsignedDocumentExists()}
              badgeContent={"1"}
              badgeSeverity="warning"
            >
              <Files />
            </EventDetailsSection>
          )}

          <EventDetailsSection
            title="Payments"
            id="payments"
            fullWidth
            showBadge={paymentScheduleErrors(true).length > 0}
            badgeContent={paymentScheduleErrors(true).length}
            badgeSeverity="warning"
          >
            <Payments />
          </EventDetailsSection>

          <EventDetailsSection
            title="Contract"
            id="contract"
            fullWidth
            defaultCollapsed
            showBadge={contract_sections.length === 0 || !contractIsEnabled()}
            badgeContent={"1"}
            badgeSeverity="error"
          >
            <Grid item xs={12}>
              <EventDetailsContentData>
                <ExpandablePreview previewHeight={200}>
                  <Contract editable />
                </ExpandablePreview>
              </EventDetailsContentData>
            </Grid>
          </EventDetailsSection>

          <EventDetailsSection title="Markups" id="markups" defaultCollapsed>
            <BackOfficeSettingsView />
          </EventDetailsSection>
          <EventDetailsSection title="Settings" id="settings" defaultCollapsed>
            <OtherSettingsView />
          </EventDetailsSection>
        </Grid>
      </Box>
    </Box>
  );
};

const EventDetailsGridRow = (props) => {
  return (
    <Grid item xs={12} container spacing={2}>
      {props.children}
    </Grid>
  );
};

const EventDetailsSection = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = React.useState(fullScreen ? false : true);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  return (
    <Grid item xs={props.fullWidth || fullScreen ? 12 : 6}>
      <Accordion
        expanded={expanded}
        onChange={fullScreen ? toggleExpanded : undefined}
        disableGutters
        square
        id={props.id}
        sx={{
          border: "1px solid lightgrey",
          borderRadius: "5px",
          height: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownOutlinedIcon />}
          aria-controls={"panel" + props.id + "-content"}
          id={"panel" + props.id + "-header"}
          sx={{
            backgroundColor: "#f7f5f5",
            minHeight: 0,
            "& .MuiAccordionSummary-content": { mt: ".25rem", mb: ".25rem" },
          }}
        >
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item xs="auto">
              <Typography variant="h6">{props.title}</Typography>
            </Grid>
            {props.showBadge ? (
              <Grid item xs="auto">
                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                  }}
                >
                  <Typography
                    textAlign={"center"}
                    sx={{
                      fontSize: ".75rem",
                      color: props.badgeContent > 0 ? "white" : "error.main",
                      backgroundColor:
                        props.badgeSeverity === "error"
                          ? "error.main"
                          : "warning.main",
                      borderRadius: "50%",
                    }}
                  >
                    {props.badgeContent}
                  </Typography>
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const EventDetailsContentHeader = (props) => {
  return <Typography sx={{ fontWeight: "bold" }}>{props.children}</Typography>;
};

const EventDetailsContentData = (props) => {
  const children = React.Children.toArray(props.children);
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ ml: ".5rem" }}
    >
      {children && (
        <Grid item xs={children?.length > 1 ? 13 - children?.length : 12}>
          <Typography sx={{ color: "text.secondary", fontSize: ".9rem" }}>
            {children[0]}
          </Typography>
        </Grid>
      )}
      {children?.length > 1 && (
        <Grid
          item
          xs={children?.length - 1}
          container
          justifyContent={"flex-end"}
        >
          {children.slice(1).map((child) => (
            <Grid item xs="auto">
              {child}
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

const AddEventDetailsContentButton = (props) => {
  return (
    <EventDetailsContentButton
      onClick={props.onClick}
      label={props.label}
      Icon={AddOutlinedIcon}
    />
  );
};

const EditEventDetailsContentButton = (props) => {
  return (
    <EventDetailsContentButton
      onClick={props.onClick}
      label={props.label}
      Icon={EditOutlinedIcon}
    />
  );
};

const EventDetailsContentButton = ({ onClick, label, Icon }) => {
  const [eventLocked] = useAccountState((state) => [state.eventLocked]);
  return (
    <Button onClick={onClick} size="small" disabled={eventLocked()}>
      <Icon fontSize="small" /> {label}
    </Button>
  );
};

const Details = (props) => {
  const [
    back_office,
    event,
    updateEvent,
    uploadEventImage,
    resetEventDate,
    eventDateFormatted,
    eventDateAmended,
  ] = useAccountState((state) => [
    state.back_office,
    state.event,
    state.updateEvent,
    state.uploadEventImage,
    state.resetEventDate,
    state.eventDateFormatted,
    state.eventDateAmended,
  ]);
  const [editing_key_date, setEditingKeyDate] = React.useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EventDetailsContentHeader>Name</EventDetailsContentHeader>
        <EventDetailsContentData>
          <>{event.name}</>
          <EditEventDetailsButton title="Edit Name">
            <DetailTextField
              id="name"
              event={event}
              updateEvent={updateEvent}
            />
          </EditEventDetailsButton>
        </EventDetailsContentData>
      </Grid>
      <Grid item xs={12}>
        <EventDetailsContentHeader>Date</EventDetailsContentHeader>
        <EventDetailsContentData>
          <>
            <Typography fontSize={"inherit"}>
              <AmendmentBadge
                tooltip={"Contracted Date: " + eventDateFormatted(true)}
                invisible={!eventDateAmended()}
              >
                <>{`${eventDateFormatted()}`}</>
              </AmendmentBadge>
              {eventDateAmended() ? (
                <Tooltip title={"Contracted Date: " + eventDateFormatted(true)}>
                  <IconButton
                    size="small"
                    disabled={event.locked}
                    onClick={resetEventDate}
                    id="reset-event-date-button"
                  >
                    <RestartAltOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Typography>
            <Typography fontSize={"inherit"}>
              Timezone: {back_office.timezone}
            </Typography>
          </>
          <IconButton
            size="small"
            onClick={() => setEditingKeyDate(true)}
            disabled={event.locked}
            id="edit-event-date-button"
          >
            <EditOutlinedIcon fontSize="inherit" />
          </IconButton>
        </EventDetailsContentData>
        <EditDateDialog
          title="Edit Key Date"
          open={editing_key_date}
          handleCancel={() => setEditingKeyDate(false)}
          handleSubmit={(data) => updateEvent({ uuid: event.uuid, ...data })}
          initialValues={{ date: event.date }}
          back_office={back_office}
          id="edit-key-date-dialog"
        />
      </Grid>

      <Grid item xs={12}>
        <EventDetailsContentHeader>Tags</EventDetailsContentHeader>
        <Tags event={event} disableColorTags />
      </Grid>
    </Grid>
  );
};

const EventDetailsDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={props.handleCancel}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditEventDetailsButton = (props) => {
  const [eventLocked] = useAccountState((state) => [state.eventLocked]);
  const [editing, setEditing] = React.useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };
  return (
    <>
      <IconButton
        size="small"
        color="info"
        onClick={toggleEditing}
        disabled={eventLocked()}
      >
        <EditOutlinedIcon fontSize="inherit" />
      </IconButton>
      <EventDetailsDialog
        open={editing}
        handleCancel={toggleEditing}
        title={props.title}
      >
        {props.children}
      </EventDetailsDialog>
    </>
  );
};

const NotesField = (props) => {
  const [event, updateEvent] = useAccountState((state) => [
    state.event,
    state.updateEvent,
  ]);

  const [editing, setEditing] = React.useState(false);

  return (
    <ExpandablePreview previewHeight={200} overrideExpanded={editing}>
      <RichTextFieldEditable
        value={event.notes_rich_text}
        updateValue={(value) => {
          updateEvent({ notes_rich_text: value });
        }}
        placeholder="Event notes..."
        autoFocus
        hideEditIcon
        stopEditingOnBlur
        setEditing={setEditing}
      />
    </ExpandablePreview>
  );
};

const DetailTextField = (props) => {
  const [event, updateEvent] = useAccountState((state) => [
    state.event,
    state.updateEvent,
  ]);
  const [value, setValue] = React.useState(event[props.id]);
  React.useEffect(() => {
    setValue(event[props.id]);
  }, [event[props.id]]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    updateEvent({
      uuid: event.uuid,
      [props.id]: value,
    });
  };

  return (
    <TextField
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      multiline={props.multiline}
      rows={props.rows}
      fullWidth
      id={props.id + "-textfield"}
    />
  );
};

const Clients = (props) => {
  const [clients, deleteClient, updateClient] = useAccountState((state) => [
    state.clients,
    state.deleteClient,
    state.updateClient,
  ]);

  return (
    <Contacts
      contacts={clients}
      deleteContact={deleteClient}
      updateContact={updateClient}
    />
  );
};

const ProfessionalContacts = (props) => {
  const [event, updateEventContact, removeEventContact] = useAccountState(
    (state) => [state.event, state.updateEventContact, state.removeEventContact]
  );

  return (
    <Contacts
      contacts={event.event_contacts}
      updateContact={updateEventContact}
      deleteContact={removeEventContact}
      url
      company
      warning="Editing this contact will update all events containing this contact."
    />
  );
};

const Contacts = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return props.contacts
    .sort(sortByName)
    .map((c, ind) => (
      <Contact
        contact={c}
        key={c.uuid}
        deleteContact={props.deleteContact}
        updateContact={props.updateContact}
        url={props.url}
        company={props.company}
        warning={props.warning}
      />
    ));
};

const Contact = (props) => {
  const [eventLocked] = useAccountState((state) => [state.eventLocked]);
  const [editingContact, setEditingContact] = React.useState(false);
  const toggleEditingContact = () => {
    setEditingContact(!editingContact);
  };
  const [deletingContact, setDeletingContact] = React.useState(false);
  const toggleDeletingContact = () => {
    setDeletingContact(!deletingContact);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const infoFields = ["email", "phone_number", "url", "company", "notes"];
  return props.contact ? (
    <EventDetailsContentData>
      <Grid container spacing={0.5} alignItems={"center"}>
        <Grid item xs="auto">
          {props.contact.name}
          {props.contact.role && " | "}
          {props.contact.role}
        </Grid>
        {props.contact.email && (
          <Grid item xs="auto">
            <CopyDetailButton
              text={props.contact.email}
              Icon={EmailOutlinedIcon}
              tooltip="Copy Email"
            />
          </Grid>
        )}

        <Grid item xs="auto">
          <Tooltip
            title={
              <Grid container spaceing={1}>
                {infoFields
                  .filter((field) => props.contact[field])
                  .map((field) => {
                    return (
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          {`${toProperCase(field.replace("_", " "))}: ${
                            props.contact[field]
                          }`}
                        </Typography>
                      </Grid>
                    );
                  })}
              </Grid>
            }
          >
            <IconButton size="small">
              <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Grid>

        {props.contact.is_signer && (
          <Grid item xs="auto">
            <Chip size="small" label="Signer" sx={{ ml: ".25rem" }} />
          </Grid>
        )}
      </Grid>
      <Tooltip title="Edit Contact">
        <IconButton
          sx={{ m: "auto" }}
          onClick={toggleEditingContact}
          size="small"
          disabled={eventLocked()}
        >
          <EditOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          props.contact.is_signer ? "Cannot delete Signer" : "Delete Client"
        }
      >
        <span>
          <IconButton
            sx={{ m: "auto" }}
            onClick={toggleDeletingContact}
            size="small"
            disabled={props.contact.is_signer || eventLocked()}
          >
            <DeleteOutlineOutlinedIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      {editingContact && (
        <ContactDialog
          open={editingContact}
          handleCancel={toggleEditingContact}
          handleSubmit={props.updateContact}
          initialValues={props.contact}
          url={props.url}
          company={props.company}
          warning={props.warning}
        />
      )}

      <ConfirmDeleteContactDialog
        open={deletingContact}
        handleCancel={toggleDeletingContact}
        handleSubmit={() => {
          props.deleteContact(props.contact.uuid);
          toggleDeletingContact();
        }}
        contact={props.contact}
      />
    </EventDetailsContentData>
  ) : null;
};

const ConfirmDeleteContactDialog = (props) => {
  return (
    <ConfirmDeleteDialog
      title="Delete Contact"
      item_name={props.contact.name}
      open={props.open}
      handleCancel={props.handleCancel}
      handleSubmit={props.handleSubmit}
    />
  );
};

const ConfirmDeleteDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete {props.item_name}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          size="small"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={props.handleSubmit}
          size="small"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CopyDetailButton = ({ tooltip, Icon, text }) => {
  const [copied, setCopied] = React.useState(false);
  const toggleCopied = () => {
    setCopied(!copied);
  };
  const copyToClipboard = () => {
    toggleCopied();
  };
  return (
    <>
      <Tooltip title={tooltip}>
        <span>
          <CopyToClipboard text={text} onCopy={copyToClipboard}>
            <IconButton onClick={copyToClipboard} size="small">
              <Icon fontSize="inherit" />
            </IconButton>
          </CopyToClipboard>
        </span>
      </Tooltip>
      <Snackbar
        open={copied}
        autoHideDuration={6000}
        onClose={toggleCopied}
        message="Copied to clipboard"
      />
    </>
  );
};

const ContactDialog = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationObj = () => {
    let val_obj = {
      name: Yup.string()
        .min(2, "Name must be at least 2 characters")
        .max(100, "Name must be less than 100 characters")
        .required("Must provide a name"),
      email: Yup.string().email("Email is not valid").nullable(),
      phone_number: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .nullable(),
      notes: Yup.string()
        .max(500, "Must be less than 500 characters")
        .nullable(),
      role: Yup.string().max(50, "Must be less than 50 characters").nullable(),
    };
    if (props.url) {
      val_obj.url = Yup.string()
        .min(2, "Name must be at least 2 characters")
        .max(100, "Name must be less than 100 characters")
        .nullable();
    }
    if (props.company) {
      val_obj.company = Yup.string()
        .min(2, "Name must be at least 2 characters")
        .max(100, "Name must be less than 100 characters")
        .nullable();
    }
    return val_obj;
  };

  const validationSchema = Yup.object().shape(validationObj());

  const initialValuesObject = () => {
    let obj = {
      name: "",
      email: "",
      phone_number: "",
      notes: "",
      role: "",
    };
    if (props.url) {
      obj.url = "";
    }
    if (props.company) {
      obj.company = "";
    }
    return obj;
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      ...initialValuesObject(),
      ...props.initialValues,
    },
    onSubmit: (values, { resetForm }) => {
      props.handleSubmit(values);
      props.handleCancel();
      resetForm();
    },
  });

  React.useEffect(() => {
    console.log("FORMIK ERRORS", formik.errors);
  }, [formik.errors]);
  return (
    <Dialog
      onClose={props.handleCancel}
      open={props.open}
      id="new-client-dialog"
    >
      <DialogTitle>{props.editing ? "Edit Client" : "Add Client"}</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: ".5rem" }}>
          <Grid container spacing={1} justifyContent="center">
            <NewContactTextField
              name="name"
              label="Name"
              formik={formik}
              id="new-client-dialog-name"
              disabled={props.initialValues?.is_signer}
            />
            <NewContactTextField
              name="email"
              label="Email"
              formik={formik}
              id="new-client-dialog-email"
              disabled={props.initialValues?.is_signer}
            />
            <NewContactTextField
              name="phone_number"
              label="Phone"
              formik={formik}
              id="new-client-dialog-phone"
            />
            <NewContactTextField
              name="role"
              label="Role"
              formik={formik}
              id="new-client-dialog-role"
            />
            {props.url && (
              <NewContactTextField
                name="url"
                label="URL"
                formik={formik}
                id="new-client-dialog-url"
              />
            )}
            {props.company && (
              <NewContactTextField
                name="company"
                label="Company"
                formik={formik}
                id="new-client-dialog-company"
              />
            )}
            <NewContactTextField
              name="notes"
              label="Notes"
              formik={formik}
              multiline
              rows={4}
              id="new-client-dialog-notes"
              fullWidth
            />
          </Grid>
          {props.warning && (
            <Grid item xs={12}>
              <Alert severity="warning">{props.warning}</Alert>
            </Grid>
          )}
          {formik.errors &&
            Object.entries(formik.errors).map(([key, value]) => {
              return (
                <Grid item xs={12}>
                  <Alert severity="error">
                    {key}:{value}
                  </Alert>
                </Grid>
              );
            })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCancel}
          color="info"
          variant="outlined"
          id="new-client-dialog-cancel-button"
          size="small"
        >
          Cancel
        </Button>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          color="secondary"
          id="new-client-dialog-submit-button"
          size="small"
        >
          {props.initialValues ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddClientButton = (props) => {
  const [addClient] = useAccountState((state) => [state.addClient]);
  const [addingClient, setAddingClient] = React.useState(false);
  const toggleAddingClient = () => {
    setAddingClient(!addingClient);
  };
  return (
    <>
      <AddEventDetailsContentButton
        onClick={toggleAddingClient}
        label="Add Client"
      />

      <ContactDialog
        open={addingClient}
        handleCancel={toggleAddingClient}
        handleSubmit={addClient}
      />
    </>
  );
};

const AddProfessionalContactButton = (props) => {
  const [addClient] = useAccountState((state) => [state.addClient]);
  const [addingContact, setAddingContact] = React.useState(false);
  const toggleAddingContact = () => {
    setAddingContact(!addingContact);
  };
  return (
    <>
      <AddEventDetailsContentButton
        onClick={toggleAddingContact}
        label="Add Contact"
      />

      <AddEventContactDialog
        open={addingContact}
        handleCancel={toggleAddingContact}
      />
    </>
  );
};

const NewContactTextField = (props) => {
  return (
    <Grid item xs={12} sm={props.fullWidth ? 12 : 6}>
      <TextField
        name={props.name}
        label={props.label}
        id={props.name}
        value={props.formik.values[props.name]}
        onChange={props.formik.handleChange}
        error={
          props.formik.touched[props.name] &&
          Boolean(props.formik.errors[props.name])
        }
        helperText={
          props.formik.touched[props.name] && props.formik.errors[props.name]
        }
        variant="outlined"
        fullWidth
        multiline={props.multiline}
        rows={props.rows}
        disabled={props.disabled}
      />
    </Grid>
  );
};

const EventContacts = (props) => {
  const [event] = useAccountState((state) => [state.event]);

  const [addingEventContact, setAddingEventContact] = React.useState(false);

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12}>
        {event.event_contacts.length > 0 ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {event.event_contacts.sort(orderByField("name")).map((contact) => {
              return (
                <EventContactRow
                  contact={contact}
                  key={contact.uuid}
                  id={"event-contact-row-" + contact.uuid}
                />
              );
            })}
          </Table>
        ) : (
          <Typography
            align="center"
            sx={{
              border: "dashed lightgrey 2px",
              p: "1rem",
              borderRadius: "5px",
              color: "info.main",
            }}
          >
            No contacts added yet.{" "}
            <Link
              color="inherit"
              sx={{ "&:hover": { cursor: "pointer" } }}
              id="add-event-contact-link"
            >
              Add Contact
            </Link>
          </Typography>
        )}
      </Grid>
      <Grid item xs="auto">
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {
            setAddingEventContact(true);
          }}
          id="add-event-contact-button"
        >
          <AddOutlinedIcon /> Add Contact
        </Button>
        <AddEventContactDialog
          open={addingEventContact}
          handleCancel={() => {
            setAddingEventContact(false);
          }}
        />
      </Grid>
    </Grid>
  );
};

const EventContactRow = (props) => {
  const [updateEventContact] = useAccountState((state) => [
    state.updateEventContact,
  ]);

  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  return (
    <TableRow>
      <TableCell>{props.contact.name}</TableCell>
      <TableCell>{props.contact.type}</TableCell>
      <TableCell>{props.contact.phone_number}</TableCell>
      <TableCell>{props.contact.email}</TableCell>
      <TableCell align="right">
        {props.contact.url ? (
          <Tooltip title={props.contact.url}>
            <IconButton
              onClick={() =>
                window.open(
                  props.contact.url.includes("http")
                    ? props.contact.url
                    : "https://" + props.contact.url,
                  "_blank"
                )
              }
            >
              <LanguageOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        <IconButton
          onClick={() => {
            setEditing(true);
          }}
          id={props.id + "-edit-button"}
        >
          <EditOutlinedIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setDeleting(true);
          }}
          id={props.id + "-delete-button"}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </TableCell>
      <RemoveEventContactDialog
        open={deleting}
        handleCancel={() => setDeleting(false)}
        event_contact={props.contact}
      />
      <AddEventCoordinatorDialog
        open={editing}
        handleCancel={() => setEditing(false)}
        handleSubmit={updateEventContact}
        initialValues={props.contact}
      />
    </TableRow>
  );
};

const AddEventContactDialog = (props) => {
  const [addEventContact, createEventContact, filteredEventCoordinators] =
    useAccountState((state) => [
      state.addEventContact,
      state.createEventContact,
      state.filteredEventCoordinators,
    ]);

  const [selectedEventContact, setSelectedEventContact] = React.useState(null);
  const [creatingEventContact, setCreatingEventContact] = React.useState(false);

  const addEventContact_ = () => {
    addEventContact(selectedEventContact);
    setSelectedEventContact(null);
    props.handleCancel();
  };

  const createEventContact_ = (data) => {
    createEventContact(data, (event_contact) => {
      setSelectedEventContact(event_contact);
      setCreatingEventContact(false);
    });
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Add Contact</DialogTitle>
      <DialogContent>
        <Autocomplete
          value={selectedEventContact}
          id="event_coordinator"
          options={filteredEventCoordinators()}
          renderInput={(params) => <TextField {...params} label="Contact" />}
          sx={{ width: "300px", m: "auto", mt: "0.5rem" }}
          getOptionLabel={(option) => {
            return option.name + ", " + option.company;
          }}
          onChange={(e, value) => {
            setSelectedEventContact(value);
          }}
          noOptionsText={
            <Typography>
              No contacts match your search.{" "}
              <Link onClick={() => setCreatingEventContact(true)}>
                Add contact?
              </Link>
            </Typography>
          }
        />

        <ContactDialog
          open={creatingEventContact}
          handleCancel={() => setCreatingEventContact(false)}
          handleSubmit={createEventContact_}
          url
          company
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={addEventContact_}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RemoveEventContactDialog = (props) => {
  const [removeEventContact] = useAccountState((state) => [
    state.removeEventContact,
  ]);

  const removeEventContact_ = () => {
    removeEventContact(props.event_contact.uuid);
    props.handleCancel();
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Remove Contact</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to remove {props.event_contact.name} from this
          event?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="delete-contact-cancel-button"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={removeEventContact_}
          id="delete-contact-confirm-button"
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddEventCoordinatorDialog = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(100, "Name must be less than 100 characters")
      .required("Must provide a name"),
    company: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(100, "Name must be less than 100 characters")
      .nullable(),
    url: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(100, "Name must be less than 100 characters")
      .nullable(),
    email: Yup.string().email("Email is not valid").nullable(),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .nullable(),
    type: Yup.string()
      .min(2, "Position must be at least 2 characters")
      .max(100, "Position must be less than 100 characters")
      .nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: props.initialValues ? props.initialValues : {},
    onSubmit: (values, { resetForm }) => {
      props.handleCancel();
      props.handleSubmit(values);
      resetForm();
    },
  });

  return (
    <Dialog onClose={props.handleCancel} open={props.open}>
      <DialogTitle>
        {props.initialValues ? "Edit" : "Add"} Event Coordinator
      </DialogTitle>
      <DialogContent>
        <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
          <Grid container spaceing={1} justifyContent="center">
            <AddEventCoordinatorTextField
              name="name"
              label="Name"
              formik={formik}
            />
            <AddEventCoordinatorTextField
              name="company"
              label="Company"
              formik={formik}
            />
            <AddEventCoordinatorTextField
              name="email"
              label="Email"
              formik={formik}
            />
            <AddEventCoordinatorTextField
              name="phone_number"
              label="Phone"
              formik={formik}
            />
            <AddEventCoordinatorTextField
              name="url"
              label="Website"
              formik={formik}
            />
            <Grid item xs={9} sm={7}>
              <Autocomplete
                value={formik.values.type}
                onChange={(e, value) => {
                  formik.setFieldValue("type", value);
                }}
                onInputChange={(e, value) => {
                  formik.setFieldValue("type", value);
                }}
                freeSolo
                options={[
                  "Event Coordinator",
                  "Event Planner",
                  "Venue Manager",
                  "Other",
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Position" />
                )}
                sx={{
                  mt: "1rem",
                }}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              onClick={props.handleCancel}
              variant="outlined"
              color="info"
            >
              Cancel
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              color="secondary"
            >
              {props.initialValues ? "Save" : "Add"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const AddEventCoordinatorTextField = (props) => {
  return (
    <Grid item xs={9} sm={7}>
      <TextField
        name={props.name}
        label={props.label}
        id={props.name}
        value={props.formik.values[props.name]}
        onChange={props.formik.handleChange}
        error={
          props.formik.touched[props.name] &&
          Boolean(props.formik.errors[props.name])
        }
        helperText={
          props.formik.touched[props.name] && props.formik.errors[props.name]
        }
        variant="outlined"
        fullWidth
        sx={{
          mt: "1rem",
        }}
        multiline={props.multiline}
        rows={props.rows}
      />
    </Grid>
  );
};

const EventLocations = (props) => {
  const [
    addEventLocation,
    back_office,
    event,
    eventDateAmended,
    event_locations,
    eventDateFormatted,
    resetEventDate,
    updateBackOffice,
    updateEvent,
  ] = useAccountState((state) => [
    state.addEventLocation,
    state.back_office,
    state.event,
    state.eventDateAmended,
    state.event_locations,
    state.eventDateFormatted,
    state.resetEventDate,
    state.updateBackOffice,
    state.updateEvent,
  ]);
  const [adding_event_location, setAddingEventLocation] = React.useState(false);
  const toggleAddingEventLocation = () => {
    setAddingEventLocation(!adding_event_location);
  };
  const [editing_key_date, setEditingKeyDate] = React.useState(false);

  const sortEventLocations = (event_locations) => {
    var unsorted_event_locations = [...event_locations];
    var sorted_event_locations = sort(unsorted_event_locations).asc(
      (el) => new Date(el.date)
    );
    return sorted_event_locations;
  };

  const latestDate = (event_locations) => {
    if (event_locations.length > 0) {
      return event_locations.sort((a, b) => {
        if (a.date > b.date) {
          return 1;
        } else if (a.date < b.date) {
          return -1;
        } else {
          return 0;
        }
      })[event_locations.length - 1].date;
    } else {
      return undefined;
    }
  };

  return (
    <Grid item xs={12} container spacing={1}>
      {sortEventLocations(event_locations).map((event_location) => (
        <EventLocationTimelineItem
          key={event_location.uuid}
          event_location={event_location}
          id={"event-location-timeline-item-" + event_location.uuid}
        />
      ))}
      <Grid item xs={12}>
        <AddEventDetailsContentButton
          onClick={toggleAddingEventLocation}
          label="Add Timeline Item"
        />
        {adding_event_location && (
          <AddEventLocationDialog
            open={adding_event_location}
            handleCancel={toggleAddingEventLocation}
            handleSubmit={addEventLocation}
            initialValues={{
              date:
                event_locations.length > 0
                  ? latestDate(event_locations)
                  : event.date,
            }}
            back_office={back_office}
          />
        )}
      </Grid>
    </Grid>
  );
};

const EditDateDialog = (props) => {
  const [back_office, event, timezone, updateBackOffice] = useAccountState(
    (state) => [
      state.back_office,
      state.event,
      state.back_office.timezone,
      state.updateBackOffice,
    ]
  );
  const formik = useFormik({
    initialValues: {
      ...props.initialValues,
      date: formatInTimeZone(
        new Date(props.initialValues.date),
        timezone,
        "yyyy-MM-dd"
      ),
    },
    onSubmit: (values, { resetForm }) => {
      props.handleCancel();
      props.handleSubmit(values);
      resetForm();
    },
    enableReinitialize: true,
  });
  return (
    <Dialog open={props.open} onClose={props.handleCancel} id={props.id}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ pt: ".5rem" }}>
          <Grid item xs={12}>
            <TextField
              id="date"
              label="Date"
              defaultValue={formik.values.date}
              type="date"
              onChange={(e) => {
                formik.setFieldValue(
                  "date",
                  zonedTimeToUtc(e.target.value + "T12:00:00", timezone)
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TimezonePicker
              id="timezone-picker"
              disabled={event.locked}
              value={back_office.timezone}
              onChange={(val) => {
                updateBackOffice({
                  timezone: val.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCancel}
          id={props.id + "-cancel-button"}
          color="info"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          color="secondary"
          id={props.id + "-cancel-button"}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EventLocationTimelineItem = (props) => {
  const [deleteEventLocation, eventLocked, timezone, updateEventLocation] =
    useAccountState((state) => [
      state.deleteEventLocation,
      state.eventLocked,
      state.back_office.timezone,
      state.updateEventLocation,
    ]);
  const [details_open, setDetailsOpen] = React.useState(false);
  const toggleDetailsOpen = () => {
    setDetailsOpen(!details_open);
  };
  const [deletingContact, setDeletingContact] = React.useState(false);
  const toggleDeletingContact = () => {
    setDeletingContact(!deletingContact);
  };
  const toggleChecked = () => {
    updateEventLocation({
      uuid: props.event_location.uuid,
      show_in_proposal: !props.event_location.show_in_proposal,
    });
  };
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <EventDetailsContentHeader>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs="auto">
              {eventDateTime(props.event_location.date, timezone)}
            </Grid>
            <Grid item xs="auto">
              <FormControlLabel
                control={
                  <Switch
                    checked={props.event_location.show_in_proposal}
                    onChange={toggleChecked}
                    size="small"
                  />
                }
                label="Show in Proposal"
                disabled={eventLocked()}
              />
            </Grid>
          </Grid>
        </EventDetailsContentHeader>
      </Grid>
      <EventDetailsContentData>
        <>
          <Typography sx={{ fontSize: "inherit" }}>
            {`${props.event_location.name} | ${props.event_location.location.name}`}
          </Typography>
          <Typography sx={{ fontSize: "inherit" }}>
            {locationAddress(props.event_location.location)}
          </Typography>
        </>
        <IconButton
          onClick={toggleDetailsOpen}
          disabled={eventLocked()}
          size="small"
        >
          <EditOutlinedIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          onClick={toggleDeletingContact}
          size="small"
          disabled={eventLocked()}
        >
          <DeleteOutlineOutlinedIcon fontSize="inherit" />
        </IconButton>
        <EventLocationDetails
          event_location={props.event_location}
          open={details_open}
          onClose={toggleDetailsOpen}
        />
        <ConfirmDeleteEventLocationDialog
          event_location={props.event_location}
          open={deletingContact}
          handleCancel={toggleDeletingContact}
          handleSubmit={() => {
            deleteEventLocation(props.event_location.uuid);
            toggleDeletingContact();
          }}
        />
      </EventDetailsContentData>
    </Grid>
  );
};
const ConfirmDeleteEventLocationDialog = (props) => {
  return (
    <ConfirmDeleteDialog
      title="Delete Timeline Item"
      item_name={props.event_location.name}
      open={props.open}
      handleCancel={props.handleCancel}
      handleSubmit={props.handleSubmit}
    />
  );
};
const EventLocationDetails = (props) => {
  const [
    deleteEventLocation,
    locked,
    timezone,
    updateEventLocation,
    updateLocation,
    updateEventLocationState,
  ] = useAccountState((state) => [
    state.deleteEventLocation,
    state.event.locked,
    state.back_office.timezone,
    state.updateEventLocation,
    state.updateLocation,
    state.updateEventLocationState,
  ]);
  const [editing_event_location, setEditingEventLocation] =
    React.useState(false);
  const [editing_location, setEditingLocation] = React.useState(false);

  const filenameFromUrl = (url) => {
    let parts = url.split("/");
    return parts[parts.length - 1];
  };

  const _updateLocation = (data) => {
    data = {
      ...data,
      uuid: props.event_location.location.uuid,
      // event_location_uuid: event_location.uuid,
    };
    updateLocation(data, (location) =>
      updateEventLocationState({
        uuid: props.event_location.uuid,
        location: location,
      })
    );
  };

  const pocString = () => {
    let s = [
      props.event_location.location.poc,
      props.event_location.location.phone_number,
      props.event_location.location.email,
    ]
      .filter((e) => e)
      .join(" | ");
    return s;
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={"xs"}>
      <DialogTitle>{props.event_location.name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <EventDetailsLine>
            {"Date"}
            {eventDateTime(props.event_location.date, timezone)}
          </EventDetailsLine>

          <EventDetailsLine>
            {"Location"}
            <>
              {locationNameParts(props.event_location.location)
                .filter((e) => e)
                .join(" | ")}
              <Tooltip
                title={"Edit details of " + props.event_location.location.name}
              >
                <IconButton
                  onClick={() => setEditingLocation(true)}
                  disabled={locked}
                  size="small"
                >
                  <EditOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </>
            <CreateNewLocationDialog
              open={editing_location}
              handleCancel={() => setEditingLocation(false)}
              handleSubmit={_updateLocation}
              initialValues={props.event_location.location}
            />

            {[
              props.event_location.location.city,
              props.event_location.location.state,
              props.event_location.location.country,
            ]
              .filter((e) => e)
              .join(" | ")}

            {props.event_location.location.url}
          </EventDetailsLine>
          {props.event_location.location.notes && (
            <EventDetailsLine>
              {"Notes"}
              {props.event_location.location.notes}
            </EventDetailsLine>
          )}
          <EventDetailsLine>
            {"Point Of Contact"}
            {pocString()}
          </EventDetailsLine>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.onClose}
          id="event-location-details-close-button"
        >
          Close
        </Button>
        <Button
          variant="contained"
          startIcon={<EditOutlinedIcon />}
          onClick={() => setEditingEventLocation(true)}
          color="secondary"
          disabled={locked}
        >
          Edit Time & Location
        </Button>
      </DialogActions>
      <AddEventLocationDialog
        open={editing_event_location}
        handleCancel={() => {
          setEditingEventLocation(false);
        }}
        handleSubmit={updateEventLocation}
        initialValues={{
          name: props.event_location.name,
          date: props.event_location.date,
          location: props.event_location.location,
          uuid: props.event_location.uuid,
        }}
      />
    </Dialog>
  );
};

const EventDetailsLine = (props) => {
  const children = React.Children.toArray(props.children);
  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold" }}>{children[0]}</Typography>
        {children.slice(1).map((child, index) => (
          <Typography key={index}>{child}</Typography>
        ))}
      </Grid>
    </Grid>
  );
};

const AddEventLocationDialog = (props) => {
  const [addLocation, getLocations, locations, timezone] = useAccountState(
    (state) => [
      state.addLocation,
      state.getLocations,
      state.locations,
      state.back_office.timezone,
    ]
  );

  const [creating_new_location, setCreatingNewLocation] = React.useState(false);
  const [loading_new_location, setLoadingNewLocation] = React.useState(false);

  React.useEffect(() => {
    getLocations();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(100, "Name must be less than 100 characters")
      .required("Must provide a name"),
    date: Yup.string().required("Must provide a date"),
    location: Yup.object().required("Must provide a location"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      ...props.initialValues,
      date: formatInTimeZone(
        props.initialValues.date,
        timezone,
        "yyyy-MM-dd'T'HH:mm"
      ),
    },
    onSubmit: (values, { resetForm }) => {
      props.handleCancel();
      let date = moment.tz(values.date, timezone).utc().format();
      props.handleSubmit({ ...values, date: date });
      if (!props.initialValues) {
        resetForm();
      }
    },
    enableReinitialize: true,
  });

  const createNewLocation = (data) => {
    setLoadingNewLocation(true);
    addLocation(data, (data) => {
      formik.setFieldValue("location", data);
      setLoadingNewLocation(false);
    });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add Location & Time</DialogTitle>
      <DialogContent>
        <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={9} sm={7}>
              <FormError>{formik.errors.name}</FormError>
              <TextField
                sx={{ mt: "1rem" }}
                name="name"
                label="Name"
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
                placeholder="e.g. Reception"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={9} sm={7} display="flex">
              <TextField
                type="datetime-local"
                // inputFormat="MM/dd/yyyy h:mm"
                name="date"
                id="date"
                label="Date"
                defaultValue={formik.values.date}
                onChange={(e) => {
                  formik.setFieldValue("date", e.target.value);
                }}
                error={formik.touched.date && Boolean(formik.errors.date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            {!loading_new_location ? (
              <Grid item xs={9} sm={7}>
                <FormError>{formik.errors.location}</FormError>
                <Autocomplete
                  name="location"
                  label="Location"
                  id="location"
                  value={formik.values.location}
                  onChange={(e, newValue) => {
                    formik.setFieldValue("location", newValue);
                  }}
                  error={
                    formik.touched.location && Boolean(formik.errors.location)
                  }
                  helperText={formik.touched.location && formik.errors.location}
                  fullWidth
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                  options={locations}
                  getOptionLabel={(option) => {
                    return locationOptionName(option);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.uuid === value.uuid
                  }
                  noOptionsText={
                    <Typography>
                      No locations match your search.{" "}
                      <Link
                        onClick={() => {
                          setCreatingNewLocation(true);
                        }}
                      >
                        Add location?
                      </Link>
                    </Typography>
                  }
                />
              </Grid>
            ) : null}
            <CreateNewLocationDialog
              open={creating_new_location}
              handleCancel={() => setCreatingNewLocation(false)}
              handleSubmit={createNewLocation}
            />
          </Grid>
          <DialogActions>
            <Button
              onClick={props.handleCancel}
              id="add-location-and-time-cancel-button"
              color="info"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              color="secondary"
              id="add-location-and-time-submit-button"
            >
              {props.initialValues.name ? "Save" : "Create"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const CreateNewLocationDialog = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters")
      .required("Must provide a name"),
    address_line_1: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters"),
    address_line_2: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters"),
    city: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(85, "Must be less than 85 characters"),
    state: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(85, "Must be less than 85 characters"),
    country: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(85, "Must be less than 85 characters"),
    postal_code: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(10, "Must be less than 85 characters"),
    url: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters"),
    email: Yup.string().email("Email is not valid"),
    phone_number: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
    notes: Yup.string().max(500, "Must be less than 500 characters"),
    poc: Yup.string().max(100, "Must be less than 100 characters"),
  });

  const removeEmpty = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: props.initialValues ? removeEmpty(props.initialValues) : {},
    onSubmit: (values) => {
      props.handleCancel();
      props.handleSubmit(values);
    },
  });

  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {props.initialValues ? "Edit Location" : "Create New Location"}
      </DialogTitle>
      <DialogContent>
        <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
              <LocationTextField id="name" label="Name" formik={formik} />
            </Grid>
            {formik.values.address_line_1 ? (
              <>
                <Grid item xs={12}>
                  <LocationTextField
                    id="address_line_1"
                    label="Address Line 1"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocationTextField
                    id="address_line_2"
                    label="Address Line 2"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationTextField id="city" label="City" formik={formik} />
                </Grid>
                <Grid item xs={6}>
                  <LocationTextField id="state" label="State" formik={formik} />
                </Grid>
                <Grid item xs={6}>
                  <LocationTextField
                    id="country"
                    label="Country"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationTextField
                    id="postal_code"
                    label="Postal Code"
                    formik={formik}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <AddressField
                  initialValue={formik.values.google_address}
                  handleChange={(value) => {
                    formik.setFieldValue("google_address", value);
                    formik.setFieldValue("google_place_id", value?.place_id);
                  }}
                  id={"google_address"}
                  label={"Address"}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <LocationTextField
                id="poc"
                label="Point of Contact"
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <LocationTextField
                id="phone_number"
                label="Phone Number"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12}>
              <LocationTextField id="url" label="Website" formik={formik} />
            </Grid>
            <Grid item xs={12}>
              <LocationTextField
                id="notes"
                label="Notes"
                formik={formik}
                multiline
                rows={4}
              />
            </Grid>
            {props.initialValues ? (
              <Grid item xs={12}>
                <Typography color="error.main">
                  Note: This change will apply for all events that reference
                  this location.
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <DialogActions>
            <Button
              onClick={props.handleCancel}
              color="info"
              variant="outlined"
              id="create-new-location-cancel-button"
            >
              Cancel
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              color="secondary"
              id="create-new-location-submit-button"
            >
              {props.initialValues ? "Save" : "Create"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const LocationTextField = (props) => {
  return (
    <TextField
      sx={{ mt: "1rem" }}
      name={props.id}
      label={props.label}
      id={props.id}
      value={props.formik.values[props.id]}
      onChange={props.formik.handleChange}
      error={
        props.formik.touched[props.id] && Boolean(props.formik.errors[props.id])
      }
      helperText={
        props.formik.touched[props.id] && props.formik.errors[props.id]
      }
      fullWidth
      variant="outlined"
      multiline={props.multiline}
      rows={props.rows}
    />
  );
};

export const eventDate = (event_date, timezone) => {
  if (typeof event_date !== "undefined") {
    return formatInTimeZone(new Date(event_date), timezone, "MM/dd/yy");
  } else {
    return null;
  }
};

export const eventDateTime = (event_date, timezone, format) => {
  if (!format) {
    format = "MM/dd/yy p ";
  } else {
    format = format + " p";
  }
  if (typeof event_date !== "undefined") {
    return formatInTimeZone(new Date(event_date), timezone, format);
  } else {
    return null;
  }
};

export const eventDateTimeInput = (event_date, timezone) => {
  console.log(
    "EVENT DATE: ",
    event_date,
    typeof event_date,
    new Date(event_date)
  );
  if (typeof event_date !== "undefined") {
    return formatInTimeZone(
      new Date(event_date),
      timezone,
      "yyyy-MM-dd'T'HH:mm"
    );
  } else {
    return null;
  }
};

const FormError = (props) => {
  return <Typography color="error.main">{props.children}</Typography>;
};

const BackOfficeSettingsView = (props) => {
  const [back_office, formatCurrency] = useAccountState((state) => [
    state.back_office,
    state.formatCurrency,
  ]);
  const [editing, setEditing] = React.useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const sectionData = [
    {
      label: "Design Fee",
      data: [
        {
          label: "Design Fee",
          id: "design_fee_percent",
          render: (val) => `${val}%`,
        },
      ],
    },
    {
      label: "Multipliers",
      data: [
        { label: "Flower Cost", id: "flower_cost_multiplier" },
        { label: "Hard Good Rental", id: "material_rental_cost_multiplier" },
        { label: "Hard Good Sale", id: "material_sale_cost_multiplier" },
        { label: "In Studio Labor", id: "build_labor_cost_multiplier" },
        { label: "On Site Labor", id: "install_labor_cost_multiplier" },
      ],
    },
    {
      label: "Labor Rates",
      data: [
        {
          label: "In Studio Labor Hourly Rate",
          id: "build_labor_cost_hourly",
          render: (val) => formatCurrency(val),
        },
        {
          label: "On Site Labor Hourly Rate",
          id: "install_labor_cost_hourly",
          render: (val) => formatCurrency(val),
        },
      ],
    },
    {
      label: "Overhead",
      data: [
        {
          label: "Account For FlowerBuddy Fee",
          id: "price_in_flowerbuddy_fee",
        },
        { label: "Account For Stripe Fee", id: "price_in_stripe_fee" },
      ],
    },
    {
      label: "Calculation Settings",
      data: [
        {
          label: "Round Suggested Prices",
          id: "round_suggested_prices",
          render: (val) =>
            round_suggested_prices_options.find((o) => o.value === val).label,
        },
      ],
    },
  ];

  return (
    <SettingsView
      sectionData={sectionData}
      EditSettingsDialog={EditBackOfficeSettingsDialog}
    />
  );
};

const EditBackOfficeSettingsDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Edit Markups</DialogTitle>
      <DialogContent>
        <BackOfficeSettings />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCancel}
          color="info"
          variant="outlined"
          id="edit-back-office-settings-cancel-button"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const OtherSettingsView = (props) => {
  const [back_office, event, getCurrencySymbol, formatCurrency] =
    useAccountState((state) => [
      state.back_office,
      state.event,
      state.getCurrencySymbol,
      state.formatCurrency,
    ]);
  const [editing, setEditing] = React.useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const sectionData = [
    {
      label: "Email Settings",
      data: [
        {
          label: "Upcoming Payment | 7 Days",
          id: "auto_email_payment_upcoming_7_days",
        },
        {
          label: "Upcoming Payment | 3 Days",
          id: "auto_email_payment_upcoming_3_days",
        },
        { label: "Payment Due", id: "auto_email_payment_due" },
        {
          label: "Payment Overdue | 3 Days",
          id: "auto_email_payment_overdue_3_days",
        },
        {
          label: "Payment Overdue | 7 Days",
          id: "auto_email_payment_overdue_7_days",
        },
      ],
    },
    {
      label: "View Settings",
      data: [
        { label: "View Profit As Percent", id: "view_profit_as_percent" },
        {
          label: `Price Warning Tolerance`,
          id: "price_error_tolerance",
          render: (val) => formatCurrency(val),
        },
        {
          label: "Date Format",
          id: "date_format",
          render: (val) =>
            eventDateFormatted(
              event.date ? event.date : new Date(),
              back_office.timezone,
              val
            ),
        },
      ],
    },
  ];

  return (
    <SettingsView
      sectionData={sectionData}
      EditSettingsDialog={EditOtherSettingsDialog}
    />
  );
};

const EditOtherSettingsDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Edit Settings</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AutoEmailSettings />
          </Grid>
          <Grid item xs={12}>
            <ViewSettings />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCancel}
          color="info"
          variant="outlined"
          id="edit-other-settings-cancel-button"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SettingsView = ({ sectionData, EditSettingsDialog }) => {
  const [back_office] = useAccountState((state) => [state.back_office]);
  const [editing, setEditing] = React.useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  return (
    <Grid container spacing={2}>
      {sectionData.map((sd) => (
        <Grid item xs={12}>
          <EventDetailsContentHeader>{sd.label}</EventDetailsContentHeader>
          {sd.data.map((d) => (
            <EventDetailsContentData>
              {`${d.label}: ${
                d.render ? d.render(back_office[d.id]) : back_office[d.id]
              }`}
            </EventDetailsContentData>
          ))}
        </Grid>
      ))}
      <Grid item xs={12}>
        <EditEventDetailsContentButton
          onClick={toggleEditing}
          label="Edit Settings"
        />
        <EditSettingsDialog open={editing} handleCancel={toggleEditing} />
      </Grid>
    </Grid>
  );
};

const Files = (props) => {
  const [event, proposal] = useAccountState((state) => [
    state.event,
    state.proposal,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <EventDetailsContentHeader>Contracts</EventDetailsContentHeader>
        </Grid>
        {proposal.contracts
          .sort(sortByKey("date_created"))
          .map((document, doc_ind) => (
            <DocumentLine key={document.uuid} document={document} />
          ))}

        {!proposal.contracts ||
          (proposal.contracts.length === 0 && (
            <Box
              sx={{
                p: ".5rem",
                border: "dashed 1px",
                borderRadius: "5px",
                borderColor: "info.light",
              }}
            >
              <Typography variant="caption" color="info.light">
                No documents have been generated for this event. Documents are
                generated after a client views the proposal and chooses to sign
                the contract.
              </Typography>
            </Box>
          ))}
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <EventDetailsContentHeader>Attachments</EventDetailsContentHeader>
        </Grid>
        {event.event_attachments
          .filter((ea) => !ea.is_order)
          .sort(sort_by_order)
          .map((attachment) => (
            <EventAttachmentLine
              key={attachment.uuid}
              attachment={attachment}
            />
          ))}
        <Grid item xs={12}>
          <AddAttachmentButton />
        </Grid>
      </Grid>
    </Grid>
  );
};

const DocumentLine = ({ document }) => {
  const [deleteContract, eventLocked] = useAccountState((state) => [
    state.deleteContract,
    state.eventLocked,
  ]);
  const locale = useLocaleContext();
  const [viewing, setViewing] = React.useState(false);
  const toggleViewing = () => {
    setViewing(!viewing);
  };
  const [deleting, setDeleting] = React.useState(false);
  const toggleDeleting = () => {
    setDeleting(!deleting);
  };
  return (
    <EventDetailsContentData>
      <>
        {`${document.title} | ${formatDate(document.date_created, locale)}`}
        {documentIsSigned(document) && (
          <Chip
            sx={{ ml: ".5rem" }}
            label="Signed"
            color="success"
            size="small"
          />
        )}
      </>
      <EmbeddedSignDialog
        open={viewing}
        handleCancel={toggleViewing}
        sign_page_url={getSignerInfo(document.signers, false, "sign_page_url")}
        signed={true}
        Sign={toggleViewing}
        document_id={document.id}
        florist
        ButtonComponent={ViewDocumentButton}
      />
      <IconButton
        size="small"
        disabled={documentIsSigned(document)}
        onClick={toggleDeleting}
      >
        <DeleteOutlineOutlinedIcon fontSize="inherit" />
      </IconButton>
      <ConfirmDeleteContractDialog
        title={`${document.title} | ${formatDate(
          document.date_created,
          locale
        )}`}
        open={deleting}
        handleCancel={toggleDeleting}
        handleSubmit={() => {
          deleteContract(document.uuid);
          toggleDeleting();
        }}
      />
    </EventDetailsContentData>
  );
};

const ViewDocumentButton = (props) => {
  return (
    <IconButton size="small" onClick={props.onClick}>
      <VisibilityOutlinedIcon fontSize="inherit" />
    </IconButton>
  );
};

const ConfirmDeleteContractDialog = (props) => {
  return (
    <ConfirmDeleteDialog
      title="Delete Contract"
      item_name={props.title}
      open={props.open}
      handleCancel={props.handleCancel}
      handleSubmit={props.handleSubmit}
    />
  );
};

const EventAttachmentLine = (props) => {
  const [deleteEventAttachment, eventLocked] = useAccountState((state) => [
    state.deleteEventAttachment,
    state.eventLocked,
  ]);
  const [editingName, setEditingName] = React.useState(false);
  const [name, setName] = React.useState(props.attachment.name);
  const updateName = () => {
    props.updateAttachment({
      uuid: props.attachment.uuid,
      name: name,
    });
    setEditingName(false);
  };
  const [editing, setEditing] = React.useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };

  const [deleting, setDeleting] = React.useState(false);
  const toggleDeleting = () => {
    setDeleting(!deleting);
  };

  const extension = props.attachment.attachment_url.split(".").pop();

  const downloadFile = async () => {
    const url = props.attachment.attachment_url;
    const fileName = `${props.attachment.name}.${extension}`; // Specify the desired file name

    try {
      const response = await axios.get(url, {
        responseType: "blob",
        withCredentials: true,
      });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      const href = URL.createObjectURL(blob);
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  return (
    <EventDetailsContentData>
      {props.attachment.name || `Untitled ${extension.toUpperCase()}`}
      <IconButton size="small" onClick={downloadFile}>
        <FileDownloadOutlinedIcon fontSize="inherit" />
      </IconButton>
      <IconButton size="small" onClick={toggleEditing} disabled={eventLocked()}>
        <EditOutlinedIcon fontSize="inherit" />
      </IconButton>
      <IconButton
        size="small"
        onClick={toggleDeleting}
        disabled={eventLocked()}
      >
        <DeleteOutlineOutlinedIcon fontSize="inherit" />
      </IconButton>
      <EditAttachmentNameDialog
        attachment={props.attachment}
        open={editing}
        handleCancel={toggleEditing}
      />
      <ConfirmDeleteAttachmentDialog
        attachment={props.attachment}
        open={deleting}
        handleCancel={toggleDeleting}
        handleSubmit={() => {
          deleteEventAttachment(props.attachment.uuid);
          toggleDeleting();
        }}
      />
    </EventDetailsContentData>
  );
};
const AddAttachmentButton = (props) => {
  const [addClient] = useAccountState((state) => [state.addClient]);
  const [addingAttachment, setAddingAttachment] = React.useState(false);
  const toggleAddingAttachment = () => {
    setAddingAttachment(!addingAttachment);
  };
  return (
    <>
      <AddEventDetailsContentButton
        onClick={toggleAddingAttachment}
        label="Add Attachment"
      />

      <AddAttachmentDialog
        open={addingAttachment}
        handleCancel={toggleAddingAttachment}
      />
    </>
  );
};

const AddAttachmentDialog = (props) => {
  const [addEventAttachment] = useAccountState((state) => [
    state.addEventAttachment,
  ]);

  const addAttachment = (url) => {
    addEventAttachment(url);
    props.handleCancel();
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Add Attachment</DialogTitle>
      <DialogContent>
        <PDFUploader savePdfUrl={addAttachment} width={100} height={75} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCancel}
          color="info"
          variant="outlined"
          id="add-attachment-cancel-button"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditAttachmentNameDialog = (props) => {
  const [updateEventAttachment] = useAccountState((state) => [
    state.updateEventAttachment,
  ]);
  const [name, setName] = React.useState(props.attachment.name);
  const updateName = () => {
    updateEventAttachment({
      uuid: props.attachment.uuid,
      name: name,
    });
    props.handleCancel();
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Edit Attachment Name</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ mt: ".5rem" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoFocus
          size="small"
          variant="outlined"
          label="Name"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Cancel
        </Button>
        <Button onClick={updateName} variant="contained" color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmDeleteAttachmentDialog = (props) => {
  return (
    <ConfirmDeleteDialog
      title="Delete Attachment"
      item_name={props.attachment.name}
      open={props.open}
      handleCancel={props.handleCancel}
      handleSubmit={props.handleSubmit}
    />
  );
};

const Payments = (props) => {
  const [payment_schedule, paymentScheduleErrors] = useAccountState((state) => [
    state.payment_schedule,
    state.paymentScheduleErrors,
  ]);
  const [editing, setEditing] = React.useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <EventDetailsContentHeader>Settings</EventDetailsContentHeader>
      </Grid>
      <Grid item xs={12} container spacing={1} justifyContent={"space-between"}>
        <Grid item xs={"auto"}>
          <PaymentScheduleSettings />
        </Grid>
        {payment_schedule.enabled && (
          <Grid item xs={"auto"}>
            <EditEventDetailsContentButton
              onClick={toggleEditing}
              label="Edit Schedule"
            />
            <PaymentScheduleEditorDialog
              open={editing}
              handleCancel={toggleEditing}
            />
          </Grid>
        )}
      </Grid>
      {payment_schedule.enabled && (
        <Grid item xs={12} container spacing={0.5}>
          {paymentScheduleErrors(props.ignore_amendments).map((err) => (
            <Grid item xs={12} key={err}>
              <Alert severity="error" key={err}>
                {err}
              </Alert>
            </Grid>
          ))}
        </Grid>
      )}
      {payment_schedule.enabled && (
        <Grid item xs={12}>
          <PaymentSchedule />
        </Grid>
      )}
    </Grid>
  );
};

const PaymentScheduleEditorDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Edit Payment Schedule</DialogTitle>
      <DialogContent>
        <PaymentScheduleEditor />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(EventDetails);
