import React from "react";
import { useAccountState } from "../state/store";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { EmptyStateText } from "./EmptyStateContainer";
import {
  AvatarGroup,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Link,
  TextField,
  Alert,
  Tooltip,
} from "@mui/material";
import { ItemIcon } from "./ItemIcon";
import {
  itemDisplayName,
  sortItemsByName,
  sortPaletteItemsByDisplayName,
} from "../utils/item_utils";

import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { LoadingButton } from "@mui/lab";
import AddItemDialog from "./AddItemDialog";
import { GeneratePaletteApi } from "../api/EventVersionApi";

export const PaletteItemsList = (props) => {
  const [event_version] = useAccountState((state) => [state.event_version]);
  const palette_items = event_version.palette_items;
  const [editing, setEditing] = React.useState(false);
  const toggleEditing = () => {
    setEditing(!editing);
  };
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const maxItems = () => {
    if (props.maxItems) {
      return props.maxItems;
    } else if (sm) {
      return 5;
    } else if (md) {
      return 12;
    } else {
      return 13;
    }
  };
  return (
    <>
      {palette_items.length === 0 && (
        <EmptyStateText action={toggleEditing}>
          <>{"No event flowers added."}</>
          <>{"Add Event Flower"}</>
        </EmptyStateText>
      )}
      {palette_items.length > 0 && (
        <Grid
          container
          spacing={1}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={"auto"}>
            {palette_items.length > 0 && (
              <AvatarGroup max={maxItems()}>
                {sortPaletteItemsByDisplayName(palette_items).map(
                  (palette_item) => (
                    <ItemIcon item={palette_item.item} />
                  )
                )}
              </AvatarGroup>
            )}
          </Grid>
          <Grid item xs={"auto"}>
            <IconButton onClick={toggleEditing}>
              <EditOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <PaletteItemsDialog open={editing} handleCancel={toggleEditing} />
    </>
  );
};

const PaletteItemsDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Event Flowers</DialogTitle>
      <DialogContent>
        <PaletteItemsEditor />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PaletteItemsEditor = (props) => {
  const [event_version, addPaletteItem, getFeatureFlagValue] = useAccountState(
    (state) => [
      state.event_version,
      state.addPaletteItem,
      state.getFeatureFlagValue,
    ]
  );

  const [adding, setAdding] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
  };

  const [generating, setGenerating] = React.useState(false);
  const toggleGenerating = () => {
    setGenerating(!generating);
  };

  const addItems = (items) => {
    setLoading(true);
    addPaletteItem(
      items.map((i) => i.uuid),
      () => {
        setLoading(false);
      }
    );
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container justifyContent={"flex-end"} spacing={1}>
        {getFeatureFlagValue("flower_palette_generator") && (
          <Grid item xs="auto">
            <Tooltip title="Generate a flower palette using FlowerBuddy AI">
              <LoadingButton
                variant="outlined"
                color="info"
                loading={loading}
                onClick={toggleGenerating}
                size="small"
              >
                <AutoAwesomeOutlinedIcon size="small" />
              </LoadingButton>
            </Tooltip>
            {generating && (
              <ItemPaletteGeneratorDialog
                open={generating}
                handleCancel={toggleGenerating}
              />
            )}
          </Grid>
        )}
        <Grid item xs="auto">
          <LoadingButton
            variant="contained"
            color="secondary"
            loading={loading}
            onClick={toggleAdding}
            size="small"
            sx={{ mb: ".5rem" }}
          >
            Add Event Flower
          </LoadingButton>
          <AddItemDialog
            open={adding}
            handleCancel={toggleAdding}
            addItems={addItems}
            filter_items={event_version.palette_items.map((item) => item.item)}
            item_type="FL"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        sx={{ maxHeight: 450, overflowY: "auto" }}
        spacing={1}
      >
        {sortPaletteItemsByDisplayName(event_version.palette_items).map(
          (palette_item) => (
            <PaletteItemLine palette_item={palette_item} />
          )
        )}
        {event_version.palette_items.length === 0 && (
          <Grid item xs={12}>
            <EmptyStateText>No event flowers added.</EmptyStateText>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const PaletteItemLine = (props) => {
  const [deletePaletteItem] = useAccountState((state) => [
    state.deletePaletteItem,
  ]);
  const handleDelete = () => {
    deletePaletteItem(props.palette_item.uuid);
  };
  return (
    <Grid item xs={12} container>
      <Grid
        item
        xs={12}
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          "&:hover": { backgroundColor: "info.light", borderRadius: "5px" },
        }}
      >
        <Grid item xs={10} container alignItems={"center"} columnSpacing={1}>
          <Grid item xs={"auto"}>
            <ItemIcon item={props.palette_item.item} width={30} height={30} />
          </Grid>
          <Grid item xs={10}>
            <Typography noWrap>
              {itemDisplayName(props.palette_item.item)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <IconButton size="small" onClick={handleDelete}>
            <DeleteOutlineOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ItemPaletteGeneratorDialog = (props) => {
  const [event_version, updatePalette] = useAccountState((state) => [
    state.event_version,
    state.updatePalette,
  ]);
  const [prompt, setPrompt] = React.useState("");
  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const [draftPaletteItems, setDraftPaletteItems] = React.useState(
    event_version.palette_items.map((pi) => pi.item)
  );
  const deleteDraftPaletteItem = (uuid) => {
    setDraftPaletteItems(
      draftPaletteItems.filter((item) => item.uuid !== uuid)
    );
  };
  const clearDraftPaletteItems = () => {
    setDraftPaletteItems([]);
  };

  const [error, setError] = React.useState(false);

  const [generating, setGenerating] = React.useState(false);
  const handleGenerate = () => {
    setGenerating(true);
    setError(false);
    GeneratePaletteApi(
      prompt,
      event_version.uuid,
      draftPaletteItems.map((pi) => pi.uuid)
    )
      .then((resp) => {
        setDraftPaletteItems(resp.data);
        setPrompt("");
        setGenerating(false);
      })
      .catch((err) => {
        setError(true);
        setGenerating(false);
      });
  };

  const [adding, setAdding] = React.useState(false);
  const toggleAdding = () => {
    setAdding(!adding);
  };
  const handleAdd = (items) => {
    setDraftPaletteItems([...draftPaletteItems, ...items]);
    setAdding(false);
  };

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    setLoading(true);
    updatePalette(
      draftPaletteItems.map((pi) => pi.uuid),
      () => {
        props.handleCancel();
        setLoading(false);
      }
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <AutoAwesomeOutlinedIcon size="small" /> Generate Flower Palette
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems={"center"} spacing={1}>
          <Grid item xs={12}>
            <Typography>
              {draftPaletteItems.length > 0 ? (
                <>
                  Type your feedback to have FlowerBuddy AI refine your flower
                  palette.{" "}
                  <Link
                    onClick={clearDraftPaletteItems}
                    sx={{ cursor: "pointer" }}
                  >
                    Start From Scratch
                  </Link>
                </>
              ) : (
                "Describe your event, then click generate to have FlowerBuddy AI create a first draft of your flower palette."
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={prompt}
              onChange={handlePromptChange}
              multiline
              minRows={4}
              placeholder={
                draftPaletteItems.length > 0
                  ? "This palette has too much foliage, it's also using pricy stems"
                  : "A classic green and white wedding with roses and hydrangeas"
              }
              fullWidth
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error" size="small">
                There was an error generating the flower palette. Please try
                again.
              </Alert>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            container
            justifyContent={
              draftPaletteItems.length > 0 ? "space-between" : "flex-end"
            }
          >
            {draftPaletteItems.length > 0 && (
              <Grid item xs={"auto"}>
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={toggleAdding}
                >
                  <AddOutlinedIcon size="small" /> Add Flowers
                </Button>
                <AddItemDialog
                  open={adding}
                  handleCancel={toggleAdding}
                  addItems={handleAdd}
                  filter_items={draftPaletteItems}
                  item_type="FL"
                />
              </Grid>
            )}
            <Grid item xs={"auto"}>
              <LoadingButton
                variant="contained"
                color="secondary"
                size="small"
                loading={generating}
                onClick={handleGenerate}
                disabled={prompt.length < 3}
              >
                <AutoAwesomeOutlinedIcon size="small" />{" "}
                {draftPaletteItems.length > 0 ? "Refine" : "Generate"}
              </LoadingButton>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={1}
            sx={{
              maxHeight: 350,
              overflowY: "auto",
              mt: ".5rem",
              scrollbarWidth: "thin", // For Firefox
              "&::-webkit-scrollbar": {
                width: "3px", // Adjust scrollbar width
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888", // Scrollbar color
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
            }}
          >
            {sortItemsByName(draftPaletteItems).map((item) => (
              <DraftPaletteItem
                key={item.uuid}
                item={item}
                deleteDraftPaletteItem={deleteDraftPaletteItem}
              />
            ))}
          </Grid>
          {draftPaletteItems.length > 0 && (
            <Grid item xs={12} container>
              <Grid item xs="auto">
                <Typography>
                  <Link
                    sx={{ color: "info.light", cursor: "pointer" }}
                    onClick={clearDraftPaletteItems}
                  >
                    clear
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent={"flex-end"}
          spacing={1}
          alignItems={"center"}
        >
          {event_version.palette_items.length > 0 &&
            draftPaletteItems.length > 0 && (
              <Grid item xs={12}>
                <Alert severity="info" size="small">
                  Clicking save will overwrite your current event flowers with
                  the above flower list.
                </Alert>
              </Grid>
            )}
          <Grid item xs="auto">
            <Button
              onClick={props.handleCancel}
              color="info"
              variant="outlined"
              size="small"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <LoadingButton
              onClick={handleSubmit}
              color="secondary"
              variant="contained"
              disabled={draftPaletteItems.length === 0}
              size="small"
              loading={loading}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const DraftPaletteItem = (props) => {
  const handleDelete = () => {
    props.deleteDraftPaletteItem(props.item.uuid);
  };
  return (
    <Grid
      item
      xs={12}
      container
      alignItems={"center"}
      spacing={1}
      justifyContent={"space-between"}
    >
      <Grid item xs={10} container alignItems={"center"} spacing={1}>
        <Grid item xs={"auto"}>
          <ItemIcon item={props.item} width={25} height={25} />
        </Grid>
        <Grid item xs={10}>
          <Typography noWrap>{itemDisplayName(props.item)}</Typography>
        </Grid>
      </Grid>
      <Grid item xs="auto">
        <IconButton size="small" onClick={handleDelete}>
          <DeleteOutlineOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Grid>
    </Grid>
  );
};
